import { useCallback,  useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FieldElect from 'components/common/FieldElect';
import { FinishLocationIcon, StartLocationIcon } from 'pages/map/icons';
import { VerticalDashedLine } from 'pages/map/components';
import CheckBox from 'components/common/CheckBox';
import {
  CheckBoxSpan,
  RouteName,
  RoutesContentWrap,
  RouteWrap,
  WaypointName,
  WaypointWrap,
} from './CreateEventPostForm.styles';
import { getSavedRoutesStart } from 'store/map/actions';
import { AsyncStatus } from 'utils/types';

const DEFAULT_PAGE_SIZE = 10;

const Routes = () => {
  const { t } = useTranslation();
    const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffset] = useState<number>(0);
   const { getSavedRoutesReqStat, savedRoutes } = useSelector(
     (state) => state.map
   );
      const savedRoutesLengthRef = useRef(savedRoutes?.length || 0);
         savedRoutesLengthRef.current = savedRoutes?.length || 0;

 const observer = useRef<IntersectionObserver | null>(null);

  const lastRouteItem = useCallback((node) => {
    if (observer.current) observer?.current?.disconnect();
    observer.current = new IntersectionObserver((entires) => {
      if (entires[0].isIntersecting) {
        setOffset(savedRoutesLengthRef.current);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

    useEffect(() => {
      dispatch(getSavedRoutesStart({ offset, limit: DEFAULT_PAGE_SIZE }));
    }, [getSavedRoutesStart, offset]);


  useEffect(() => {
    isLoading &&
      getSavedRoutesReqStat === AsyncStatus.SUCCESS &&
      setIsLoading(false);
  }, [getSavedRoutesReqStat]);

  return (
    <FieldElect
      name="routeId"
      label={t('event:eventRoutes')}
      headerText={t('event:eventRoutes')}
      multipleSelect={false}
      render={(b, onRemove) => {
        if (b?.waypoints?.length > 0) {
          return (
            <RouteWrap>
              <RouteName>{b?.name}</RouteName>
              <WaypointWrap>
                <StartLocationIcon />
                <WaypointName>{b?.waypoints[0]?.waypointName}</WaypointName>
              </WaypointWrap>
              <VerticalDashedLine margin="0 0 0 11px" />
              <WaypointWrap>
                <FinishLocationIcon />
                <WaypointName>{b?.waypoints[1]?.waypointName}</WaypointName>
              </WaypointWrap>
            </RouteWrap>
          );
        }
        return '';
      }}
      formRender={(value, onChange) => {
        
        return (
          <>
            <RoutesContentWrap>
              {savedRoutes?.length > 0 &&
                savedRoutes?.map((d, i) => (
                  <RouteWrap
                    key={d?._id}
                    ref={i === savedRoutes?.length - 1 ? lastRouteItem : null}
                    onClick={() => onChange(d)}
                  >
                    <RouteName>{d?.name}</RouteName>

                    <WaypointWrap>
                      <StartLocationIcon />

                      <WaypointName>
                        {d?.waypoints[0]?.waypointName}
                      </WaypointName>
                    </WaypointWrap>

                    <VerticalDashedLine margin="0 0 0 11px" />

                    <WaypointWrap>
                      <FinishLocationIcon />

                      <WaypointName>
                        {d?.waypoints[1]?.waypointName}
                      </WaypointName>
                    </WaypointWrap>
                    {d?._id === value?._id && (
                      <CheckBoxSpan>
                        <CheckBox
                          type="radio"
                          checked={d?._id === value?._id}
                        />
                      </CheckBoxSpan>
                    )}
                  </RouteWrap>
                ))}
            </RoutesContentWrap>
          </>
        );
      }}
    />
  );
};

export default Routes;
