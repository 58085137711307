import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import Drawer from 'components/Drawer';
import CreatePhotoPostForm from 'forms/CreatePhotoPostForm';
import { closeSideBarCreatePhotoPost } from 'store/sideBarControler/actions';
import appState from 'hooks/appState';
import { APP_STATE } from 'types';
import { uploadToS3 } from 'utils/aws/upload-file';
import { routesPath } from 'utils/constants/routePath';

import { getInterestsStart } from 'store/user/actions';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';
import CreateDiscussionPostForm from 'forms/CreateDiscussionPostForm';
import useProcessPostResponse from 'hooks/reduxUpdate/useProcessPostResponse';
import { IDiscussionPost } from './IDiscussionPost';

import { DiscussionPostWrapper } from './DiscussionDrawer.styles';

const DiscussionDrawer = ({ open }: IDiscussionPost) => {
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { action, loading } = fetchApi();
  const router = useRouter();

  const appStateStore = useSelector((store) => store.app);

  const appStateAction = appState();
  const { processPostSuccess } = useProcessPostResponse();

  const variant =
    appStateStore[APP_STATE.EDIT_DISCUSSION_FORM]?.variant || 'create';
  const data = appStateStore[APP_STATE.EDIT_DISCUSSION_FORM]?.data || undefined;

  const getUserInterests = useSelector((store) => store.fetchData);

  const isEditForm = variant === 'edit';

  const fetchData = useCallback(() => {
    action(API_NAME.GET_USER_INTERESTS, {});
  }, []);

  useEffect(() => {
    if (!(getUserInterests[API_NAME.GET_USER_INTERESTS]?.data?.length > 0)) {
      fetchData();
    }
  }, []);

  const onCreatePhotoPost = async (v) => {
    try {
      if (!isEditForm) {
        router.push(`${routesPath.feedPath}`);
      }

      appStateAction(APP_STATE.CREATE_DISCUSSION_FORM, {
        isOpen: false,
        isCreatingPost: true,
      });
      const resMedia = !isEditForm ? await uploadToS3(v?.images) : [];
      const bodyData = {
        type: 11,
        ...(!isEditForm && { media: [...resMedia] }),
        title: v.title,
        text: v?.description,
        postCreator: v?.postCreator,
        origin: v?.origin,
        originId: v?.originId,
        hashtags: v?.quickTags?.map((i) => i?._id),
        events: v?.events?.map((i) => i?._id),
        groups: v?.groups?.map((i) => i?._id),
        users: v?.users?.map((i) => i?._id),
      };
      if (isEditForm) {
        await action(API_NAME.UPDATE_PHOTO_POST, {
          params: {
            postId: appStateStore[APP_STATE.EDIT_DISCUSSION_FORM]?.postId,
          },
          body: bodyData,
        });

        appStateAction(APP_STATE.EDIT_DISCUSSION_FORM, {
          isOpen: false,
        });

        return;
      }
      const res = await action(
        API_NAME.CREATE_PHOTO_POST,
        {
          body: bodyData,
        },
        false,
        false,
        [],
        true
      );
      if (res?.code === 200) {
        processPostSuccess(res);
        appStateAction(APP_STATE.CREATE_DISCUSSION_FORM, {
          isCreatingPost: false,
          data: {
            ...res?.data,
            mediaObjects: res?.data?.mediaObjects?.map((item, i) => ({
              ...item,
              url: v?.images[i],
            })),
          },
          type: 11,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initialValues = useMemo(
    () => ({
      ...(data && {
        images: data?.media,
        title: data?.title,
        description: data?.text,
        // addTags: {
        groups: data?.groups,
        users: data?.users,
        events: data?.events,
        // },
        quickTags: data?.interests,
        creator: data?.creator,
        group: data?.creator?.isGroup ? data?.creator?.data?._id : null,
      }),
    }),
    [data]
  );

  return (
    <Drawer
      open={
        variant === 'create'
          ? appStateStore[APP_STATE.CREATE_DISCUSSION_FORM]?.isOpen
          : appStateStore[APP_STATE.EDIT_DISCUSSION_FORM]?.isOpen
      }
      onDrawerClose={() =>
        variant === 'create'
          ? appStateAction(APP_STATE.CREATE_DISCUSSION_FORM, {
              isOpen: false,
            })
          : appStateAction(APP_STATE.EDIT_DISCUSSION_FORM, {
              isOpen: false,
            })
      }
      rootForm
    >
      <CreateDiscussionPostForm
        onSubmit={onCreatePhotoPost}
        interests={
          getUserInterests[API_NAME.GET_USER_INTERESTS]?.data?.length > 0 &&
          getUserInterests[API_NAME.GET_USER_INTERESTS]?.data
        }
        privateFeatures={false}
        setIsDrawerOpen={setIsDrawerOpen}
        onIconClick={() =>
          variant === 'create'
            ? appStateAction(APP_STATE.CREATE_DISCUSSION_FORM, {
                isOpen: false,
              })
            : appStateAction(APP_STATE.EDIT_DISCUSSION_FORM, {
                isOpen: false,
              })
        }
        variant={typeof variant !== 'undefined' && variant}
        initialValues={initialValues}
      />
    </Drawer>
  );
};

export default DiscussionDrawer;
