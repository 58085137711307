import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import MapGL, { Marker } from 'react-map-gl';

import { getLocalStorage } from 'hooks';
import { getPlaceName, searchPlace } from 'services';
import ValidationError from 'components/ValidationError';
import { MapControls } from 'pages/map/components/mapControls/mapControls';
import Image from 'components/common/Image';
import { InputField, LocationPlacesList } from 'components';
import {
  CircleWithEditIcon,
  PlusIcon,
  LocationArrowIcon,
  LocationIcon,
} from 'public/assets/icons';
import useMapFilters from 'hooks/useMapFilters';
import { IFormElect } from './Types';

import {
  Wrapper,
  Title,
  IconButton,
  RenderContent,
  BarWrapper,
  RenderContentDrawer,
  MapBoxContainer,
  BarLeft,
  BarIcon,
} from './styles';

const FormElect = ({
  id,
  label,
  meta,
  input,
  headerText,
  isDisabled = false,
  barIcon,
}: IFormElect) => {
  const [open, setOpen] = useState(false);
  const { onChange } = input;

  const { t } = useTranslation();

  const { valid, invalid, touched, error, submitError } = meta;
  const errorText = error || submitError;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!(touched && invalid && (error || submitError));

  const onDrawerClose = () => {
    setOpen(false);
  };

  const [locationInputValue, setLocationInputValue] = useState('');
  const [isOfferedPlacesModalOpen, setIsOfferedPlacesModalOpen] =
    useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [submitLocationData, setSubmitLocationData] = useState(null);

  const userLocalData = JSON.parse(getLocalStorage('user_data'));
  // const { location } = useSelector((state) => state.sideBarControler.tagsData);

  const { data } = userLocalData || { data: null };
  const { geoLocation } = data || { geoLocation: null };

  const [viewport, setViewport] = useState({
    width: '100%',
    height: '100%',
    latitude: geoLocation?.coordinates[1],
    longitude: geoLocation?.coordinates[0],
    zoom: 6,
  });
  const [userCoordinates, setUserCoordinates] = useState([]);
  const [placesOffered, setPlacesOffered] = useState([]);

  const mapRef = useRef(null);
  const locationInputRef = useRef(null);
  useMapFilters(mapRef, isMapLoaded);

  useEffect(() => {
    if (meta?.initial?.lng && meta?.initial?.lat && meta?.initial?.address) {
      setSubmitLocationData({
        lat: Number(meta?.initial?.lat),
        lng: Number(meta?.initial?.lng),
        name: meta?.initial?.address,
        address: meta?.initial?.address,
      });
      setLocationInputValue(meta?.initial?.name || meta?.initial?.address);
      setViewport({
        ...viewport,
        latitude: Number(meta?.initial?.lat),
        longitude: Number(meta?.initial?.lng),
        zoom: 15,
      });
    }
  }, [meta?.initial]);

  useEffect(() => {
    if (isMapLoaded) {
      locationInputRef?.current?.focus();
    }
  }, [isMapLoaded]);

  const onPointClick = async (point) => {
    const { features } = await getPlaceName({ coordinates: point.lngLat });

    if (Array.isArray(features) && features?.length) {
      if (features[0].place_type[0]) {
        setLocationInputValue(features[0].place_name);
      } else {
        setLocationInputValue(
          `${point.lngLat[0].toFixed(6)},${point.lngLat[1].toFixed(6)}`
        );
      }
    }

    const [longitude, latitude] = point.lngLat;

    setSubmitLocationData({
      lat: latitude,
      lng: longitude,
      name: features[0]?.place_name,
      address: features[0]?.place_name,
    });
    setViewport({ ...viewport, latitude, longitude, zoom: 15 });
  };

  const onChangeUserLocation = ({ coords: { latitude, longitude } }) => {
    setUserCoordinates([longitude, latitude]);
  };

  const onChangePlace = (newText) => {
    setLocationInputValue(newText);

    const proximity = userCoordinates
      ? `${userCoordinates[0]},${userCoordinates[1]}`
      : '';

    const searchPlaceByText = async () => {
      const resp = await searchPlace({
        searchText: newText.trim(),
        proximity,
      });

      if (!resp) {
        return null;
      }

      const { features } = resp;
      if (features?.length) {
        setPlacesOffered(features);
        // eslint-disable-next-line no-unused-expressions
        !isOfferedPlacesModalOpen && setIsOfferedPlacesModalOpen(true);
      } else {
        setPlacesOffered([]);
        setIsOfferedPlacesModalOpen(false);
      }
    };

    if (newText.length) {
      searchPlaceByText();
    } else {
      setIsOfferedPlacesModalOpen(false);
      setSubmitLocationData(null);
    }
  };

  const onPlaceOfferedClick = (place) => {
    setIsOfferedPlacesModalOpen(false);
    setLocationInputValue(place.place_name);

    const [longitude, latitude] = place.geometry.coordinates;

    setSubmitLocationData({
      lat: latitude,
      lng: longitude,
      name: place.place_name,
      address: place.place_name,
    });
    setViewport({ ...viewport, latitude, longitude, zoom: 13 });
  };

  const onSubmit = () => {
    onChange(submitLocationData);
    onDrawerClose();
  };

  const onMapLoad = () => {
    setIsMapLoaded(true);
  };

  return (
    <>
      <Wrapper>
        <BarWrapper>
          <BarLeft>
            {barIcon && (
              <BarIcon>
                <LocationIcon />
              </BarIcon>
            )}
            <Title type="h4">{label}</Title>
          </BarLeft>
          <IconButton
            type="button"
            onClick={() => setOpen(true)}
            disabled={isDisabled}
            hasError={hasError}
          >
            {input?.value?.lng &&
            input?.value?.lat &&
            (input?.value?.address || input?.value?.name) ? (
              <CircleWithEditIcon />
            ) : (
              <PlusIcon />
            )}
          </IconButton>
        </BarWrapper>

        {(input?.value?.address || input?.value?.name) && (
          <RenderContent>
            {input?.value?.address || input?.value?.name}
          </RenderContent>
        )}

        <ValidationError touched={hasError} error={errorText} />
      </Wrapper>
      <RenderContentDrawer
        open={open}
        headerText={headerText}
        onIconClick={onDrawerClose}
        onDrawerClose={onDrawerClose}
        submitText={t('editUserProfile:save')}
        onSubmit={onSubmit}
      >
        <InputField
          value={locationInputValue}
          onChange={(e) => onChangePlace(e.target.value)}
          label={t('common:location')}
          crossIcon
          onCrossClick={() => {
            setLocationInputValue('');
            setSubmitLocationData(null);
            setPlacesOffered([]);
            setIsOfferedPlacesModalOpen(false);
          }}
          margin="0 0 20px 0"
          iconLeft={<LocationArrowIcon />}
          disabled={!isMapLoaded}
          ref={locationInputRef}
        />
        <MapBoxContainer>
          <MapGL
            ref={mapRef}
            {...viewport}
            mapStyle={process.env._MAPBOX_STYLE_URL}
            onViewportChange={(nextViewport) => setViewport(nextViewport)}
            mapboxApiAccessToken={process.env._MAPBOX_ACCESS_TOKEN}
            onClick={(evt) => onPointClick(evt)}
            onLoad={onMapLoad}
            attributionControl={false}
            interactiveLayerIds={[
              process.env._EVENT_PIN_LAYER_NAME,
              process.env._POI_PIN_LAYER_NAME,
              process.env._BUSINESS_MKPL_PIN_LAYER_NAME,
            ]}
          >
            <MapControls onGeolocate={onChangeUserLocation} />

            {submitLocationData && (
              <Marker
                key={submitLocationData.name}
                longitude={Number(submitLocationData.lng)}
                latitude={Number(submitLocationData.lat)}
                offsetLeft={-18}
                offsetTop={-43}
              >
                <Image
                  src="/assets/img/pin.svg"
                  alt="location_pin"
                  width={36}
                  height={43}
                  unoptimized
                  priority
                />
              </Marker>
            )}
          </MapGL>

          {isOfferedPlacesModalOpen && (
            <LocationPlacesList
              locationsList={placesOffered}
              onOutsideClick={() => setIsOfferedPlacesModalOpen(false)}
              onItemClick={(item) => onPlaceOfferedClick(item)}
            />
          )}
        </MapBoxContainer>
      </RenderContentDrawer>
    </>
  );
};

export default FormElect;
