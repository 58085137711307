import styled from 'styled-components';

interface IProps {
  type?: string;
  disabled?: boolean;
}

export const CheckMark = styled.span<IProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${(props) => (props.type === 'checkbox' ? '24px' : '14px')};
  width: ${(props) => (props.type === 'checkbox' ? '24px' : '14px')};
  background: ${(props) =>
    props.disabled ? props.theme.colors.neutral.n5 : '#f7f7f7'};
  border: 1px solid ${(props) => props.theme.colors.neutral.n5};
  border-radius: ${(props) => (props.type === 'checkbox' ? '4px' : '50%')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  z-index: 0;
  &::after {
    ${(props) => {
      if (props.type === 'checkbox') {
        return `
        content: '';
        position: absolute;
        display: none;
        left: 9px;
        top: 2px;
        width: 5px;
        height: 12px;
        border: solid white;
        border-width: 0 2.5px 2.5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        `;
      }
      if (props.type === 'radio') {
        return `
        content: '';
        position: absolute;
        display: none;
        left: 5px;
        top: 2px;
        width: 3px;
        height: 7px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        `;
      }
    }}
  }
`;

export const CheckBoxInput = styled.input`
  opacity: 0;
  cursor: pointer;
  height: 16px;
  width: 16px;
  top: 0;
  right: 0;
  z-index: 1;

  &:checked ~ ${CheckMark} {
    background-color: ${(props) =>
      props.type === 'checkbox'
        ? props.theme.colors.orange
        : props.theme.colors.blue};
    border-color: transparent;
    &::after {
      display: block;
    }
  }
`;

export const CheckBoxWrapper = styled.label<IProps>`
  display: flex;
  flex-direction: row;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const CheckBoxChildrenWrapper = styled.div<IProps>`
  flex: 1;
`;

export const CheckBoxInputWrapper = styled.div<IProps>`
  position: relative;
`;
