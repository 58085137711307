import { useContext } from 'react';
import { useTheme } from 'styled-components';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import Link from 'components/common/Link';
import { routesPath } from 'utils/constants/routePath';
import { ChatIcon, SearchIcon } from 'public/assets/icons';
import { ThemeContext } from 'theme';
import { useSelector } from'react-redux';
import imageResize from 'utils/imageResize';


import {
  clearSidebarStore,
  openSidebarChatsList,
  openSidebarNotifications,
} from 'store/sideBarControler/actions';

import {
  HeaderMobileNavItem,
  MobileLoginHeaderWrapper,
  NavItem,
} from './MobileLoginHeader.styles';
import { IMobileLoginHeader } from './IMobileLoginHeader';
import Image from 'next/image';

const MobileLoginHeader = ({
  isSidebarOpen,
  isOpenSidebarSavedRoutes,
  isOpenSidebarWhereTo,
  isOpenSidebarPlanRoute,
  isOpenSidebarChoseStartPoint,
  isOpenSidebarChoseWayPoint,
  isOpenSidebarRouteDetail,
  isOpenSidebarChoseEndPoint,
  isConformationModalOpen,
}: IMobileLoginHeader) => {
  const dispatch = useDispatch();
  const { searchPath } = routesPath;
  const router = useRouter();
  const { route } = router;
  const theme: any = useTheme();
   const activeClub = useSelector((state) => state?.club?.activeClub);
   const clubLogo = activeClub?.theme?.logoMedia?.url;

  const context: any = useContext(ThemeContext);
  const Logo = context.content.transparentLogo;

  return (
    <MobileLoginHeaderWrapper
      isSidebarOpen={
        isSidebarOpen ||
        isOpenSidebarSavedRoutes ||
        isOpenSidebarWhereTo ||
        isOpenSidebarPlanRoute ||
        isOpenSidebarChoseStartPoint ||
        isOpenSidebarChoseWayPoint ||
        isOpenSidebarRouteDetail ||
        isOpenSidebarChoseEndPoint
      }
      isConformationModalOpen={isConformationModalOpen}
    >
      <HeaderMobileNavItem>
        <Link path={searchPath}>
          <NavItem>
            <SearchIcon
              colorStroke={
                route === searchPath ? theme.colors.orange : theme.colors.black
              }
            />
          </NavItem>
        </Link>
      </HeaderMobileNavItem>
      <HeaderMobileNavItem>
        <Link path="/">
          <NavItem>
            {clubLogo ? (
              <Image
                src={clubLogo}
                alt="user image"
                width={36}
                height={36}
              />
            ) : (
              <Logo />
            )}
          </NavItem>
        </Link>
      </HeaderMobileNavItem>
      <HeaderMobileNavItem onClick={() => dispatch(openSidebarChatsList())}>
        <NavItem>
          <ChatIcon colorStroke={theme.colors.black} />
        </NavItem>
      </HeaderMobileNavItem>
    </MobileLoginHeaderWrapper>
  );
};

export default MobileLoginHeader;
