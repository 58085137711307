import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ValidationError from 'components/ValidationError';
import ImagePreview from 'components/ImagePreview';
import ImageUploader from 'components/ImageUploader';
import SmallImagePreview from 'components/SmallImagePreview';
import { EditIcon, EditIconWithBg, TrashIcon } from 'public/assets/icons';
import Dropdown from 'components/Dropdown';
import { FileInput } from 'components/ImageUploader/ImageUploader.styles';
import { ImageCropper } from 'components';
import { IUploadImage } from './Types';

import {
  CustomListContent,
  CustomListItem,
  DropdownWrapper,
  EditButton,
  IconWrapper,
  MultipleImagesWrap,
  OnePhotoPreview,
  TextWrapper,
  UploadMultipleImagesWrapper,
} from './styles';

const Input = ({
  //   images: input?.value,
  setImages,
  isOnePhoto = false,
  disabled = false,
  isProfileImages = false,
  isAspect,
  label,
  input,
  meta,
  className,
}: IUploadImage) => {
  const [index, setIndex] = useState(0);
  const [imageSrc, setImageSrc] = useState<string>('');

  const [showCropper, setShowCropper] = useState<boolean>(false);
  const [reset, setReset] = useState<any>({ index: '', image: '' });
  const [image, setImage] = useState([]);

  const { t } = useTranslation();

  const { valid, invalid, touched, error, submitError } = meta;

  const errorText = error || submitError;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!(touched && invalid && (error || submitError));

  const onFileChange = (e) => {
    e.stopPropagation();
    if (e?.target?.files && e?.target?.files?.length > 0) {
      const file = e?.target?.files[0];
      input.onChange(URL.createObjectURL(file));
      setImageSrc(URL.createObjectURL(file));
      setShowCropper(true);
    }
  };

  const listItems = [
    {
      customContent: (
        <CustomListItem key={0} onClick={(e) => e.stopPropagation()}>
          <CustomListContent>
            <IconWrapper>
              <EditIcon />
            </IconWrapper>
            <TextWrapper>{t('selectPhotos:changePhoto')}</TextWrapper>
          </CustomListContent>
          <FileInput type="file" onChange={onFileChange} accept="image/*" />
        </CustomListItem>
      ),
    },
    {
      id: 1,
      icon: <TrashIcon />,
      text: t('selectPhotos:removePhoto'),
      onClick: () => input.onChange(''),
    },
  ];

  const onCloseIconClick = (index: number) => {
    input.onChange(
      input?.value.filter((image, imageIndex) => imageIndex !== index)
    );
  };

  const onEditIconClick = (index: number) => {
    setShowCropper(true);
    input?.value.filter((image, imageIndex) =>
      imageIndex === index
        ? setReset({ ...reset, index: imageIndex, image })
        : ''
    );
  };
  const onSubmitImage = (image: string) => {
    setShowCropper(false);
    if (isOnePhoto) {
      input.onChange(image);
      return;
    }
    if (reset?.image) {
      return input?.value?.map((item, index) =>
        reset?.index === index
          ? setImage(input?.value?.splice(index, 1, image))
          : input?.value
      );
    }
    input.onChange([...input?.value, image]);
  };

  return (
    <>
      {showCropper && (
        <ImageCropper
          aspect={3 / 2}
          image={imageSrc || reset?.image}
          onSubmitImage={onSubmitImage}
          isCircleShape={false}
          isAspect={isAspect}
        />
      )}
      {isProfileImages && showCropper && (
        <ImageCropper
          aspect={3 / 2}
          image={imageSrc}
          onSubmitImage={onSubmitImage}
          isProfileImages={isProfileImages}
          isCircleShape
          isAspect={isAspect}
        />
      )}

      <UploadMultipleImagesWrapper className={className}>
        {!input?.value?.length ? (
          <ImageUploader
            onChange={(v, s, f) => {
              if (!isOnePhoto) {
                input.onChange([...input?.value, v]);
              } else {
                input.onChange(v);
              }
            }}
            label={label && label}
            hasError={hasError}
            isAspect={isAspect}
          />
        ) : !isOnePhoto ? (
          <ImagePreview imageSrc={input?.value[index]} isPhotoPost />
        ) : (
          <OnePhotoPreview>
            <ImagePreview imageSrc={input?.value} isPhotoPost />
            {!showCropper && (
              <DropdownWrapper>
                <Dropdown listItems={listItems}>
                  <EditButton>
                    <EditIconWithBg disabled={disabled} />
                  </EditButton>
                </Dropdown>
              </DropdownWrapper>
            )}
          </OnePhotoPreview>
        )}

        {!isOnePhoto && input?.value?.length > 0 && (
          <MultipleImagesWrap>
            {input?.value?.map((v, i) => (
              <SmallImagePreview
                key={i}
                image={v}
                index={i}
                onCloseIconClick={onCloseIconClick}
                onEditIconClick={onEditIconClick}
                setSelectedImage={setIndex}
              />
            ))}
            {input?.value?.length < 5 && (
              <ImageUploader
                isSmallImages
                onChange={(v, s, f) => input.onChange([...input?.value, v])}
                isAspect={isAspect}
              />
            )}
          </MultipleImagesWrap>
        )}
      </UploadMultipleImagesWrapper>

      <ValidationError touched={hasError} error={errorText} />
    </>
  );
};

export default Input;
