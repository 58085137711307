import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useImageStyle from 'utils/hooks/useImageDimension';
import { setActiveAccountId } from 'store/club/actions';
import { useRouter } from 'next/router';
import { AppClubsListProps, Club } from './IAppClubsList';
import { Container, Loader, ListContent } from './AppClubsList.styles';
import AppClubItem from '../AppClubItem';

const DEFAULT_CLUB: Club = {
  _id: 'RoadStr',
  name: 'RoadStr',
  theme: {
    logoMedia: {
      url: '',
    },
    colors: {
      cola: '',
      dodgerBlue: '',
      orangeGradientBot: '',
      orangeGradientTop: '',
      pizazz: '',
      rajah: '',
      westSide: '',
      yellow: '',
    },
  },
};

const AppClubsList: React.FC<AppClubsListProps> = () => {
  const [selectedId, setSelectedId] = useState<string>(DEFAULT_CLUB._id);

  const dispatch = useDispatch();
  const router = useRouter();
  const allClubs: Club[] = useSelector(
    (state: any) => state?.club?.allClubs || []
  );
  const activeClub: Club = useSelector((state: any) => state?.club?.activeClub);
  const activeAccountId: string = useSelector(
    (state: any) => state?.club?.activeAccountId
  );
  const loading: boolean = useSelector((state: any) => state?.club?.loading);

  const clubLogo = activeClub?.theme?.logoMedia;
  const imageStyle = useImageStyle(40, clubLogo);

  const joinedClubs = useMemo(
    () => [DEFAULT_CLUB, ...(Array.isArray(allClubs) ? allClubs : [])],
    [allClubs]
  );

  useEffect(() => {
    setSelectedId(activeAccountId || DEFAULT_CLUB._id);
  }, [activeAccountId]);

  const handlePress = (clubId: string) => {
    const updatedClubId = clubId === 'RoadStr' ? null : clubId;
    router.push('/feed').then(() => {
      dispatch(setActiveAccountId(updatedClubId));
      setSelectedId(clubId);
    });
  };

  const renderItem = (item: Club) => (
    <AppClubItem
      key={item._id}
      avatarStyle={{ height: imageStyle?.height, width: imageStyle?.width }}
      clubName={item?.name}
      logoUrl={item?.theme?.logoMedia?.url || ''}
      onPress={() => handlePress(item._id)}
      selected={item._id === selectedId}
      defaultClubId={DEFAULT_CLUB._id}
    />
  );

  return (
    <Container>
      <ListContent>
        {loading && allClubs?.length === 0 ? (
          <Loader className="spinner" />
        ) : (
          joinedClubs.map(renderItem)
        )}
      </ListContent>
    </Container>
  );
};

export default AppClubsList;
