import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';

import Drawer from 'components/Drawer';
import CheckBox from 'components/common/CheckBox';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import { IJoiningManagementDrawer } from './IJoiningManagementDrawer';

import {
  JoiningManagementContentBlock,
  JoiningManagementContentBlockLeft,
  JoiningManagementContentBlockRight,
  JoiningManagementContentWrapper,
  BlockHeading,
  BlockText,
  SecretCodeInputBlock,
} from './JoiningManagementDrawer.styles';

const JoiningManagementDrawer = ({
  open,
  setOpen,
  setJoiningManagement,
}: IJoiningManagementDrawer) => {
  const [checked, setChecked] = useState<number>();

  const { t } = useTranslation();
  const { values } = useFormState();

  const JoiningData = [
    {
      id: 1,
      title: t('group:joiningManagement:blockAutomatic:title'),
      text: t('group:joiningManagement:blockAutomatic:text'),
      joiningManagement: 'auto',
    },
    {
      id: 2,
      title: t('group:joiningManagement:blockSecretCode:title'),
      text: t('group:joiningManagement:blockSecretCode:text'),
      joiningManagement: 'code',
    },
  ];

  useEffect(() => {
    const getData = JoiningData?.find(
      (i) => i?.joiningManagement === values?.joiningManagement
    );
    if (values?.joiningManagement) {
      setJoiningManagement((prev) => ({
        ...prev,
        title: getData?.title,
        text: getData?.text,
        joiningManagement: getData?.joiningManagement,
      }));
      setChecked(getData?.id);
    }
  }, []);

  return (
    <Drawer
      open={open}
      headerText="Joining management"
      onIconClick={() => setOpen(false)}
      onDrawerClose={() => setOpen(false)}
      submitText={t('editUserProfile:save')}
      onSubmit={() => setOpen(false)}
      disabled={checked === 2 && !values?.secretCode?.length}
    >
      <JoiningManagementContentWrapper checked={checked === 2}>
        {JoiningData?.map((d) => (
          <JoiningManagementContentBlock
            key={d?.id}
            onClick={() => {
              setJoiningManagement((prev) => ({
                ...prev,
                title: d?.title,
                text: d?.text,
                joiningManagement: d?.joiningManagement,
              }));
              setChecked(d?.id);
            }}
            checked={
              checked === d?.id ||
              values?.joiningManagement === d?.joiningManagement
            }
          >
            <JoiningManagementContentBlockRight>
              <CheckBox
                type="radio"
                name="Join"
                checked={
                  checked === d?.id ||
                  values?.joiningManagement === d?.joiningManagement
                }
              >
                <JoiningManagementContentBlockLeft>
                  <BlockHeading type="h4" fs={14}>
                    {d?.title}
                  </BlockHeading>
                  <BlockText>{d?.text}</BlockText>
                </JoiningManagementContentBlockLeft>
                {d?.id === 2 && checked === 2 && (
                  <SecretCodeInputBlock>
                    <Input
                      id="secretCode"
                      name="secretCode"
                      type="text"
                      label={t('event:secretCodeLabel')}
                      placeholder={t('event:secretCodePlaceholder')}
                      //   validate={composeValidators(
                      //     required(t('error:thisFieldIsRequired'))
                      //   )}
                    />
                  </SecretCodeInputBlock>
                )}
              </CheckBox>
            </JoiningManagementContentBlockRight>
          </JoiningManagementContentBlock>
        ))}
      </JoiningManagementContentWrapper>
    </Drawer>
  );
};

export default JoiningManagementDrawer;
