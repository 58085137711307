/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, batch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SidebarWrapper } from 'components';
import {
  closeSidebarChooseRouteMethod,
  openSidebarImportRoute,
  openSidebarPlanRoute,
} from 'store/map/actions';
import {
  ArrowBack,
} from 'public/assets/icons';
import {
  AddButtonContainer,
  AddButtonWrap,
  AddButtonText,
} from './SidebarChooseRouteMethod.style';
import { MapSidebarWrap } from '../index';

const SidebarChooseRouteMethod = ({
  handleMethodSelect,
  handleImportRoutes,
}: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(()=>{
    handleImportRoutes()
  },[])
  
  const onCloseSidebarClick = async () => {
    batch(() => {
      dispatch(closeSidebarChooseRouteMethod());
      dispatch(openSidebarPlanRoute());
    });
  };

  const handleImportMethod = (method: string) => {
    batch(() => {
      dispatch(closeSidebarChooseRouteMethod());
      dispatch(openSidebarImportRoute());
    });
    handleMethodSelect(method);
  };

  return (
    <MapSidebarWrap>
      <SidebarWrapper
        headerText={t('map:selectImportMethod')}
        headerIcon={<ArrowBack />}
        onIconClick={onCloseSidebarClick}
        contentPadding="20px"
      >
        <AddButtonContainer>
          <AddButtonWrap onClick={() => handleImportMethod('maps')}>
            <AddButtonText>{t('map:importFromGoogleMaps')}</AddButtonText>
          </AddButtonWrap>

          <AddButtonWrap onClick={() => handleImportMethod('gpx')}>
            <AddButtonText>{t('map:importFromGpxFile')}</AddButtonText>
          </AddButtonWrap>
        </AddButtonContainer>
      </SidebarWrapper>
    </MapSidebarWrap>
  );
};

export default SidebarChooseRouteMethod;
