import API_NAME from 'services/api';
import useProcessResponse from './useProcessResponse';

const useProcessPostResponse = () => {
  const processPostSuccess = useProcessResponse(
    API_NAME.FEED_GET_FOLLOWING_POSTS,
    'ADD'
  );
  const processDeleteSuccess = useProcessResponse(
    API_NAME.FEED_GET_FOLLOWING_POSTS,
    'DELETE'
  );
  return { processPostSuccess, processDeleteSuccess };
};
export default useProcessPostResponse;
