/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import i18n from 'utils/i18n';
import {
  CrossIcon,
  EarthIcon,
  LocationPinIcon,
  LocationIcon,
  ArrowBack,
} from 'public/assets/icons';
import { style } from 'utils/constants/style';
import {
  closeSidebarCreateGroup,
  openSidebarTagWithAdding,
  actionTypes,
  removeTag,
  openSidebarTag,
  addTags,
  openSidebarChooseLocation,
} from 'store/sideBarControler/actions';
import { addGroupStart, clearCreatedGroupStatus } from 'store/user/actions';
import { editGroupStart, clearEditGroupStatus } from 'store/posts/actions';
import { getCorrectImageBase64Format } from 'utils/getCorrectImageBase64Format';
import { AsyncStatus } from 'utils/types';
import { getLocalStorage } from 'utils/hooks';
import { validateUrl } from 'utils/validators';
import { GroupSettingRadio } from './GroupSettingRadio';
import { GroupSetting } from './GroupSetting';
import {
  InputField,
  ImagesUploader,
  Selector,
  Pill,
  ButtonsGroup,
  ErrorMessage,
  InputDescription,
} from '../../index';
import { SidebarWrapper } from '../index';
import { ICreateGroup } from './ICreateGroup';
import {
  ContentWrapper,
  ContentHeaderText,
  TagImageWrap,
  SectionHeader,
  GroupLocalityWrap,
  GroupLocalityItemWrap,
  GroupLocalityItemText,
  LocationText,
} from './CreateGroup.style';

const GroupLocalityTypes = () => [
  {
    id: 0,
    name: i18n.t('buttonGroupLabels:local'),
    value: 0,
    icon: (color) => <LocationPinIcon color={color} />,
  },
  {
    id: 1,
    name: i18n.t('buttonGroupLabels:national'),
    value: 2,
    icon: (color = '#A7A7A7') => (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 1.50012C4 1.22398 3.77614 1.00012 3.5 1.00012C3.22386 1.00012 3 1.22398 3 1.50012V14.5703C3 14.8464 3.22386 15.0703 3.5 15.0703C3.77614 15.0703 4 14.8464 4 14.5703V1.50012ZM13.286 4.40649C13.2889 5.44042 13.2919 6.47434 13.2946 7.50827C13.2952 7.70996 13.132 7.7827 12.9302 7.70111C11.6332 7.2509 10.3739 7.56802 9.11472 7.88514C7.85553 8.20226 6.59635 8.51938 5.29941 8.06918C5.0975 7.98748 4.9342 7.83064 4.93273 7.53167C4.92658 6.28311 4.92401 5.0345 4.92144 3.78589C4.92017 3.17159 4.91891 2.55728 4.91722 1.94299C4.91662 1.74133 5.07992 1.66858 5.28177 1.75024C6.57876 2.20045 7.83796 1.88332 9.09715 1.56619C10.3563 1.24906 11.6155 0.931934 12.9125 1.38212C13.2431 1.49233 13.2785 1.71795 13.2791 1.91962C13.2814 2.74858 13.2837 3.57753 13.286 4.40649Z"
          fill={color}
        />
      </svg>
    ),
  },
  {
    id: 2,
    name: i18n.t('buttonGroupLabels:global'),
    value: 4,
    icon: (color) => <EarthIcon color={color} />,
  },
];

const PrivacyMock = () => [
  {
    id: 0,
    header: i18n.t('groupPrivacyOptions:public'),
    body: i18n.t('groupPrivacyOptions:publicDescription'),
  },
  {
    id: 1,
    header: i18n.t('groupPrivacyOptions:membersPlus'),
    body: i18n.t('groupPrivacyOptions:membersPlusDescription'),
  },
  {
    id: 2,
    header: i18n.t('groupPrivacyOptions:private'),
    body: i18n.t('groupPrivacyOptions:privateDescription'),
  },
];

const PermissionsMock = () => [
  {
    id: 1,
    header: i18n.t('postPermissionOptions:open'),
    body: i18n.t('postPermissionOptions:openDescription'),
  },
  {
    id: 2,
    header: i18n.t('postPermissionOptions:membersPlus'),
    body: i18n.t('postPermissionOptions:membersPlusDescription'),
  },
  {
    id: 3,
    header: i18n.t('postPermissionOptions:admin'),
    body: i18n.t('postPermissionOptions:adminDescription'),
  },
];

export const CreateGroup = ({ groupForEditData }: ICreateGroup) => {
  const [groupInfo, setGroupInfo] = useState({
    coverPhoto: '',
    profilePhoto: '',
    username: '',
    name: '',
    zone: null,
    description: '',
    chat: false,
    postPermission: 1,
    privacy: 0,
    website: '',
  });
  console.log('IN CREATE GROUP');
  const [additionalSettings, setAdditionalSettings] = useState(0); // 0 - none, 1 - privacy, 2 - permissions
  const [groupPrivacy, setGroupPrivacy] = useState(0);
  const [groupPostPermissions, setGroupPostPermissions] = useState(1);
  const [linkError, setLinkError] = useState('');

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tagsData = useSelector((state) => state.sideBarControler.tagsData);
  const { addGroupReqStat } = useSelector((state) => state.user);
  const { editGroupReqStat } = useSelector((state) => state.posts);

  useEffect(() => {
    if (addGroupReqStat === AsyncStatus.SUCCESS) {
      dispatch(closeSidebarCreateGroup());
      dispatch(clearCreatedGroupStatus());
    }

    if (editGroupReqStat === AsyncStatus.SUCCESS) {
      dispatch(closeSidebarCreateGroup());
      dispatch(clearEditGroupStatus());
    }
  }, [addGroupReqStat, editGroupReqStat]);

  useEffect(() => {
    if (groupForEditData) {
      const {
        coverPhoto,
        profilePhoto,
        username,
        name,
        zone,
        description,
        isChat: chat,
        postPermission,
        privacy,
        website,
        interests,
        brands,
        collaborators,
        admins,
        location,
      } = groupForEditData;

      setGroupInfo({
        coverPhoto,
        profilePhoto,
        username,
        name,
        zone,
        description,
        chat,
        postPermission,
        privacy,
        website,
      });

      dispatch(addTags({ type: 'interests', tags: interests }));
      dispatch(addTags({ type: 'brands', tags: brands }));
      dispatch(addTags({ type: 'collaborators', tags: collaborators }));
      dispatch(addTags({ type: 'admins', tags: admins }));
      dispatch(addTags({ type: 'location', tags: location }));
    }
  }, [groupForEditData]);

  const onFileInputChange = (image: string, type) => {
    if (type === 'cover') {
      setGroupInfo({ ...groupInfo, coverPhoto: image });
    } else {
      setGroupInfo({ ...groupInfo, profilePhoto: image });
    }
  };

  const onRemovePhotoClick = (type) => {
    if (type === 'cover') {
      setGroupInfo({ ...groupInfo, coverPhoto: '' });
    } else {
      setGroupInfo({ ...groupInfo, profilePhoto: '' });
    }
  };

  const tagsList = (selectedTags, type) =>
    selectedTags.map((tag) => {
      if (type === 'collaborators' || type === 'admins') {
        return (
          <Pill
            text={tag.username}
            onClick={() => dispatch(removeTag({ id: tag._id, type }))}
            iconRight={<CrossIcon width="10" height="10" />}
            fontSize={12}
            margin="0 10px 8px 0"
            key={tag._id}
            iconLeft={
              <TagImageWrap>
                <Image
                  width={26}
                  height={26}
                  src={
                    tag.profilePhoto.trim().length
                      ? tag.profilePhoto
                      : '/assets/img/emptyUserPhoto.png'
                  }
                  alt="tag"
                  unoptimized
                  priority
                />
              </TagImageWrap>
            }
          />
        );
      }

      return (
        <Pill
          text={tag ? tag.name : '2'}
          onClick={() => dispatch(removeTag({ id: tag ? tag._id : '0', type }))}
          iconRight={<CrossIcon width="10" height="10" />}
          fontSize={12}
          margin="0 10px 8px 0"
          key={tag ? tag._id : '0'}
        />
      );
    });

  const renderTagsList = (type: string) => {
    if (tagsData[type] && tagsData[type].length) {
      return tagsList(tagsData[type], type);
    }

    return null;
  };

  const groupLocalityTypesList = GroupLocalityTypes().map((el) => (
    <GroupLocalityItemWrap
      isSelected={el.value === groupInfo.zone}
      key={el.id}
      onClick={() => setGroupInfo({ ...groupInfo, zone: el.value })}
    >
      {el.icon(el.value === groupInfo.zone ? '#ffffff' : '#A9A9B5')}
      <GroupLocalityItemText>{el.name}</GroupLocalityItemText>
    </GroupLocalityItemWrap>
  ));

  const getSidebarHeader = () => {
    if (additionalSettings) {
      return additionalSettings === 1
        ? t('addNewGroup:groupPrivacy')
        : t('addNewGroup:postPermissions');
    }

    return t('myGroups:createGroup');
  };

  const onSubmit = () => {
    if (additionalSettings) {
      setGroupInfo({
        ...groupInfo,
        privacy: groupPrivacy,
        postPermission: groupPostPermissions,
      });
      setAdditionalSettings(0);
    } else {
      const userId = JSON.parse(getLocalStorage('user_id'));
      const selectedAdmins = tagsData.admins?.map((el) => el._id) || [];
      const defaultCollaborators = groupForEditData?.collaborators || [];
      const collaborators = tagsData.collaborators || [];
      const defaultLocation = {
        lat: undefined,
        lng: undefined,
        address: '',
      };

      if (groupInfo.website.trim().length) {
        const validationResult = validateUrl(groupInfo.website || null);
        const error = validationResult;

        if (error) {
          setLinkError(error.url[0]);
          return;
        }
      }

      const groupData = {
        ...groupInfo,
        coverPhoto: getCorrectImageBase64Format(groupInfo.coverPhoto),
        profilePhoto: getCorrectImageBase64Format(groupInfo.profilePhoto),
        admins: groupForEditData ? [userId, ...selectedAdmins] : selectedAdmins,
        interests: tagsData.interests?.map((el) => el._id) || [],
        brands: tagsData.brands?.map((el) => el._id) || [],
        collaborators:
          JSON.stringify(defaultCollaborators) === JSON.stringify(collaborators)
            ? []
            : collaborators.map((el) => el._id),
        location: tagsData.location || defaultLocation,
      };

      if (groupForEditData) {
        dispatch(editGroupStart({ groupId: groupForEditData._id, groupData }));
      } else {
        dispatch(addGroupStart(groupData));
      }
    }
  };

  const onSidebarHeaderIconClick = () => {
    if (additionalSettings) {
      setAdditionalSettings(0);
      setGroupPrivacy(groupInfo.privacy);
      setGroupPostPermissions(groupInfo.postPermission);
    } else {
      dispatch(closeSidebarCreateGroup());
    }
  };

  const renderAdditionalSettings = () => {
    const isPrivacy = additionalSettings === 1;

    return (isPrivacy ? PrivacyMock() : PermissionsMock()).map((elem) => (
      <GroupSettingRadio
        key={elem?.id}
        header={elem?.header}
        body={elem?.body}
        isSelected={
          elem?.id === (isPrivacy ? groupPrivacy : groupPostPermissions)
        }
        onClick={() => {
          if (isPrivacy) {
            setGroupPrivacy(elem?.id);
          } else {
            setGroupPostPermissions(elem?.id);
          }
        }}
      />
    ));
  };

  const getSubmitAvailability = () => {
    let submit = false;

    if (groupForEditData) {
      const {
        coverPhoto,
        profilePhoto,
        username,
        name,
        zone,
        description,
        isChat: chat,
        postPermission,
        privacy,
        website,
        interests,
        brands,
        collaborators,
        location,
      } = groupForEditData;

      const groupInitData = {
        coverPhoto,
        profilePhoto,
        username,
        name,
        zone,
        description,
        chat,
        postPermission,
        privacy,
        website,
      };
      const defaultTags = [interests, brands, collaborators, location];
      const tags = [
        tagsData.interests,
        tagsData.brands,
        tagsData.collaborators,
        tagsData.location,
      ];

      if (JSON.stringify(groupInitData) !== JSON.stringify(groupInfo)) {
        submit = true;
      } else {
        submit = false;
      }

      if (JSON.stringify(defaultTags) !== JSON.stringify(tags)) {
        submit = true;
      } else {
        submit = false;
      }
    }
    const { coverPhoto, profilePhoto, username, name, zone } = groupInfo;

    if (
      coverPhoto.length &&
      profilePhoto.length &&
      username.length &&
      name.length &&
      typeof zone === 'number' &&
      tagsData.interests?.length &&
      !linkError
    ) {
      submit = true;
    } else {
      submit = false;
    }

    return submit;
  };

  const onWebsiteInputBlur = (value) => {
    const validationResult = validateUrl(value || null);
    const error = validationResult;

    error && setLinkError(error.url[0]);
  };

  return (
    <SidebarWrapper
      headerText={getSidebarHeader()}
      headerIcon={
        additionalSettings ? (
          <ArrowBack />
        ) : (
          <CrossIcon color={style.mainBlack.black} />
        )
      }
      submitText={
        additionalSettings || groupForEditData
          ? t('editUserProfile:save')
          : t('event:Create')
      }
      isSubmitAvailable={additionalSettings ? true : getSubmitAvailability()}
      onSubmit={onSubmit}
      onIconClick={onSidebarHeaderIconClick}
      contentPadding="20px 20px 37px 20px"
    >
      {additionalSettings ? (
        renderAdditionalSettings()
      ) : (
        <>
          <ContentWrapper>
            <ImagesUploader
              coverPhoto={groupInfo.coverPhoto}
              profilePhoto={groupInfo.profilePhoto}
              isProfileImages
              height={groupInfo.coverPhoto ? 240 : 180}
              onProfileImagesChange={onFileInputChange}
              onRemovePhotoClick={onRemovePhotoClick}
              cropAspect={3 / 2}
            />

            <ContentHeaderText mt={40}>
              {t('event:requiredInfo')}
            </ContentHeaderText>

            <InputField
              value={groupInfo.username}
              onChange={(e) =>
                setGroupInfo({ ...groupInfo, username: e.target.value })
              }
              label={t('addNewGroup:groupUsername')}
              placeholder={t('addNewGroup:groupUsername')}
              crossIcon
              onCrossClick={() => setGroupInfo({ ...groupInfo, username: '' })}
            />
            <InputField
              value={groupInfo.name}
              onChange={(e) =>
                setGroupInfo({ ...groupInfo, name: e.target.value })
              }
              label={t('addNewGroup:groupFullName')}
              placeholder={t('addNewGroup:groupFullName')}
              crossIcon
              onCrossClick={() => setGroupInfo({ ...groupInfo, name: '' })}
              margin="17px 0 17px 0"
            />

            <Selector
              title={t('addNewGroup:groupInterests')}
              isSelectedItemsExist={
                tagsData.interests && !!tagsData.interests.length
              }
              onClick={() =>
                dispatch(
                  openSidebarTagWithAdding({
                    actionType: actionTypes.OPEN_SIDEBAR_TAG_WITH_ADDING,
                    tagType: 'interests',
                    sidebarHeaderStartText: t('notifications:group'),
                  })
                )
              }
              margin={`26px 0 ${
                tagsData.interests && !!tagsData.interests.length
                  ? '5px'
                  : '20px'
              } 0`}
            />
            {renderTagsList('interests') && (
              <div style={{ marginBottom: 22 }}>
                {renderTagsList('interests')}
              </div>
            )}

            <SectionHeader mb={18}>
              {t('addNewGroup:groupLocality')}
            </SectionHeader>
            <GroupLocalityWrap>{groupLocalityTypesList}</GroupLocalityWrap>
          </ContentWrapper>

          <ContentWrapper>
            <ContentHeaderText>
              {t('addNewGroup:optionalInfo')}
            </ContentHeaderText>

            <Selector
              title={t('addNewGroup:groupBrands')}
              isSelectedItemsExist={tagsData.brands && !!tagsData.brands.length}
              onClick={() =>
                dispatch(
                  openSidebarTag({
                    actionType: actionTypes.OPEN_SIDEBAR_TAG,
                    tagType: 'brands',
                    sidebarHeaderStartText: t('notifications:group'),
                  })
                )
              }
              margin={`24px 0 ${
                tagsData.brands && !!tagsData.brands.length ? '0' : '28px'
              } 0`}
            />
            {tagsData.brands && !!tagsData.brands.length && (
              <div style={{ marginBottom: 22 }}>
                {tagsList(tagsData.brands, 'brands')}
              </div>
            )}

            {/* <InputField
              type="textarea"
              value={groupInfo.description}
              onChange={(e) =>
                setGroupInfo({ ...groupInfo, description: e.target.value })
              }
              label={t('addNewGroup:addShortBioOrDescription')}
              placeholder={t('common:addADescription')}
              crossIcon
              onCrossClick={() =>
                setGroupInfo({ ...groupInfo, description: '' })
              }
              showCharactersNumber
              maxLength={500}
              height={88}
            /> */}
            <InputDescription
              defaultValue={groupForEditData?.description}
              onChange={(e) => setGroupInfo({ ...groupInfo, description: e })}
              label={t('addNewGroup:addShortBioOrDescription')}
              placeholder={t('common:addADescription')}
              // crossIcon
              // onCrossClick={() =>
              //   setGroupInfo({ ...groupInfo, description: '' })
              // }
              // showCharactersNumber
              maxLength={500}
              height={122}
            />

            <Selector
              title={t('addNewGroup:cityOrLocation')}
              isSelectedItemsExist={tagsData.location}
              onClick={() => dispatch(openSidebarChooseLocation())}
              leftIcon={<LocationIcon />}
              margin="18px 0 16px 0"
            />
            {tagsData.location && (
              <LocationText>{tagsData.location.address}</LocationText>
            )}

            <InputField
              value={groupInfo.website}
              onChange={(e) => {
                setGroupInfo({ ...groupInfo, website: e.target.value });
                linkError && setLinkError('');
              }}
              label={t('addNewGroup:groupWebsite')}
              placeholder={t('addNewGroup:groupWebsite')}
              crossIcon
              onCrossClick={() => {
                setGroupInfo({ ...groupInfo, website: '' });
                linkError && setLinkError('');
              }}
              onBlur={(e) => onWebsiteInputBlur(e.target.value)}
              margin="0 0 17px 0"
            />
            {linkError && <ErrorMessage text={linkError} mt={-11} />}

            <Selector
              title={t('profile:GroupAdmins')}
              isSelectedItemsExist={tagsData.admins && !!tagsData.admins.length}
              onClick={() =>
                dispatch(
                  openSidebarTag({
                    actionType: actionTypes.OPEN_SIDEBAR_TAG,
                    tagType: 'admins',
                    sidebarHeaderStartText: t('notifications:group'),
                  })
                )
              }
              margin={`0 0 ${
                tagsData.admins && !!tagsData.admins.length ? '0' : '14px'
              } 0`}
            />
            {tagsData.admins && !!tagsData.admins.length && (
              <div style={{ marginBottom: 8 }}>
                {tagsList(tagsData.admins, 'admins')}
              </div>
            )}

            <Selector
              title={t('addNewGroup:groupCollaborators')}
              isSelectedItemsExist={
                tagsData.collaborators && !!tagsData.collaborators.length
              }
              onClick={() =>
                dispatch(
                  openSidebarTag({
                    actionType: actionTypes.OPEN_SIDEBAR_TAG,
                    tagType: 'collaborators',
                    sidebarHeaderStartText: t('notifications:group'),
                  })
                )
              }
            />
            {tagsData.collaborators &&
              !!tagsData.collaborators.length &&
              tagsList(tagsData.collaborators, 'collaborators')}
          </ContentWrapper>

          <GroupSetting
            header={t('addNewGroup:groupPrivacy')}
            subHeader={PrivacyMock()[groupInfo?.privacy]?.header}
            text={PrivacyMock()[groupInfo?.privacy]?.body}
            onClick={() => setAdditionalSettings(1)}
          />
          <GroupSetting
            header={t('addNewGroup:postPermissions')}
            subHeader={PermissionsMock()[groupInfo?.postPermission - 1]?.header}
            text={PermissionsMock()[groupInfo?.postPermission - 1]?.body}
            onClick={() => setAdditionalSettings(2)}
          />
          <GroupSetting
            header={t('addNewGroup:groupChat')}
            subHeader={t('groupPrivacyOptions:membersPlus')}
            text={t('addNewGroup:groupChatWillBeDisabled')}
            isChat
            isChatActive={groupInfo.chat}
            onChatSettingClick={(val) =>
              setGroupInfo({ ...groupInfo, chat: val })
            }
          />
        </>
      )}
    </SidebarWrapper>
  );
};
