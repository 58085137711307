import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';

import Drawer from 'components/Drawer';
import CheckBox from 'components/common/CheckBox';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import { eventOwnerShipVariants } from 'utils/constants';
import { IEventOwnerShipDrawer } from './IEventOwnerShipDrawer';

import {
  AttendanceManagementContentBlock,
  AttendanceManagementContentBlockLeft,
  AttendanceManagementContentBlockRight,
  AttendanceManagementContentWrapper,
  BlockHeading,
  BlockText,
} from './EventOwnerShipDrawer.styles';

const EventOwnerShipDrawer = ({
  open,
  setOpen,
  setEventOwnerShip,
  form,
}: IEventOwnerShipDrawer) => {
  const [checked, setChecked] = useState<number>(3);

  const { t } = useTranslation();
  const { values } = useFormState();
  const eventOwnerShipOptions = [
    {
      id: 1,
      title: t('event:eventOwnership:realOwner'),
      key: eventOwnerShipVariants.REALOWNER,
      text: t('event:eventOwnership:realOwnerdesc'),
      value: true,
    },
    {
      id: 2,
      title: t('event:eventOwnership:notRealOwner'),
      key: eventOwnerShipVariants.NOTREALOWNER,
      text: t('event:eventOwnership:notRealOwnerdesc'),
      value: false,
    },
    {
      id: 3,
      title: t('event:eventOwnership:notChoosen'),
      key: eventOwnerShipVariants.NOTCHOOSEN,
      text: t('event:eventOwnership:notChoosendesc'),
      value: null,
    },
  ];

  useEffect(() => {
    const getData = eventOwnerShipOptions?.find(
      (i) => i?.value === values?.isRealOwner
    );
    if (getData) {
      const ownershipValue = eventOwnerShipOptions?.find(
        (el) => el?.key === getData?.key
      )?.value;
      setEventOwnerShip((prev) => ({
        ...prev,
        title: getData?.title,
        text: getData?.text,
        isRealOwner: ownershipValue,
      }));
      form?.change('isRealOwner', ownershipValue);
      setChecked(getData?.id);
    } else {
      setEventOwnerShip({
        title: eventOwnerShipOptions[2].title,
        text: eventOwnerShipOptions[2].text,
        isRealOwner: null,
      });
      form?.change('isRealOwner', null);
    }
  }, [values?.isRealOwner]);

  return (
    <Drawer
      open={open}
      headerText={t('event:eventOwnership:heading')}
      onIconClick={() => setOpen(false)}
      onDrawerClose={() => setOpen(false)}
      submitText={t('editUserProfile:save')}
      onSubmit={() => setOpen(false)}
    >
      <AttendanceManagementContentWrapper>
        {eventOwnerShipOptions?.map((d) => (
          <AttendanceManagementContentBlock
            key={d?.id}
            onClick={() => {
              const ownershipValue = eventOwnerShipOptions?.find(
                (el) => el?.key === d?.key
              )?.value;
              setEventOwnerShip((prev) => ({
                ...prev,
                title: d?.title,
                text: d?.text,
                isRealOwner: ownershipValue,
              }));
              form?.change('isRealOwner', ownershipValue);
              setChecked(d?.id);
            }}
            checked={checked === d?.id || values?.isRealOwner === d?.key}
          >
            <AttendanceManagementContentBlockRight>
              <CheckBox
                type="radio"
                name="attendance"
                checked={checked === d?.id || values?.isRealOwner === d?.key}
              >
                <AttendanceManagementContentBlockLeft>
                  <BlockHeading type="h4" fs={14}>
                    {d?.title}
                  </BlockHeading>
                  <BlockText>{d?.text}</BlockText>
                </AttendanceManagementContentBlockLeft>
              </CheckBox>
            </AttendanceManagementContentBlockRight>
          </AttendanceManagementContentBlock>
        ))}
      </AttendanceManagementContentWrapper>
    </Drawer>
  );
};

export default EventOwnerShipDrawer;
