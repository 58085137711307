import { EyeIcon, EyeWithLineIcon } from 'public/assets/icons';
import { useState } from 'react';
import ValidationError from 'components/ValidationError';

import { IInput } from './IField';
import {
  InputWrapper,
  InputField,
  Label,
  TextareaField,
  CrossIconWrapper,
  LeftIconWrapper,
  TextareaBottom,
} from './Input.styles';

const CONTENT_MAX_LENGTH = 5000;

const Input = ({
  id,
  customErrorText,
  meta,
  input,
  inputRef,
  rows = 1,
  label,
  prefix,
  suffix,
  maxLength,
  isControlledInput,
  ...restProps
}: IInput) => {
  const [inputType, setInputType] = useState<string>('password');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  if (label && !id) {
    throw new Error('id required when a label is given');
  }
  const isTextarea = input.type === 'textarea';
  const isPassword = input.type === 'password';

  const { valid, invalid, touched, error, submitError } = meta;

  const errorText = customErrorText || error || submitError;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError =
    !!customErrorText || !!(touched && invalid && (error || submitError));

  // Textarea doesn't need type.
  const { type, ...inputWithoutType } = input;

  // Uncontrolled input uses defaultValue instead of value.
  const { value: defaultValue, ...inputWithoutValue } = input;
  // Use inputRef if it is passed as prop.
  const refMaybe = inputRef ? { ref: inputRef } : {};
  const maximumLength = maxLength || CONTENT_MAX_LENGTH;

  const handleTypeChange = () => {
    if (!showPassword) {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };

  const inputProps: any = isTextarea
    ? {
        id,
        rows,
        maxLength: maximumLength,
        error: hasError,
        ...refMaybe,
        ...inputWithoutType,
        ...restProps,
      }
    : {
        id,
        type,
        defaultValue,
        error: hasError,
        ...refMaybe,
        ...inputWithoutValue,
        ...restProps,
      };
  const renderRightIcon = () => {
    if (type === 'password') {
      return (
        <CrossIconWrapper
          onClick={() => {
            setShowPassword(!showPassword);
            handleTypeChange();
          }}
        >
          {showPassword ? <EyeWithLineIcon /> : <EyeIcon />}
        </CrossIconWrapper>
      );
    }
  };
  if (isControlledInput) {
    inputProps.value = defaultValue;
  }

  return (
    <InputWrapper>
      {label && <Label htmlFor={id}>{label}</Label>}
      {isTextarea ? (
        <>
          <TextareaField {...inputProps} />
          {maxLength && (
            <TextareaBottom
              valid={typeof hasError === 'undefined' ? false : hasError}
            >
              <span>{`${input?.value?.length} / ${maxLength}`}</span>
              <ValidationError touched={hasError} error={errorText} />
            </TextareaBottom>
          )}
        </>
      ) : isPassword ? (
        <>
          <InputField {...inputProps} type={inputType} />
          {renderRightIcon()}
        </>
      ) : (
        <>
          {prefix && (
            <LeftIconWrapper
              valid={typeof hasError === 'undefined' ? false : hasError}
            >
              {prefix}
            </LeftIconWrapper>
          )}
          <InputField
            {...inputProps}
            valid={typeof hasError === 'undefined' ? false : hasError}
          />
          {suffix}
        </>
      )}

      {!isTextarea && <ValidationError touched={hasError} error={errorText} />}
    </InputWrapper>
  );
};

export default Input;
