/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import UploadImage from 'components/common/UploadImage';
import { InputDescriptionField } from 'components';
import CreatePostQuickTags from 'containers/CreatePostQuickTags';
import SchedulePost from 'containers/SchedulePost';
import { composeValidators, required } from 'utils/validators';
import Input from 'components/common/Input';
import {
  DesktopLoginHeaderRight,
  SeparateSettingsHeader,
} from 'forms/CreateEventPostForm/CreateEventPostForm.styles';
import PostCreatorSelect from 'forms/CreateEventPostForm/PostCreatorSelect';
import { ICreateDiscussionPostForm } from './ICreateDiscussionPostForm';

import {
  FinalFormWrapper,
  FormWrapper,
  SectionHeader,
  SectionWrapper,
} from './CreateDiscussionPostForm.styles';
import Groups from './Groups';
import Users from './Users';
import Events from './Events';

const CreateDiscussionPostForm = (props: ICreateDiscussionPostForm) => {
  const { t } = useTranslation();

  const [scheduledPostData, setScheduledPostData] = useState<{
    date: any;
    error: string;
  }>({
    date: new Date(),
    error: '',
  });

  return (
    <>
      <FinalFormWrapper
        {...props}
        render={(formRenderProps: FormRenderProps) => {
          const { handleSubmit, form, values, initialValues } = formRenderProps;
          const {
            inProgress,
            ready,
            interests,
            privateFeatures,
            onIconClick,
            variant,
          } = props;
          const submitAbility =
            values?.title?.trim() && values?.description?.trim();
          const handleClubSelect = (clubId: string, isUser: boolean) => {
            if (!isUser) {
              form.change('origin', 'group');
              form.change('originId', clubId);
            } else {
              form.change('postCreator', clubId);
            }
          };
          return (
            <FormWrapper
              onSubmit={handleSubmit}
              onIconClick={onIconClick}
              isHeaderBorder
              headerText={
                variant === 'create'
                  ? t('selectPhotos:createDiscussion')
                  : t('selectPhotos:editDiscussionPost')
              }
              submitText={t('editUserProfile:Save')}
              disabled={!submitAbility}
            >
              <SeparateSettingsHeader mt={10} mb={15}>
                {t('event:postingAs')}
              </SeparateSettingsHeader>
              <SeparateSettingsHeader mb={10} mt={10}>
                <DesktopLoginHeaderRight>
                  <PostCreatorSelect
                    onClubSelect={handleClubSelect}
                    initialValues={initialValues}
                  />
                </DesktopLoginHeaderRight>
              </SeparateSettingsHeader>
              <SectionWrapper>
                <SectionHeader type="h3">
                  {t('addNewGroup:requiredInfo')}
                </SectionHeader>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  label={t('addPointOfInterest:title')}
                  validate={composeValidators(
                    required(t('error:thisFieldIsRequired'))
                  )}
                />
                <InputDescriptionField
                  placeholder={t('common:addADescription')}
                  label={t('common:addADescription')}
                  name="description"
                  showCharactersNumber
                  maxLength={500}
                  height={88}
                  validate={composeValidators(
                    required(t('error:thisFieldIsRequired'))
                  )}
                />
              </SectionWrapper>
              <SectionWrapper>
                <SectionHeader type="h3">
                  {t('addNewGroup:optionalInfo')}
                </SectionHeader>
                <UploadImage
                  name="images"
                  label="Add a photo"
                  isAspect={false}
                />
              </SectionWrapper>

              <SectionWrapper>
                <SectionHeader type="h3">{t('common:addTags')}</SectionHeader>
                <Groups />
                <Users />
                <Events />
              </SectionWrapper>
              <Field name="quickTags">
                {({ input }) => {
                  return (
                    <CreatePostQuickTags
                      interests={interests}
                      quickTags={input.value}
                      setQuickTags={input.onChange}
                      {...input}
                    />
                  );
                }}
              </Field>
              {privateFeatures && (
                <Field name="schedulePost">
                  {({ input }) => {
                    form.change('schedulePost', scheduledPostData);
                    return (
                      <SchedulePost
                        scheduledPostData={scheduledPostData}
                        setScheduledPostData={setScheduledPostData}
                        {...input}
                      />
                    );
                  }}
                </Field>
              )}
            </FormWrapper>
          );
        }}
      />
    </>
  );
};

export default CreateDiscussionPostForm;
