import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  CircleWithPlus,
  GarageIcon,
  GroupsIcon,
  LinkIcon,
  MeetUpIcon,
  PhotoIcon,
  RoadShareIcon,
  DiscussionIcon,
} from 'public/assets/icons';
import appState from 'hooks/appState';
import { APP_STATE } from 'types';
import Dropdown from 'components/Dropdown';
import {
  openSidebarCreateEvent,
  openSideBarCreateLinkPost,
  openSideBarCreatePhotoPost,
} from 'store/sideBarControler/actions';
import { useTheme } from 'styled-components';
import { useRouter } from 'next/router';
import { routesPath } from 'utils/constants/routePath';
import { ICreateActionsButton } from './IHeader';

import { CreateButton, IconWrapper, TextWrapper } from './Header.styles';

const CreateActionsButton = ({ disabled }: ICreateActionsButton) => {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const dispatch = useDispatch();
  const appStateAction = appState();
  const router = useRouter();

  const createItems = [
    {
      items: [
        {
          id: 1,
          icon: <PhotoIcon />,
          text: t('event:photoPost'),
          onClick: () =>
            appStateAction(APP_STATE.CREATE_PHOTO_POST_FORM, {
              isOpen: true,
            }),
        },
        {
          id: 2,
          icon: <LinkIcon />,
          text: t('event:linkPost'),
          onClick: () => {
            // dispatch(openSideBarCreateLinkPost({}));
            appStateAction(APP_STATE.CREATE_LINK_POST_FORM, {
              isOpen: true,
            });
          },
        },
        {
          id: 3,
          icon: <MeetUpIcon />,
          text: t('common:event'),
          onClick: () =>
            appStateAction(APP_STATE.EVENT_CREATE_FORM, {
              isOpen: true,
            }),
        },
        {
          id: 4,
          icon: <RoadShareIcon fill={theme.colors.neutral.n8} />,
          text: t('addPointOfInterest:route'),
          onClick: () =>
            router.push({
              pathname: routesPath.mapPath,
              query: { isNewRoute: true },
            }),
        },
        {
          id: 5,
          icon: <GarageIcon />,
          text: t('map:vehicle'),
          onClick: () =>
            appStateAction(APP_STATE.ADD_VEHICLE_FORM, {
              isOpen: true,
            }),
        },
        {
          id: 6,
          icon: <GroupsIcon />,
          text: t('notifications:group'),
          onClick: () =>
            appStateAction(APP_STATE.CREATE_GROUP_DRAWER, {
              isOpen: true,
            }),
        },
        {
          id: 7,
          icon: <DiscussionIcon height="24" width="24" color="#8D8D8D" />,
          text: t('discussions:discussions'),
          onClick: () =>
            appStateAction(APP_STATE.CREATE_DISCUSSION_FORM, { isOpen: true }),
        },
      ],
    },
  ];

  return (
    <Dropdown sections={createItems} variant="left">
      <CreateButton>
        <IconWrapper>
          <CircleWithPlus disabled={disabled} />
        </IconWrapper>
        <TextWrapper>{t('home:create')}</TextWrapper>
      </CreateButton>
    </Dropdown>
  );
};

export default CreateActionsButton;
