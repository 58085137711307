import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import Drawer from 'components/Drawer';
import CreatePhotoPostForm from 'forms/CreatePhotoPostForm';
import { closeSideBarCreatePhotoPost } from 'store/sideBarControler/actions';
import appState from 'hooks/appState';
import { APP_STATE } from 'types';
import { uploadToS3 } from 'utils/aws/upload-file';
import { routesPath } from 'utils/constants/routePath';

import { getInterestsStart } from 'store/user/actions';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';
import { ICreatePhotoPost } from './ICreatePhotoPost';

import { CreatePhotoPostWrapper } from './CreatePhotoPost.styles';

const CreatePhotoPost = ({ open }: ICreatePhotoPost) => {
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { action, loading } = fetchApi();
  const router = useRouter();

  const appStateStore = useSelector((store) => store.app);

  const appStateAction = appState();

  const variant =
    appStateStore[APP_STATE.EDIT_PHOTO_POST_FORM]?.variant || 'create';
  const data = appStateStore[APP_STATE.EDIT_PHOTO_POST_FORM]?.data || undefined;

  const getUserInterests = useSelector((store) => store.fetchData);

  const isEditForm = variant === 'edit';

  const fetchData = useCallback(() => {
    action(API_NAME.GET_USER_INTERESTS, {});
  }, []);

  useEffect(() => {
    if (!(getUserInterests[API_NAME.GET_USER_INTERESTS]?.data?.length > 0)) {
      fetchData();
    }
  }, []);

  const onCreatePhotoPost = async (v) => {
    try {
      if (!isEditForm) {
        router.push(`${routesPath.feedPath}`);
      }

      appStateAction(APP_STATE.CREATE_PHOTO_POST_FORM, {
        isOpen: false,
        isCreatingPost: true,
      });
      const resMedia = !isEditForm ? await uploadToS3(v?.images) : [];
      const bodyData = {
        type: 1,
        ...(!isEditForm && { media: [...resMedia] }),
        text: v?.description,
        postCreator: v?.postCreator,
        origin: v?.origin,
        originId:v?.originId,
        hashtags: v?.quickTags?.map((i) => i?._id),
        events: v?.events?.map((i) => i?._id),
        groups: v?.groups?.map((i) => i?._id),
        users: v?.users?.map((i) => i?._id),
        ...(v?.location?.name &&
          v?.location?.lat &&
          v?.location?.lng && {
            location: {
              lat: v?.location?.lat,
              lng: v?.location?.lng,
              name: v?.location?.name,
            },
          }),
      };
      if (isEditForm) {
        const res = await action(API_NAME.UPDATE_PHOTO_POST, {
          params: {
            postId: appStateStore[APP_STATE.EDIT_PHOTO_POST_FORM]?.postId,
          },
          body: bodyData,
        });

        appStateAction(APP_STATE.EDIT_PHOTO_POST_FORM, {
          isOpen: false,
        });

        return;
      }
      const res = await action(API_NAME.CREATE_PHOTO_POST, {
        body: bodyData,
      });
      if (res?.code === 200) {
        appStateAction(APP_STATE.CREATE_PHOTO_POST_FORM, {
          isCreatingPost: false,
          data: {
            ...res?.data,
            mediaObjects: res?.data?.mediaObjects?.map((item, i) => ({
              ...item,
              url: v?.images[i],
            })),
          },
          type: 1,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer
      open={
        variant === 'create'
          ? appStateStore[APP_STATE.CREATE_PHOTO_POST_FORM]?.isOpen
          : appStateStore[APP_STATE.EDIT_PHOTO_POST_FORM]?.isOpen
      }
      onDrawerClose={() =>
        variant === 'create'
          ? appStateAction(APP_STATE.CREATE_PHOTO_POST_FORM, {
              isOpen: false,
            })
          : appStateAction(APP_STATE.EDIT_PHOTO_POST_FORM, {
              isOpen: false,
            })
      }
      rootForm
    >
      <CreatePhotoPostForm
        onSubmit={onCreatePhotoPost}
        interests={
          getUserInterests[API_NAME.GET_USER_INTERESTS]?.data?.length > 0 &&
          getUserInterests[API_NAME.GET_USER_INTERESTS]?.data
        }
        privateFeatures={false}
        setIsDrawerOpen={setIsDrawerOpen}
        onIconClick={() =>
          variant === 'create'
            ? appStateAction(APP_STATE.CREATE_PHOTO_POST_FORM, {
                isOpen: false,
              })
            : appStateAction(APP_STATE.EDIT_PHOTO_POST_FORM, {
                isOpen: false,
              })
        }
        variant={typeof variant !== 'undefined' && variant}
        initialValues={{
          ...(data && {
            images: data?.media,
            description: data?.text,
            // addTags: {
            groups: data?.groups,
            users: data?.users,
            events: data?.events,
            location: data?.location,
            // },
            quickTags: data?.interests,
            creator: data?.creator,
            group: data?.creator?.isGroup ? data?.creator?.data?._id : null,
          }),
        }}
      />
    </Drawer>
  );
};

export default CreatePhotoPost;
