import { HYDRATE } from 'next-redux-wrapper';

const actionTypes = {
  UPDATE_GROUP_DISCOVERY_FILTER: 'UPDATE_GROUP_DISCOVERY_FILTER',
  UPDATE_EVENT_DISCOVERY_FILTER: 'UPDATE_EVENT_DISCOVERY_FILTER',
  SET_ACTIVE_FEED_FILTER: 'SET_ACTIVE_FEED_FILTER',
  SET_ACTIVE_EVENT_FILTER: 'SET_ACTIVE_EVENT_FILTER',
};

const initialState = {
  groupDiscoveryFilter: {
    groupIds: [],
    zone: null,
    types: [],
    popular: false,
    following: [],
  },
  eventDiscoveryFilter: {},
  activeFeedFilter: null,
  activeEventFilter: null,
};

// Reducer
export const reducer = (state = initialState, action) => {
  console.log('ACTION TYPE IS', action?.type);
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }
    case actionTypes.UPDATE_GROUP_DISCOVERY_FILTER:
      return {
        ...state,
        groupDiscoveryFilter: {
          ...state.groupDiscoveryFilter,
          ...action.payload,
        },
      };
    case actionTypes.UPDATE_EVENT_DISCOVERY_FILTER:
      console.log('UPDATING EVENT DISCOVERY FILTER', action?.payload, {
        ...state,
        eventDiscoveryFilter: {
          ...state.eventDiscoveryFilter,
          ...action.payload,
        },
      });
      return {
        ...state,
        eventDiscoveryFilter: {
          ...state.eventDiscoveryFilter,
          ...action.payload,
        },
      };
    case actionTypes.SET_ACTIVE_FEED_FILTER:
      return {
        ...state,
        activeFeedFilter: action.payload,
      };
    case actionTypes.SET_ACTIVE_EVENT_FILTER:
      return {
        ...state,
        activeEventFilter: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
