import styled from 'styled-components';
import { Form as FinalForm } from 'react-final-form';

import Heading from 'components/common/Typography/Heading';
import DrawerForm from 'forms/DrawerForm';
import Text from 'components/common/Text';
import Pills from 'components/common/Pills';
import Input from 'components/common/Input';

interface IProps {
  pt?: number;
  pb?: number;
  mt?: number;
  mb?: number;
  fs?: number;
  fw?: number;
  italic?: boolean;
}

export const FinalFormWrapper = styled(FinalForm)``;

export const FormWrapper = styled(DrawerForm)``;

// export const SectionWrapper = styled.div<IProps>`
//   padding-top: 0;
//   padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '16.5px')};
//   border-bottom: 1px dashed ${(props) => props.theme.colors.neutral.n4};

//   :last-of-type {
//     border: none;
//   }

//   & > div {
//     &:nth-child(2) {
//       & > input {
//         height: 44px;
//         padding: 0 16px 0 36px;
//       }
//     }
//   }
// `;

export const SectionWrapper = styled.div<IProps>`
  padding-top: ${({ pt }) => (pt ? `${pt}px` : '0')};
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '16.5px')};
  border-bottom: 1px dashed ${(props) => props.theme.colors.neutral.n4};

  :last-of-type {
    border: none;
  }
  & > div {
    &:nth-child(2) {
      & > input {
        height: 44px;
        padding: 0 16px 0 36px;
      }
    }
  }
`;

export const SectionHeader = styled(Heading)`
  font-size: ${(props) => props.theme.typography.fontSize.f7};
  line-height: 130%;
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 13px;
`;

export const SelectedItemWrapper = styled.div``;
export const SelectedItemText = styled(Text)`
  padding: 10px 10px 10px 16px;
  background-color: ${(props) => props.theme.colors.neutral.n0};
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  color: ${(props) => props.theme.colors.neutral.n7};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
`;
export const SelectedValues = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 16px 8px;
`;

export const ItemForSelectWrapper = styled.div``;
export const ItemForSelectText = styled(Text)`
  padding: 10px 10px 10px 16px;
  background-color: ${(props) => props.theme.colors.neutral.n0};
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  color: ${(props) => props.theme.colors.neutral.n7};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
`;

export const ItemForSelectValue = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
`;

export const RenderPills = styled(Pills)``;
export const FormRenderPills = styled(Pills)`
  padding: 0 6px 0;
`;

export const SelectedPills = styled(Pills)`
  padding: 0 8px 0;
  & > span {
    &:last-child {
      & > svg {
        & > path {
          stroke: ${(props) => props.theme.colors.white};
        }
      }
    }
  }
`;

export const NoResultWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
`;

export const NoResultText = styled(Text)`
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.neutral.n7};
  text-align: center;
  margin-top: 0;
  margin-bottom: 42px;
`;

export const FormSearchWrapper = styled.div`
  padding: 20px;
  & > div {
    & > div {
    }
  }
`;
export const FormSearch = styled(Input)`
  padding: 0;
  padding-left: 35px;
  height: 44px;
`;

export const SelectorWrapper = styled.div`
  margin-top: 21px;
`;

export const InputWrapper = styled.div`
  padding-top: 20px;

  & > div {
    & > div {
      bottom: 33px;
      top: 33px;
    }
  }

  & input {
    &::placeholder {
      color: ${(props) => props.theme.colors.neutral.n7};
      font-weight: ${(props) => props.theme.typography.fontSize.f5};
      font-weight: ${(props) => props.theme.typography.fontWeight.regular};
    }
  }

  & textarea {
    height: 88px;
    width: 100%;
    box-sizing: border-box;
    &::placeholder {
      color: ${(props) => props.theme.colors.neutral.n7};
      font-size: ${(props) => props.theme.typography.fontSize.f5};
      font-weight: ${(props) => props.theme.typography.fontWeight.regular};
    }
  }
`;

export const WebsiteInput = styled(Input)`
  padding-left: 35px;
`;

export const PhoneInput = styled(Input)`
  padding-left: 35px;
`;

export const EmailInput = styled(Input)`
  padding-left: 35px;
`;

export const SelectedItemsWrap = styled.div`
  height: 118px;
  background-color: ${(props) => props.theme.colors.neutral.n0};
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  overflow: auto;

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.neutral.n0};
  }

  &::-webkit-scrollbar {
    background-color: ${(props) => props.theme.colors.neutral.n0};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.neutral.n6};
    border-radius: 10px;
  }
`;

export const NoSelectedItemWrap = styled.div`
  width: 100%;
  height: 118px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.neutral.n0};
  margin-bottom: 20px;
`;

export const NoSelectedItemText = styled(Text)<IProps>`
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.neutral.n7};
  font-style: ${({ italic }) => (italic ? 'italic' : 'initial')};
  text-align: center;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const SelectedItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;
`;
export const ItemImageWrap = styled.div`
  display: flex;
  margin-bottom: 4px;
  & > span {
    border-radius: 50%;
  }
`;
export const ItemName = styled(Text)`
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  width: 100%;
  max-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;
export const SeparateSettingsHeader = styled(Heading)<IProps>`
  font-size: ${({ fs, theme }) =>
    fs ? `${fs}px` : theme.typography.fontSize.f6};
  line-height: 150%;
  font-weight: ${({ fw }) => fw || '600'};
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.text};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;
export const DesktopLoginHeaderRight = styled.div`
  display: flex;

  & > div {
    display: flex;
    & > button {
      position: relative;
      z-index: 120;
    }
    &:not(:last-child) {
      margin-right: 10px;
    }

    &:first-child {
      & > div {
        right: auto;
      }
    }
  }
`;
