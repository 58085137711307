import { getDirectionsForDriving } from 'services';
import { FINISH_WAYPOINT_TYPE, START_WAYPOINT_TYPE } from './constants';
import { call } from 'redux-saga/effects';

export const getWaypointsArray = (waypointArr: any[], newWaypoint: any) => {
  if (!waypointArr?.length) {
    return [{ ...newWaypoint, type: START_WAYPOINT_TYPE }];
  }
  if (waypointArr.length === 1) {
    if (
      waypointArr?.find((_waypoint) => _waypoint?.type === FINISH_WAYPOINT_TYPE)
    ) {
      return [{ ...newWaypoint, type: START_WAYPOINT_TYPE }].concat(
        waypointArr
      );
    }
    return waypointArr.concat({ ...newWaypoint, type: FINISH_WAYPOINT_TYPE });
  }
  return reAssignTypeToWaypoints([...waypointArr, newWaypoint]);
};
export const reAssignTypeToWaypoints = (waypointArr: any) =>
  waypointArr.map((_waypoint: any, index: number) => ({
    ..._waypoint,
    type:
      index === 0
        ? START_WAYPOINT_TYPE
        : index === waypointArr?.length - 1
        ? FINISH_WAYPOINT_TYPE
        : null,
  }));

export const getOverlappingChunksCoordinates = (
  coordinates: any,
  chunkSize = 25
) => {
  const coordsArray = coordinates.split(';').filter((coord) => coord); // Split into array, filter out empty strings
  const chunks = [];

  for (let i = 0; i < coordsArray.length - 1; i += chunkSize - 1) {
    const chunk = coordsArray.slice(i, i + chunkSize);
    chunks.push(chunk.join(';'));
  }

  return chunks;
};

export function* fetchChunkedDirections(chunkedCoordinates: string[]) {
  let responses = [];
  let errorData = null;
  for (const coordinates of chunkedCoordinates) {
    const response = yield call(getDirectionsForDriving, {
      coordinates,
    } as any);
    if (!response || response.code !== 'Ok') {
      errorData = response;
      responses = [];
      break;
    }
    responses.push(response);
  }
  return { responses, errorData };
}

export const mergeRouteJson = (responses: any) => {
  let mergedRoute = null;
  responses.forEach((response: any) => {
    const { routes } = response;
    const route = routes[0]; // Assuming we want to use the first route from each response
    if (mergedRoute === null) {
      mergedRoute = { ...route };
    } else {
      // Merge routes
      mergedRoute.distance += route.distance;
      mergedRoute.duration += route.duration;
      mergedRoute.geometry.coordinates.push(...route.geometry.coordinates);
      mergedRoute.legs.push(...route.legs);
    }
  });

  return { routes: [mergedRoute] };
};
