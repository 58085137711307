import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { composeValidators, required } from 'utils/validators';
import FieldElect from 'components/common/FieldElect';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';

import { PlusIcon, SearchIcon, WheelIcon } from 'public/assets/icons';
import { useField, useForm } from 'react-final-form';
import { addCarBrandStart } from 'store/vehicle/actions';
import {
  FormRenderPills,
  ItemForSelectText,
  ItemForSelectValue,
  ItemForSelectWrapper,
  RenderPills,
  SelectedPills,
  SelectedItemWrapper,
  SelectedItemText,
  SelectedValues,
  NoResultWrap,
  NoResultText,
  FormSearch,
  FormSearchWrapper,
  NewAttrContainer,
  NewAttrTouchable,
  NewAttrText,
  NewAttrTextStatic,
  NewAttrTextDynamic,
  PlusIconWrap,
} from './VehicleForm.styles';

const search = (data: any[], searchValue: string) => {
  const searchValueLowerCase = searchValue?.toLocaleLowerCase();

  return data?.filter((elem) =>
    elem?.name?.toLowerCase()?.includes(searchValueLowerCase)
  );
};

const Make = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme: any = useTheme();
  const searchValue = useField('search').input.value;
  const form = useForm();
  const getCarBrands = useSelector((store) => store.fetchData);

  const { action, loading } = fetchApi();

  const fetchData = useCallback(() => {
    action(API_NAME.CAR_GET_CAR_BRANDS, {});
  }, []);

  useEffect(() => {
    if (!(getCarBrands[API_NAME.CAR_GET_CAR_BRANDS]?.data?.length > 0)) {
      fetchData();
    }
  }, []);

  const handleSelect = (value: string, onChange: (e: string) => void) => {
    const selected = getCarBrands[API_NAME.CAR_GET_CAR_BRANDS]?.data?.find(
      (brand: { name: string }) => brand.name === value
    );

    if (!selected && searchValue) {
      dispatch(addCarBrandStart({ brand: value }));
      onChange(searchValue);
    } else {
      onChange(value);
    }
    form.change('search', '');
  };
  const hasBrands = getCarBrands[API_NAME.CAR_GET_CAR_BRANDS]?.data?.length > 0;
  const searchedBrands = useMemo(
    () =>
      hasBrands
        ? search(getCarBrands[API_NAME.CAR_GET_CAR_BRANDS]?.data, searchValue)
        : [],
    [hasBrands, searchValue]
  );

  return (
    <FieldElect
      name="make"
      label={t('addVehicle:make')}
      headerText="My brands"
      multipleSelect={false}
      render={(b, onRemove) =>
        b?.length > 0 && (
          <RenderPills
            key={b}
            onCrossClick={() => onRemove(b)}
            bgColor={theme.colors.neutral.n0}
            type="addTags"
            isCross
          >
            {b}
          </RenderPills>
        )
      }
      formRender={(value, onChange) => {
        return (
          <>
            <FormSearchWrapper>
              <FormSearch
                name="search"
                prefix={<SearchIcon colorStroke={theme.colors.neutral.n6} />}
              />
            </FormSearchWrapper>
            {!!searchValue && !searchedBrands?.length && (
              <NewAttrContainer>
                <NewAttrTouchable
                  onClick={() => handleSelect(searchValue, onChange)}
                >
                  <PlusIconWrap>
                    <PlusIcon />
                  </PlusIconWrap>
                  <NewAttrText>
                    <NewAttrTextStatic>{t('add')}</NewAttrTextStatic>
                    <NewAttrTextDynamic>{`" ${searchValue} "`}</NewAttrTextDynamic>
                    <NewAttrTextStatic>{`${t(
                      'addVehicle:asNew'
                    )} `}</NewAttrTextStatic>
                  </NewAttrText>
                </NewAttrTouchable>
              </NewAttrContainer>
            )}
            {value?.length > 0 && (
              <SelectedItemWrapper>
                <SelectedItemText>{t('common:selected')}</SelectedItemText>
                <SelectedValues>
                  {value && (
                    <SelectedPills
                      key={value}
                      onCrossClick={() => onChange()}
                      bgColor={theme.colors.blue}
                      color={theme.colors.white}
                      type="addTags"
                      isCross
                    >
                      {value}
                    </SelectedPills>
                  )}
                </SelectedValues>
              </SelectedItemWrapper>
            )}

            <ItemForSelectWrapper>
              <ItemForSelectText>
                {t('addVehicle:selectCarMake')}
              </ItemForSelectText>
              <ItemForSelectValue>
                {hasBrands ? (
                  searchedBrands?.map((s, i) => {
                    return (
                      <FormRenderPills
                        key={s?._id || i}
                        onSelectItemClick={() => onChange(s?.name)}
                        bgColor={
                          s?.name === value
                            ? theme.colors.blue
                            : theme.colors.neutral.n0
                        }
                        color={
                          s?.name === value
                            ? theme.colors.white
                            : theme.colors.neutral.text
                        }
                        type="addTags"
                      >
                        {s?.name}
                      </FormRenderPills>
                    );
                  })
                ) : (
                  <NoResultWrap>
                    <NoResultText>{t('chat:nothingFound')}</NoResultText>
                    <WheelIcon />
                  </NoResultWrap>
                )}
              </ItemForSelectValue>
            </ItemForSelectWrapper>
          </>
        );
      }}
      validate={composeValidators(required(t('error:thisFieldIsRequired')))}
    />
  );
};

export default Make;
