import { ICheckBox } from './ICheckBox';

import {
  CheckBoxWrapper,
  CheckBoxInput,
  CheckMark,
  CheckBoxChildrenWrapper,
  CheckBoxInputWrapper,
} from './CheckBox.styles';

const CheckBox = ({
  type = 'checkbox',
  name,
  htmlFor,
  inputId,
  disabled,
  checked,
  children,
}: ICheckBox) => {
  return (
    <CheckBoxWrapper htmlFor={htmlFor}>
      <CheckBoxChildrenWrapper>{children}</CheckBoxChildrenWrapper>
      <CheckBoxInputWrapper>
        <CheckBoxInput
          id={type === 'radio' ? htmlFor : inputId}
          type={type}
          name={name}
          disabled={disabled}
          checked={checked}
          onChange={() => {}}
        />
        <CheckMark type={type} disabled={disabled} />
      </CheckBoxInputWrapper>
    </CheckBoxWrapper>
  );
};

export default CheckBox;
