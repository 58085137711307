import { useTranslation } from 'react-i18next';

import {
  PostCreatorList,
  PostCreatorListWrapper,
} from './CreateEventPostForm.styles';
import { IPostCreatorWrapper } from './ICreateEventPostForm';
import PostCreatorsList from './PostCreatorsList';
import { useCallback, useRef } from 'react';
import { LoadingComponent } from 'components';

const PostCreatorWrapper = ({
  isLoading,
  listItems = [],
  shouldPaginate,
  onLoadMore,
  onSelect,
  selected,
}: IPostCreatorWrapper) => {
  const enablePagination = shouldPaginate && !!onLoadMore;
  const observer = useRef<IntersectionObserver | null>(null);
  const { t } = useTranslation();
  
  const lastItem = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && enablePagination) {
          onLoadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [onLoadMore, enablePagination]
  );

  const handleSelect = (club) => {
    if (onSelect) {
      onSelect(club);
    }
  };

  return (
    <PostCreatorListWrapper>
      <PostCreatorList>
        
        <>
          {listItems?.map((item, index) => (
            <PostCreatorsList
              ref={index === listItems.length - 1 ? lastItem : undefined}
              key={index}
              _id={item?._id}
              coverPhoto={item?.profilePhoto}
              name={item?.name}
              username={item?.username}
              selected={item?._id === selected}
              onSelect={handleSelect}
              isUser={item?.isUser}
            />
          ))}
          {isLoading  && (
            <LoadingComponent
              fullWidth
              color="#C2C2C2"
              variant="spin"
              wrapHeight={50}
              loaderSize={15}
            />
          )}
        </>
      </PostCreatorList>
    </PostCreatorListWrapper>
  );
};

export default PostCreatorWrapper;
