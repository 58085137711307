import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { routesPath } from 'utils/constants/routePath';
import Drawer from 'components/Drawer';
import { closeSideBarCreateLinkPost } from 'store/sideBarControler/actions';
import CreateLinkPostForm from 'forms/CreateLinkPostForm';
import appState from 'hooks/appState';
import API_NAME from 'services/api';
import { APP_STATE } from 'types';
import { getInterestsStart } from 'store/user/actions';
import fetchApi from 'hooks/fetchApi';
import { ICreateLinkPost } from './ICreateLinkPost';

import { CreateLinkPostWrapper } from './CreateLinkPost.styles';

const CreateLinkPost = ({ open }: ICreateLinkPost) => {
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const appStateStore = useSelector((store) => store.app);

  const { action, loading } = fetchApi();
  const appStateAction = appState();
  const router = useRouter();

  const getUserInterests = useSelector((store) => store.fetchData);

  const fetchData = useCallback(() => {
    action(API_NAME.GET_USER_INTERESTS, {});
  }, []);

  useEffect(() => {
    if (!(getUserInterests[API_NAME.GET_USER_INTERESTS]?.data?.length > 0)) {
      fetchData();
    }
  }, []);

  const onCreateLinkPost = async (v) => {
    try {
      router.push(`${routesPath.feedPath}`);
      appStateAction(APP_STATE.CREATE_LINK_POST_FORM, {
        isOpen: false,
        isCreatingPost: true,
      });
      const bodyData = {
        link: v?.link,
        text: v?.description,
        postCreator: v?.postCreator,
        origin: v?.origin,
        originId: v?.originId, 
        interests: v?.quickTags?.map((i) => i?._id),
        events: v?.events?.map((i) => i?._id),
        groups: v?.groups?.map((i) => i?._id),
        users: v?.users?.map((i) => i?._id),
        location: v?.location,
        type: 3,
      };
      const res = await action(API_NAME.CREATE_LINK_POST, {
        body: bodyData,
      });
      if (res?.code === 200) {
        appStateAction(APP_STATE.CREATE_LINK_POST_FORM, {
          isCreatingPost: false,
          data: res?.data,
          type: 3,
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <Drawer
      open={appStateStore[APP_STATE.CREATE_LINK_POST_FORM]?.isOpen}
      onDrawerClose={() =>
        appStateAction(APP_STATE.CREATE_LINK_POST_FORM, {
          isOpen: false,
        })
      }
      loading={loading[APP_STATE.CREATE_LINK_POST_FORM]}
      rootForm
    >
      <CreateLinkPostForm
        onSubmit={onCreateLinkPost}
        interests={
          getUserInterests[API_NAME.GET_USER_INTERESTS]?.data?.length > 0 &&
          getUserInterests[API_NAME.GET_USER_INTERESTS]?.data
        }
        privateFeatures={false}
        setIsDrawerOpen={setIsDrawerOpen}
        onIconClick={() =>
          appStateAction(APP_STATE.CREATE_LINK_POST_FORM, {
            isOpen: false,
          })
        }
      />
    </Drawer>
  );
};

export default CreateLinkPost;
