import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CircleWithArrowIcon } from 'public/assets/icons';
import Dropdown from 'components/Dropdown';
import Image from 'components/common/Image';
import { EmptyUserProfilePhoto, LoadingComponent } from 'components';
import SidebarMyGroups from 'containers/SidebarMyGroups';
import fetchApi from 'hooks/fetchApi';
import { useSelector, useDispatch } from 'react-redux';
import API_NAME from 'services/api';
import { ThemeContext } from 'theme';
import imageResize from 'utils/imageResize';
import { AsyncStatus } from 'utils/types';
import {
  IconWrapper,
  ProfileButton,
  TextWrapper,
  FullName,
  UserName,
} from './CreateEventPostForm.styles';
import PostCreatorWrapper from './PostCreatorWrapper';
import { getSideGroupsStart } from 'store/user/actions';
const DEFAULT_PAGE_SIZE = 10;

const PostCreatorSelect = ({
  onClubSelect,
  initialValues,
  isDuplicating,
}: any) => {
  const context: any = useContext(ThemeContext);
  const dispatch = useDispatch();
  const {
    _id,
    username,
    fullName: name,
    profilePhoto,
  } = context?.user || {};
  const userOption = { _id, username, name, profilePhoto, isUser: true };
  const [selectedClub, setSelectedClub] = useState(userOption);
  const initDone = useRef(false);
  
  const {
    sideGroups: listGroups,
    totalGroups,
    getSideGroupsReqStat,
  } = useSelector((state) => state.user);

  const [groupOffset, setGroupOffset] = useState(0);
   const [filteredGroups, setFilteredGroups] = useState([]);

  useEffect(() => {
    const adminGroups = (listGroups || [])?.filter(
      (group: any) => group?.isAdmin
    );
    setFilteredGroups(adminGroups);
  }, [listGroups]);


  useEffect(() => {
    if (!initialValues?.group || initDone.current) {
      return;
    }
    const selectedClub = filteredGroups?.find(
      (el) => el?._id === initialValues?.group
    );
    if (selectedClub) {
      initDone.current = !!selectedClub;
      setSelectedClub(selectedClub);
    }
  }, [initialValues?.group, filteredGroups]);

  const loadMoreGroups = useCallback(() => {
    if (
      getSideGroupsReqStat !== AsyncStatus.LOADING &&
      listGroups?.length < totalGroups
    ) {
      setGroupOffset((prevOffset) => prevOffset + DEFAULT_PAGE_SIZE);
    }
  }, [getSideGroupsReqStat, listGroups, totalGroups]);

  useEffect(() => {
    if (groupOffset > 0 && listGroups?.length <= totalGroups) {
      dispatch(
        getSideGroupsStart({ offset: groupOffset, limit: DEFAULT_PAGE_SIZE })
      );
    }
  }, [groupOffset, dispatch, totalGroups]);

  


  const userName = selectedClub?.username;
  const fullName = selectedClub?.name || context?.user?.fullName;
  const profileImage = selectedClub?.profilePhoto?.trim();
  const profilePhotoThumb = imageResize(profileImage, '_thumb');
  const getData = useSelector((store) => store.fetchData);
  const userImage = profilePhotoThumb || profileImage;

  const isLoading = getSideGroupsReqStat === AsyncStatus.LOADING;
  const canShowGroups = filteredGroups?.length;
  const showPaginatedLoader = canShowGroups && isLoading;

  const handleSelectClub = (club) => {
    setSelectedClub(club);
    onClubSelect(club?._id ? club?._id : '', club?.isUser);
  };
 

  const listItems = [
    {
      customContent: (
        <PostCreatorWrapper
          isLoading={showPaginatedLoader}
          listItems={[userOption].concat(filteredGroups)}
          onSelect={handleSelectClub}
          selected={selectedClub?._id}
          onLoadMore={loadMoreGroups}
          offset={groupOffset}
          shouldPaginate
        />
      ),
    },
  ];

  return (
    <Dropdown
      listItems={listItems}
      variant="right"
      userImage={userImage}
      userName={userName}
      disabled={!isDuplicating && !!initialValues?.creator}
    >
      <ProfileButton>
        {userImage ? (
          <Image
            src={userImage}
            alt="user image"
            width={28}
            height={28}
            unoptimized
            priority
          />
        ) : (
          <EmptyUserProfilePhoto
            wrapSize={28}
            userIconSize={14}
            withBorder={false}
            cursor="pointer"
          />
        )}
        <TextWrapper>
          <UserName>{userName}</UserName>
          <FullName>{fullName}</FullName>
        </TextWrapper>
        <IconWrapper>
          <CircleWithArrowIcon />
        </IconWrapper>
      </ProfileButton>
    </Dropdown>
  );
};

export default PostCreatorSelect;
