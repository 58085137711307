import { useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchApiReq } from 'store/fetchApi/actions';
import { parseJsonIfNeeded } from 'utils/helpers';
import useDataRef from './useDataRef';

const useProcessResponse = (key: string, operation: 'ADD' | 'DELETE') => {
  const followingData = useSelector((store) => store.fetchData);
  const prevData = useDataRef(String(key), followingData);
  const dispatch = useDispatch();
  const processPostSuccess = useCallback(async (_res: any) => {

    const res = await parseJsonIfNeeded(_res);
    const resData = {
      ...res.data,
      creator: {
        data: { ...res?.data?.creator },
        isGroup: res?.data?.creator?.isGroup,
      },
    };
    dispatch(
      fetchApiReq({
        key,
        res: {
          ...prevData.current,
          data: [resData, ...prevData.current?.data],
          error: false,
          success: true,
          loading: false,
        },
      })
    );
  }, []);
  const processDeleteSuccess = useCallback(async (postId: string) => {
    dispatch(
      fetchApiReq({
        key,
        res: {
          ...prevData.current,
          data: [...prevData.current?.data]?.filter((el) => el?._id !== postId),
          error: false,
          success: true,
          loading: false,
        },
      })
    );
  }, []);

  const processResponse = useCallback(
    (_res: any) => {
      switch (operation) {
        case 'ADD':
          return processPostSuccess(_res);
        case 'DELETE':
          return processDeleteSuccess(_res);
        default:
          return null;
      }
    },
    [operation, key]
  );
  return processResponse;
};
export default useProcessResponse;
