import { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useField } from 'react-final-form';

import { composeValidators, required } from 'utils/validators';
import FieldElect from 'components/common/FieldElect';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';
import imageResize from 'utils/imageResize';
import { EventsIcon, SearchIcon, WheelIcon } from 'public/assets/icons';
import SidebarCheckItem from 'components/SidebarCheckItem';
import Image from 'components/common/Image';
import { EmptyUserProfilePhoto } from 'components';
import { ThemeContext } from 'theme';

import {
  ItemForSelectText,
  ItemForSelectValue,
  ItemForSelectWrapper,
  RenderPills,
  NoResultWrap,
  NoResultText,
  FormSearch,
  FormSearchWrapper,
  NoSelectedItemText,
  SelectedItemsWrap,
  SelectedItem,
  ItemImageWrap,
  ItemName,
  NoSelectedItemWrap,
} from './CreateDiscussionPostForm.styles';

const search = (data: any[], searchValue: string) => {
  const searchValueLowerCase = searchValue?.toLocaleLowerCase();

  return data?.filter((elem) =>
    elem?.name?.toLowerCase()?.includes(searchValueLowerCase)
  );
};

const Events = () => {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const searchValue = useField('search').input.value;
  const context: any = useContext(ThemeContext);

  const getUserEvents = useSelector((store) => store.fetchData);

  const { action, loading } = fetchApi();

  const fetchData = useCallback(() => {
    action(API_NAME.PROFILE_GET_USER_EVENTS, {
      params: {
        id: context?.userId,
      },
    });
  }, []);

  useEffect(() => {
    if (!(getUserEvents[API_NAME.PROFILE_GET_USER_EVENTS]?.data?.length > 0)) {
      fetchData();
    }
  }, []);

  return (
    <FieldElect
      name="events"
      label={t('search:eventsTab')}
      headerText="Tag events"
      barIcon={<EventsIcon />}
      render={(b, onRemove) => {
        return (
          b?.length > 0 &&
          b?.map((k) => {
            return (
              <RenderPills
                key={k?.id}
                onCrossClick={() => onRemove(k)}
                bgColor={theme.colors.neutral.n0}
                type="addTags"
                isCross
                isImage={k?.coverPhoto}
                variant="profile"
                image={k?.coverPhoto}
              >
                {k?.name}
              </RenderPills>
            );
          })
        );
      }}
      formRender={(value, onChange) => {
        const isSelectedItem = (id) => value?.map((v) => v?.id)?.includes(id);

        return (
          <>
            <FormSearchWrapper>
              <FormSearch
                name="search"
                prefix={<SearchIcon colorStroke={theme.colors.neutral.n6} />}
              />
            </FormSearchWrapper>
            {value?.length > 0 ? (
              <SelectedItemsWrap>
                {value &&
                  value.map((s) => {
                    return (
                      <SelectedItem>
                        <ItemImageWrap>
                          {s?.coverPhoto?.trim() ? (
                            <Image
                              loader={() => s?.coverPhoto}
                              src={
                                imageResize(s?.coverPhoto, '_thumb') ||
                                s?.coverPhoto?.trim()
                              }
                              alt="profile photo"
                              height={40}
                              width={40}
                              objectFit="cover"
                              unoptimized
                              priority
                            />
                          ) : (
                            <EmptyUserProfilePhoto
                              wrapSize={40}
                              userIconSize={20}
                            />
                          )}
                        </ItemImageWrap>
                        <ItemName>{s?.name}</ItemName>
                      </SelectedItem>
                    );
                  })}
              </SelectedItemsWrap>
            ) : (
              <NoSelectedItemWrap>
                <NoSelectedItemText italic>
                  {t('common:thereAreNoSelectedEvents')}
                </NoSelectedItemText>
              </NoSelectedItemWrap>
            )}

            <ItemForSelectWrapper>
              <ItemForSelectText>{t('search:searchResults')}</ItemForSelectText>
              <ItemForSelectValue>
                {getUserEvents[API_NAME.PROFILE_GET_USER_EVENTS]?.data?.length >
                0 ? (
                  search(
                    getUserEvents[API_NAME.PROFILE_GET_USER_EVENTS]?.data,
                    searchValue
                  )?.map((s, i) => {
                    return (
                      <SidebarCheckItem
                        key={s?._id}
                        image={s?.coverPhoto}
                        name={s?.name}
                        isSelected={isSelectedItem(s?._id)}
                        onSidebarCheckItemClick={() =>
                          onChange({ ...s, id: s?._id })
                        }
                      />
                    );
                  })
                ) : (
                  <NoResultWrap>
                    <NoResultText>{t('chat:nothingFound')}</NoResultText>
                    <WheelIcon />
                  </NoResultWrap>
                )}
              </ItemForSelectValue>
            </ItemForSelectWrapper>
          </>
        );
      }}
      // validate={composeValidators(required(t('error:thisFieldIsRequired')))}
    />
  );
};

export default Events;
