import styled from 'styled-components';
import { style } from 'utils/constants/style';

export const AddImportRouteWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; 
  cursor: pointer;
  margin-top: 16px;
  padding: 12px; 
  border: 2px solid ${style.mainOrange.darkOrange}; 
  border-radius: 8px; 
  background-color: #f8f8f8;

  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: ${style.mainOrange.darkOrange};
    letter-spacing: 0.02em;
    margin-left: 6px;
    text-transform: uppercase;
  }
`;

export const GpxFileWrap = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  padding: 12px;
  border-bottom: 2px solid ${style.mainOrange.darkOrange};
  background-color: #f8f8f8;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    margin-left: 6px;
    text-transform: uppercase;
  
`;
