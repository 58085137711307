import fetchData from 'utils/fetchData';
import { parseJsonIfNeeded } from 'utils/helpers';
import { endpoints, methods, variant } from './endpointType';
import api from './endpoints';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const generateApi = (name, provide, isResponse = true) => {
  const token =
    typeof window !== 'undefined' ? localStorage.getItem('token') : '';
  const apiObj: any = api[name](provide);
  const apiName = `${
    apiObj?.variant ? endpoints[apiObj?.variant] : endpoints[apiObj.version]
  }api/${apiObj.version}${apiObj.api}`;
  const isAuthentication = apiObj.authentication;

  try {
    if (apiObj.method === methods.POST) {
      if (isResponse) {
        return fetchData(apiName, {
          method: 'POST',
          headers: {
            ...headers,
            ...(isAuthentication && {
              tsec: JSON.parse(token),
            }),
          },
          ...(apiObj?.body && {
            body: apiObj.body,
          }),
        }).then((response) => response?.json());
      }
      return fetchData(apiName, {
        method: 'POST',
        headers: {
          ...headers,
          ...(isAuthentication && {
            tsec: JSON.parse(token),
          }),
        },
        ...(apiObj?.body && {
          body: apiObj.body,
        }),
      });
    }
    if (apiObj.method === methods.PUT) {
      return fetchData(apiName, {
        method: 'PUT',
        headers: {
          ...headers,
          ...(isAuthentication && {
            tsec: JSON.parse(token),
          }),
        },
        ...(apiObj?.body && {
          body: apiObj.body,
        }),
      }).then((response) => parseJsonIfNeeded(response));
    }
    if (apiObj.method === methods.PATCH) {
      return fetchData(apiName, {
        method: 'PATCH',
        headers: {
          ...headers,
          ...(isAuthentication && {
            tsec: JSON.parse(token),
          }),
        },
        ...(apiObj?.body && {
          body: apiObj.body,
        }),
      }).then((response) => response.json());
    }
    if (apiObj.method === methods.DELETE) {
      return fetchData(apiName, {
        method: 'DELETE',
        headers: {
          ...headers,
          ...(isAuthentication && {
            tsec: JSON.parse(token),
          }),
        },
        ...(apiObj?.body && {
          body: apiObj.body,
        }),
      }).then((response) => parseJsonIfNeeded(response));
    }
    return fetchData(apiName, {
      method: 'GET',
      headers: {
        ...headers,
        ...(isAuthentication && {
          tsec: JSON.parse(token),
        }),
      },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export default generateApi;
