import styled from 'styled-components';

import { deviceMaxWidth, style } from 'utils/constants/style';


export const AddButtonContainer = styled.div`
  width: 100%;
  box-sizing: border-box;

  @media only screen and (max-width: 550px) {
    padding: 0 10px;
  }
`;

export const AddButtonWrap = styled.div`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${style.mainBlue.aliceBlueColor};
  border: 1px solid ${style.mainBlue.blue};
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 16px;
  margin-top: 24px;

  @media only screen and (${deviceMaxWidth.sm}) {
    margin-top: 20px;
  }
`;

export const AddButtonText = styled.p`
  font-size: 15px;
  line-height: 130%;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${style.mainBlue.blue};
  margin-left: 8px;
`;