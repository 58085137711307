import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setMapFilter } from 'store/map/actions';
import { MAP_FILTER_DATA } from 'utils/constants';
import { onlyUnique } from 'utils/helpers';

const useMapFilters = (mapviewRef: any, isFinishedRenderingMap: boolean) => {
  const activeAccountId = useSelector((state) => state?.club?.activeAccountId);
  const mapFilter = useSelector(({ map }) => map?.mapFilter);
  const dispatch = useDispatch();
  const setMapFilterInRedux = useCallback((mapFilterKeys) => {
    dispatch(setMapFilter(mapFilterKeys));
  }, []);

  const filterItemsList = useMemo(() => {
    const labels = MAP_FILTER_DATA.map((el) => el?.label)?.filter(onlyUnique);
    return labels?.map((label) =>
      MAP_FILTER_DATA.find((el) => el?.label === label)
    );
  }, []);

  useEffect(() => {
    if (mapFilter === undefined || mapFilter === null) {
      setMapFilterInRedux(filterItemsList?.map((el) => el?.label));
    }
  }, [mapFilter, setMapFilterInRedux, filterItemsList]);

  const setLayerStatus = useCallback(
    async (sourceId, layerName, status) => {
      const map = mapviewRef.current.getMap();
      map.setLayoutProperty(
        layerName,
        'visibility',
        !status ? 'none' : 'visible'
      );
    },
    [mapviewRef]
  );

  useEffect(() => {
    if (!isFinishedRenderingMap) {
      return;
    }
    MAP_FILTER_DATA.forEach(async (el) => {
      if (
        el?.toggleFullLayer ||
        el?.filterMatch === undefined ||
        !el?.filterKey
      ) {
        setLayerStatus('composite', el?.layer, mapFilter?.includes(el?.label));
      }
    });
  }, [mapFilter, setLayerStatus, isFinishedRenderingMap]);

  useEffect(() => {
    if (!isFinishedRenderingMap) {
      return;
    }
    const multiLayerData = MAP_FILTER_DATA.filter(
      (el) =>
        !el?.toggleFullLayer &&
        ((el?.filterMatch !== undefined && el?.filterKey) ||
          el?.defaultFilter ||
          (activeAccountId && el?.filterForClub))
    );
    const filterObj = {};
    multiLayerData.forEach((el) => {
      filterObj[el?.layer] = [];
    });
    const layerNames = Object.keys(filterObj);

    multiLayerData.forEach((el) => {
      if (mapFilter?.includes(el?.label)) {
        filterObj[el?.layer] = (filterObj[el?.layer] || []).concat(
          el?.filterMatch
        );
      }
    });
    layerNames.forEach((layerName) => {
      setLayerStatus('composite', layerName, true);
      const multiLayerDataVal = multiLayerData?.find(
        (l) => l?.layer === layerName
      );
      if (multiLayerDataVal) {
        const hasAppliedFilter =
          !!multiLayerDataVal?.filterKey && filterObj[layerName]?.length;
        const filterEntry = hasAppliedFilter
          ? [
              'in',
              ['get', multiLayerDataVal?.filterKey],
              ['literal', filterObj[layerName]],
            ]
          : multiLayerDataVal?.filterKey // temporary workaround
          ? [
              'in',
              ['get', multiLayerDataVal?.filterKey],
              ['literal', ['notfound']],
            ]
          : [];
        let appliedMapboxFilter = filterEntry?.length
          ? ['any', filterEntry, ['!', ['has', multiLayerDataVal?.filterKey]]]
          : filterEntry;

        if (multiLayerDataVal?.filterForClub) {
          let activeAccountFilter;
          const clubDataFilter = ['==', ['get', 'clubs'], activeAccountId];
          const nonClubDataFilter = [
            'any',
            ['!', ['has', 'clubs']],
            ['==', ['get', 'clubs'], null],
          ];
          const clubAndNonClubData = ['any', clubDataFilter, nonClubDataFilter];
          if (activeAccountId) {
            activeAccountFilter = multiLayerDataVal.showNonClubData
              ? clubAndNonClubData
              : clubDataFilter;
          } else {
            activeAccountFilter = nonClubDataFilter;
          }

          appliedMapboxFilter = appliedMapboxFilter?.length
            ? ['all', activeAccountFilter, appliedMapboxFilter]
            : activeAccountFilter;
        }

        let mapboxFilter = appliedMapboxFilter;

        if (multiLayerDataVal.defaultFilter) {
          const defaultFilterEntry = [
            'any',
            multiLayerDataVal.defaultFilter,
            ['!', ['has', multiLayerDataVal?.defaultFilterKey]],
          ];
          mapboxFilter = appliedMapboxFilter?.length
            ? ['all', appliedMapboxFilter, defaultFilterEntry]
            : defaultFilterEntry;
        }

        const map = mapviewRef.current.getMap();
        map.setFilter(layerName, mapboxFilter);
      }
    });
  }, [setLayerStatus, mapFilter, activeAccountId, isFinishedRenderingMap]);

  return null;
};

export default useMapFilters;

/**
 * This React hook (`useMapFilters`) is designed for integrating and managing
 * dynamic filters in a Mapbox map within a React Native application.
 * Mapbox is a library used for embedding interactive maps within web and mobile applications.
 * In Mapbox, layers represent the visual representation of data. Filters are used to
 * selectively display features within a layer based on certain conditions.
 *
 * Overview of Key Concepts:
 * - `MAP_FILTER_DATA`: An array of objects, each representing a filterable feature on the map
 *   (e.g., a type of pin or marker). Each object includes properties like label, layer ID,
 *   and filter conditions.
 * - `mapFilter`: A state variable representing the currently applied filters (e.g., which types
 *   of pins should be visible).
 * - `useMemo` and `useCallback`: React hooks used for performance optimization. `useMemo` memorizes
 *   expensive calculations, and `useCallback` memorizes functions.
 *
 * Main Functionalities:
 * 1. Initialize Default Filters:
 *    Sets the default map filters based on `filterItemsList`, derived from `MAP_FILTER_DATA`.
 *    This happens when `mapFilter` is undefined or null.
 *
 * 2. Update Layer Visibility:
 *    Manages the visibility of map layers based on the current filters. This is achieved
 *    through the `setLayerStatus` function, which alters the visibility of a layer based
 *    on the `mapFilter` state.
 *
 * 3. Complex Filter Management (`multiLayerArrayData`):
 *    This section is the core of the hook where complex filter logic is implemented:
 *    - Filters from `MAP_FILTER_DATA` are refined and applied to map layers.
 *    - There are two types of filters: Dynamic Filters and Default Filters.
 *      - Dynamic Filters (`filterEntry`) are based on user interaction (e.g., toggling a pin type).
 *      - Default Filters (`defaultFilterEntry`) are preset filters that apply to certain layers
 *        (e.g., showing only pins with a distance > 5).
 *    - The hook combines these filters using Mapbox filter syntax:
 *      - `['all', ...]`: Represents an AND condition, where all nested conditions must be true.
 *      - `['any', ...]`: Represents an OR condition, where any of the nested conditions can be true.
 *      - These conditions are used to construct a final filter expression that is applied
 *        to each map layer.
 *
 *    The result (`multiLayerArrayData`) is an array of objects, each containing the layer ID
 *    and the corresponding filter expression. This array is then used to render the map layers
 *    with the applied filters.
 *
 * This hook is meant to be used in a component where a Mapbox map is rendered, facilitating
 * dynamic and complex filtering of map features based on user interaction and predefined conditions.
 */
