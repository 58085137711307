/* eslint-disable prefer-destructuring */
import { useState, useRef, useCallback } from 'react';
import Image from 'components/common/Image';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { EmptyGroupIcon } from 'public/assets/icons';
import { useOutsideClick } from 'utils/hooks';
import { routesPath } from 'utils/constants/routePath';
import { DEFAULT_LINK_POST_PLACEHOLDER_IMAGE } from 'utils/constants';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import { ICustomList } from './ICustomList';
import {
  CustomListWrapper,
  Wrapper,
  ListWrap,
  ItemDescriptions,
  ItemWrap,
  ItemContainer,
  PopOVerWrap,
  Title,
  EmptyUserPhoto,
  PopularPostsListWrapper,
  PopularPostsList,
  TitleWrap,
  PlaceHolderWrapper,
} from './CustomList.styled';
import { ConfiguringPopoverComponent } from '../Popover/ConfiguringPopoverComponent';
import { ListUserUserActions } from '../Popover/Popover.style';
import imageResize from 'utils/imageResize';

export const CustomList = ({
  list,
  title,
  icon,
  type,
  shouldPaginate,
  onLoadMore,
  offset,
  isLoading,
}: ICustomList) => {
  const [popoverShow, setPopoverShow] = useState(false);
  const enablePagination = shouldPaginate && !!onLoadMore;

  const router = useRouter();
  const { t } = useTranslation();

  const observer = useRef(null);

  const lastItem = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          onLoadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [onLoadMore]
  );

  const refActionPopover = useRef(null);
  useOutsideClick(refActionPopover, () => {
    setPopoverShow(false);
  });

  const onItemClick = (item) => {
    if (type === 'my_groups') {
      router.push(`${routesPath.group_details}/${item._id}`);
    } else {
      router.push(`${routesPath.event_details}/${item._id}`);
    }
  };

  if (type === 'popular_posts') {
    const getRedirectUrl = (item) => {
      switch (item.type) {
        case 1:
        case 3:
        case 11:
          return `${routesPath.post_details}/${item._id}`;
        case 4:
          return `${routesPath.vehicle_details}/${item.originId}`;
        case 6:
        case 16:
          return `${routesPath.event_details}/${item.originId}`;
        case 7:
          return `${routesPath.group_details}/${item.originId}`;

        default:
          return '';
      }
    };

    const getImageSrc = (item, variant = null) => {
      let src;

      switch (item.type) {
        case 1:
        case 4:
        case 11:
          src = item?.media?.length ? item.media[0] : null;
          break;

        case 3:
          src =
            item.linkImage === DEFAULT_LINK_POST_PLACEHOLDER_IMAGE
              ? ''
              : item.linkImage;
          break;

        case 6:
        case 16:
          src = item.event.coverPhoto;
          break;

        default:
          src = item.coverPhoto;
      }

      return !src?.length
        ? '/assets/img/tireTrace.png'
        : !variant
        ? src
        : imageResize(src, variant);
    };

    return (
      <Wrapper>
        <div style={{ display: 'flex' }}>
          <div>{icon}</div>
          <Title>{title}</Title>
        </div>

        <PopularPostsListWrapper>
          {list?.length > 0 &&
            list.map((el, i) => (
              <div key={`custom-list${i}`}>
                <PopularPostsList
                  onClick={() => router.push(getRedirectUrl(el))}
                >
                  <Image
                    loader={() => getImageSrc(el)}
                    fallbackSrc={getImageSrc(el)}
                    src={getImageSrc(el, '_search')}
                    alt="post"
                    width={130}
                    height={157}
                    objectFit="cover"
                    unoptimized
                    priority
                  />
                </PopularPostsList>
              </div>
            ))}
        </PopularPostsListWrapper>
      </Wrapper>
    );
  }

  return (
    <>
      {popoverShow && (
        <PopOVerWrap>
          <ConfiguringPopoverComponent>
            <ListUserUserActions>
              <li>{t('search:searchText')}</li>
              <li>{t('feed:create')}</li>
            </ListUserUserActions>
          </ConfiguringPopoverComponent>
        </PopOVerWrap>
      )}
      <Wrapper>
        <TitleWrap>
          <div>{icon}</div>
          <Title>{title}</Title>
        </TitleWrap>
        <CustomListWrapper>
          <PlaceHolderWrapper>
            {list?.length === 0 && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Image
                  width={72}
                  height={72}
                  alt="empty placeholder"
                  src={
                    type === 'my_groups'
                      ? '/assets/img/empty-groups.svg'
                      : '/assets/img/empty-events.svg'
                  }
                  unoptimized
                  priority
                />
                <div>
                  {type === 'my_groups'
                    ? t('myGroups:placeholder')
                    : t('myEvents:placeholder')}
                </div>
              </div>
            )}
          </PlaceHolderWrapper>

          <ListWrap>
            {list?.length > 0 &&
              list.map((el, i) => (
                <ItemContainer
                  key={`custom-list${i}`}
                  onClick={() => onItemClick(el)}
                  ref={
                    enablePagination
                      ? i === list.length - 1
                        ? lastItem
                        : undefined
                      : null
                  }
                >
                  <ItemWrap>
                    {el.coverPhoto && el.coverPhoto !== ' ' ? (
                      <span>
                        <Image
                          width={40}
                          height={40}
                          alt={type === 'my_groups' ? 'group' : 'event'}
                          src={imageResize(el?.coverPhoto, '_thumb')}
                          fallbackSrc={el.coverPhoto}
                          unoptimized
                          priority
                        />
                      </span>
                    ) : (
                      <span>
                        <EmptyUserPhoto>
                          <EmptyGroupIcon />
                        </EmptyUserPhoto>
                      </span>
                    )}

                    <ItemDescriptions>
                      <div>{el.name}</div>
                      {type === 'my_groups' ? (
                        <span>{el.username}</span>
                      ) : (
                        <span>
                          {moment(el?.startDate).format('DD MMM hh:mm A')}
                        </span>
                      )}
                    </ItemDescriptions>
                  </ItemWrap>
                  {isLoading && i === list?.length - 1 && (
                    <LoadingComponent
                      fullWidth
                      color="#C2C2C2"
                      variant="spin"
                      wrapHeight={50}
                      loaderSize={15}
                    />
                  )}
                </ItemContainer>
              ))}
          </ListWrap>
        </CustomListWrapper>
      </Wrapper>
    </>
  );
};
