/* eslint-disable import/no-cycle */
import { all, call, put, takeLatest } from 'redux-saga/effects';
import Router from 'next/router';
import cookieCutter from 'cookie-cutter';
import { getReferrerData } from 'utils/referrerdata';
import { postAuthorization } from 'services/index';
import { routesPath } from 'utils/constants/routePath';
import { actionTypes, SignUpSuccess, signUpError } from './actions';

function* workerSignUp(action) {
  try {
    const referrerData = getReferrerData();
    const resp = yield call(
      postAuthorization,
      action.data.email,
      action.data.password,
      referrerData
    );

    console.log('registartion', resp);

    if (resp.data.ok) {
      yield cookieCutter.set('tsec', resp.data.token, { path: '/' });
      yield localStorage.setItem('token', JSON.stringify(resp.data.token));
      yield localStorage.setItem('user_id', JSON.stringify(resp.data._id));

      yield put(SignUpSuccess(resp.data));
      // yield Router.push(`${routesPath.feedPath}`);
    } else {
      yield put(signUpError(resp.data.code));
    }
  } catch (err) {
    yield put(signUpError(err));
  }
}

function* watchSignUp() {
  yield all([takeLatest(actionTypes.SIGN_UP_START, workerSignUp)]);
}

export default watchSignUp;
