/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { InputDescription, InputDescriptionField } from 'components';
import CreatePostAddTags from 'containers/CreatePostAddTags';
import SidebarSelectTags from 'containers/SidebarSelectTags';
import CreatePostQuickTags from 'containers/CreatePostQuickTags';
import SchedulePost from 'containers/SchedulePost';
import Input from 'components/common/Input';
import { LinkIcon } from 'public/assets/icons';
import ChooseLocationDrawer from 'containers/ChooseLocationDrawer';
import FieldLocation from 'components/common/FieldLocation';
import { closeSideBarCreateLinkPost } from 'store/sideBarControler/actions';
import { composeValidators, required } from 'utils/validators';
import {
  LinkInfoContentWrap,
  LinkInfoDescription,
  LinkInfoSite,
  LinkInfoWrap,
} from 'components/SideBarContent/CreateLinkPost/CreateLinkPost.style';
import Users from './Users';
import Groups from './Groups';
import Events from './Events';
import { ICreateLinkPostForm } from './ICreateLinkPostForm';

import {
  DesktopLoginHeaderRight,
  FinalFormWrapper,
  FormWrapper,
  SectionHeader,
  SectionWrapper,
  SeparateSettingsHeader,
} from './CreateLinkPostForm.styles';
import PostCreatorSelect from 'forms/CreateEventPostForm/PostCreatorSelect';

const CreateLinkPostForm = (props: ICreateLinkPostForm) => {
  const { t } = useTranslation();

  const [quickTags, setQuickTags] = useState<Array<any>>([]);
  const [scheduledPostData, setScheduledPostData] = useState<{
    date: any;
    error: string;
  }>({
    date: new Date(),
    error: '',
  });

  return (
    <>
      <FinalFormWrapper
        {...props}
        render={(formRenderProps: FormRenderProps) => {
          const { handleSubmit, values, form, initialValues } = formRenderProps;

          const {
            inProgress,
            ready,
            interests,
            privateFeatures,
            onIconClick,
            variant = 'create',
          } = props;

          const submitAbility =
            values?.link &&
            values?.description?.trim() &&
            (values?.groups?.length ||
              values?.users?.length ||
              values?.events?.length ||
              values?.location !== null) &&
            values?.quickTags?.length;

             const handleClubSelect = (clubId: string, isUser: boolean) => {
               if (!isUser) {
                 form.change('origin', 'group');
                 form.change('originId', clubId);
               } else {
                 form.change('postCreator', clubId);
               }
             };
          return (
            <FormWrapper
              onSubmit={handleSubmit}
              onIconClick={onIconClick}
              isHeaderBorder
              headerText={
                variant === 'create' ? t('link:createLink') : t('link:editLink')
              }
              submitText={
                variant === 'create'
                  ? t('feed:create')
                  : t('editUserProfile:save')
              }
            >
              <SeparateSettingsHeader mt={10} mb={15}>
                {t('event:postingAs')}
              </SeparateSettingsHeader>
              <SeparateSettingsHeader mb={10} mt={10}>
                <DesktopLoginHeaderRight>
                  <PostCreatorSelect
                    onClubSelect={handleClubSelect}
                    initialValues={initialValues}
                  />
                </DesktopLoginHeaderRight>
              </SeparateSettingsHeader>
              
              <SectionWrapper>
                <SectionHeader type="h3">
                  {t('event:mainInformation')}
                </SectionHeader>
                <Input
                  id="link"
                  name="link"
                  type="text"
                  label={t('common:addADescription')}
                  placeholder={t('link:typeOrPasteLink')}
                  prefix={<LinkIcon />}
                  validate={composeValidators(
                    required(t('error:thisFieldIsRequired'))
                  )}
                />

                {/* <LinkInfoWrap backgroundImage={linkInfo.ogImage.url}>
                  <LinkInfoContentWrap>
                    <LinkInfoDescription>
                      {linkInfo.ogTitle}
                    </LinkInfoDescription>
                    <LinkInfoSite>
                      {`${linkInfo.host.domain}.${linkInfo.host.tld}`}
                    </LinkInfoSite>
                  </LinkInfoContentWrap>
                </LinkInfoWrap> */}
                <InputDescriptionField
                  placeholder={t('common:addADescription')}
                  label={t('common:addADescription')}
                  name="description"
                  showCharactersNumber
                  maxLength={500}
                  height={88}
                  validate={composeValidators(
                    required(t('error:thisFieldIsRequired'))
                  )}
                />
              </SectionWrapper>
              <SectionWrapper pt={20}>
                <SectionHeader type="h3">{t('common:addTags')}</SectionHeader>
                <Groups />
                <Users />
                <Events />
                <FieldLocation
                  name="location"
                  label={t('addNewGroup:cityOrLocation')}
                  headerText={t('map:tagLocation')}
                  barIcon
                />
              </SectionWrapper>
              {/* <Field name="addTags">
                {({ input }) => {
                  form.change('addTags', data);
                  return (
                    <CreatePostAddTags
                      tagsData={data}
                      setOpen={setOpenTagDrawer}
                      setOpenChooseLocationDrawer={setOpenChooseLocationDrawer}
                      setData={setData}
                      setTagType={setTagType}
                      {...input}
                    />
                  );
                }}
              </Field> */}
              <Field name="quickTags">
                {({ input }) => {
                  form.change('quickTags', quickTags);
                  return (
                    <CreatePostQuickTags
                      interests={interests}
                      quickTags={quickTags}
                      setQuickTags={setQuickTags}
                      {...input}
                    />
                  );
                }}
              </Field>
              {privateFeatures && (
                <Field name="schedulePost">
                  {({ input }) => {
                    form.change('schedulePost', scheduledPostData);
                    return (
                      <SchedulePost
                        scheduledPostData={scheduledPostData}
                        setScheduledPostData={setScheduledPostData}
                        {...input}
                      />
                    );
                  }}
                </Field>
              )}
            </FormWrapper>
          );
        }}
      />
      {/* <SidebarSelectTags
        isOpenSideBarTag={openTagDrawer}
        onSubmit={(e) => {
          setData({ ...data, [tagType]: e });
          setSearchedItems([]);
        }}
        setOpen={setOpenTagDrawer}
        type={tagType}
        data={data}
        setIsDrawerOpen={props.setIsDrawerOpen}
        searchedItems={searchedItems}
        setSearchedItems={setSearchedItems}
      />
      <ChooseLocationDrawer
        open={openChooseLocationDrawer}
        handleSubmit={(e) => setData({ ...data, location: e })}
        setOpenChooseLocationDrawer={setOpenChooseLocationDrawer}
      /> */}
    </>
  );
};

export default CreateLinkPostForm;
