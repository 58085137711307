/* eslint-disable no-case-declarations */
import { HYDRATE } from 'next-redux-wrapper';

import { actionTypes } from './actions';

const initialState = {
  activeAccountId: null,
  allClubs: [],
  loading: false,
  error: null,
  activeClub: {
    name: '',
    logoUrl: '',
    theme: {
      colors: {
        primary: '',
        secondary: '',
        tertiary: '',
      },
    },
  },
  allThemes: {},
  memberList: [],
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }
    case actionTypes.RESET_CLUBS_DATA:
      return {
        ...state,
        activeAccountId: null,
        activeClub: initialState.activeClub,
      };
    case actionTypes.RESET_STATE:
      return { ...initialState };
    case actionTypes.POST_JOIN_CLUBS_SUCCESS:
      return state;
    case actionTypes.SET_ACTIVE_ACCOUNT_ID:
      return {
        ...state,
        activeAccountId: action.payload.activeAccountId,
      };
    case actionTypes.POST_ACTIVE_CLUB_INFO_REQUEST:
      return state;
    case actionTypes.POST_ACTIVE_CLUB_INFO_SUCCESS:
      if (!action?.payload?._id) {
        return { ...state, activeClub: initialState.activeClub };
      }
      const { _id, logoUrl, theme } = action.payload;
      return {
        ...state,
        activeAccountId: _id,
        activeClub: action.payload,
        allClubs: [action.payload].concat(
          state?.allClubs?.filter((_club) => _club?._id !== _id) || []
        ),
        allThemes: {
          ...state.allThemes,
          [_id]: {
            logoUrl,
            colors: theme.colors,
          },
        },
      };
    case actionTypes.POST_ACTIVE_CLUB_INFO_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.SET_THEME:
      return {
        ...state,
        activeClub: {
          ...state.activeClub,
          logoUrl: action.payload.logoUrl || state.activeClub.logoUrl,
          theme: {
            colors: {
              ...state.activeClub.theme.colors,
              ...action.payload.colors,
            },
          },
        },
      };
    case actionTypes.POST_JOIN_CLUB_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.POST_JOIN_CLUB_SUCCESS:
      return {
        ...state,
        loading: false,
        activeClub: action.payload,
        activeAccountId: action.payload?._id,
      };
    case actionTypes.POST_JOIN_CLUB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_SINGLE_CLUB_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_SINGLE_CLUB_SUCCESS:
      const club = action.payload;
      const { _id: clubId, logoUrl: clubLogoUrl, theme: clubTheme } = club;
      return {
        ...state,
        loading: false,
        allClubs: [club].concat(
          state?.allClubs?.filter((_club) => _club?._id !== clubId) || []
        ),
        allThemes: {
          ...state.allThemes,
          [clubId]: {
            logoUrl: clubLogoUrl,
            colors: clubTheme.colors,
          },
        },
      };
    case actionTypes.GET_SINGLE_CLUB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_MEMBER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        memberList: action.payload || [],
      };
    case actionTypes.GET_MEMBER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_ALL_CLUBS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_ALL_CLUBS_SUCCESS:
      return {
        ...state,
        loading: false,
        allClubs: action.payload,
      };
    case actionTypes.GET_ALL_CLUBS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        memberList: state?.memberList?.filter(
          (user) => user?._id !== action.payload
        ),
      };
    case actionTypes.DELETE_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
