import React, { useState, useEffect } from 'react';
import blurImage from 'utils/blurImage';
import { IImage } from './IImage';
import { ImageWrapper } from './Image.styles';

const Image = (props: IImage) => {
  const { src, alt, layout, isLoader = false, fallbackSrc, ...rest } = props;
  const [imgSrc, setImgSrc] = useState(src || '/assets/img/tireTrace.png');

  useEffect(() => {
    setImgSrc(src || '/assets/img/tireTrace.png');
  }, [src]);

  const handleError = () => {
    if (fallbackSrc) {
      setImgSrc(fallbackSrc);
    }
  };

  if (isLoader) {
    return (
      <ImageWrapper
        src={imgSrc}
        alt={alt}
        layout={layout}
        placeholder="blur"
        loading="eager"
        blurDataURL={blurImage}
        {...rest}
        unoptimized
        loader={({ src, width, quality }) => src}
        priority
        onError={handleError}
      />
    );
  }

  return (
    <ImageWrapper
      src={imgSrc}
      alt={alt}
      layout={layout}
      placeholder="blur"
      loading="eager"
      blurDataURL={blurImage}
      onError={handleError}
      {...rest}
    />
  );
};

export default Image;
