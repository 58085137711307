export const actionTypes = {
  RESET_CLUBS_DATA: 'RESET_CLUBS_DATA',
  RESET_STATE: 'RESET_STATE',
  POST_JOIN_CLUBS_SUCCESS: 'POST_JOIN_CLUBS_SUCCESS',
  SET_ACTIVE_ACCOUNT_ID: 'SET_ACTIVE_ACCOUNT_ID',
  POST_ACTIVE_CLUB_INFO_REQUEST: 'POST_ACTIVE_CLUB_INFO_REQUEST',
  POST_ACTIVE_CLUB_INFO_SUCCESS: 'POST_ACTIVE_CLUB_INFO_SUCCESS',
  POST_ACTIVE_CLUB_INFO_FAILURE: 'POST_ACTIVE_CLUB_INFO_FAILURE',
  SET_THEME: 'SET_THEME',
  POST_JOIN_CLUB_REQUEST: 'POST_JOIN_CLUB_REQUEST',
  POST_JOIN_CLUB_SUCCESS: 'POST_JOIN_CLUB_SUCCESS',
  POST_JOIN_CLUB_FAILURE: 'POST_JOIN_CLUB_FAILURE',
  GET_SINGLE_CLUB_REQUEST: 'GET_SINGLE_CLUB_REQUEST',
  GET_SINGLE_CLUB_SUCCESS: 'GET_SINGLE_CLUB_SUCCESS',
  GET_SINGLE_CLUB_FAILURE: 'GET_SINGLE_CLUB_FAILURE',
  GET_MEMBER_LIST_REQUEST: 'GET_MEMBER_LIST_REQUEST',
  GET_MEMBER_LIST_SUCCESS: 'GET_MEMBER_LIST_SUCCESS',
  GET_MEMBER_LIST_FAILURE: 'GET_MEMBER_LIST_FAILURE',
  GET_ALL_CLUBS_REQUEST: 'GET_ALL_CLUBS_REQUEST',
  GET_ALL_CLUBS_SUCCESS: 'GET_ALL_CLUBS_SUCCESS',
  GET_ALL_CLUBS_FAILURE: 'GET_ALL_CLUBS_FAILURE',
  DELETE_MEMBER_REQUEST: 'DELETE_MEMBER_REQUEST',
  DELETE_MEMBER_SUCCESS: 'DELETE_MEMBER_SUCCESS',
  DELETE_MEMBER_FAILURE: 'DELETE_MEMBER_FAILURE',
};

// Action Creators
export const resetClubsData = () => ({
  type: actionTypes.RESET_CLUBS_DATA,
});

export const resetState = () => ({
  type: actionTypes.RESET_STATE,
});

export const postJoinClubsSuccess = () => ({
  type: actionTypes.POST_JOIN_CLUBS_SUCCESS,
});

export const setActiveAccountId = (activeAccountId) => ({
  type: actionTypes.SET_ACTIVE_ACCOUNT_ID,
  payload: {
    activeAccountId,
    activeClub: activeAccountId || null,
  },
});

export const postActiveClubInfoRequest = (clubId) => ({
  type: actionTypes.POST_ACTIVE_CLUB_INFO_REQUEST,
  payload: { clubId },
});

export const postActiveClubInfoSuccess = (activeClubInfo) => ({
  type: actionTypes.POST_ACTIVE_CLUB_INFO_SUCCESS,
  payload: activeClubInfo,
});

export const postActiveClubInfoFailure = (error) => ({
  type: actionTypes.POST_ACTIVE_CLUB_INFO_FAILURE,
  payload: error,
});

export const setTheme = (logourl, colors) => ({
  type: actionTypes.SET_THEME,
  payload: { logourl, colors },
});

export const postJoinClubRequest = (clubId, inviteToken, onSuccess) => ({
  type: actionTypes.POST_JOIN_CLUB_REQUEST,
  payload: { clubId, inviteToken, onSuccess },
});

export const postJoinClubSuccess = (club) => ({
  type: actionTypes.POST_JOIN_CLUB_SUCCESS,
  payload: club,
});

export const postJoinClubFailure = (error) => ({
  type: actionTypes.POST_JOIN_CLUB_FAILURE,
  payload: error,
});

export const getSingleClubRequest = (payload) => ({
  type: actionTypes.GET_SINGLE_CLUB_REQUEST,
  payload,
});

export const getSingleClubSuccess = (club) => ({
  type: actionTypes.GET_SINGLE_CLUB_SUCCESS,
  payload: club,
});

export const getSingleClubFailure = (error) => ({
  type: actionTypes.GET_SINGLE_CLUB_FAILURE,
  payload: error,
});

export const getMemberListRequest = () => ({
  type: actionTypes.GET_MEMBER_LIST_REQUEST,
});

export const getMemberListSuccess = (club) => ({
  type: actionTypes.GET_MEMBER_LIST_SUCCESS,
  payload: club,
});

export const getMemberListFailure = (error) => ({
  type: actionTypes.GET_MEMBER_LIST_FAILURE,
  payload: error,
});

export const getAllClubsRequest = () => ({
  type: actionTypes.GET_ALL_CLUBS_REQUEST,
});

export const getAllClubsSuccess = (clubs) => ({
  type: actionTypes.GET_ALL_CLUBS_SUCCESS,
  payload: clubs,
});

export const getAllClubsFailure = (error) => ({
  type: actionTypes.GET_ALL_CLUBS_FAILURE,
  payload: error,
});

export const deleteMemberRequest = () => ({
  type: actionTypes.DELETE_MEMBER_REQUEST,
});

export const deleteMemberSuccess = (userId) => ({
  type: actionTypes.DELETE_MEMBER_SUCCESS,
  payload: userId,
});

export const deleteMemberFailure = (error) => ({
  type: actionTypes.DELETE_MEMBER_FAILURE,
  payload: error,
});
