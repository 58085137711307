export const actionTypes = {
  CREATE_POST_START: 'CREATE_POST_START',
  CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
  CREATE_POST_ERROR: 'CREATE_POST_ERROR',
  CLEAR_CREATE_POST_REQ_STAT: 'CLEAR_CREATE_POST_REQ_STAT',
  CLEAR_POSTS_STORE: 'CLEAR_POSTS_STORE',
  PARSE_LINK_START: 'PARSE_LINK_START',
  PARSE_LINK_SUCCESS: 'PARSE_LINK_SUCCESS',
  PARSE_LINK_ERROR: 'PARSE_LINK_ERROR',
  CREATE_EVENT_START: 'CREATE_EVENT_START',
  CREATE_EVENT_SUCCESS: 'CREATE_EVENT_SUCCESS',
  CREATE_EVENT_ERROR: 'CREATE_EVENT_ERROR',
  CLEAR_CREATE_EVENT_REQ_STAT: 'CLEAR_CREATE_EVENT_REQ_STAT',
  GET_EVENT_DETAILS_START: 'GET_EVENT_DETAILS_START',
  GET_EVENT_DETAILS_SUCCESS: 'GET_EVENT_DETAILS_SUCCESS',
  GET_EVENT_DETAILS_ERROR: 'GET_EVENT_DETAILS_ERROR',
  GET_POST_DETAILS_START: 'GET_POST_DETAILS_START',
  GET_POST_DETAILS_SUCCESS: 'GET_POST_DETAILS_SUCCESS',
  GET_POST_DETAILS_ERROR: 'GET_POST_DETAILS_ERROR',
  CLEAR_POST_DETAILS_DATA: 'CLEAR_POST_DETAILS_DATA',
  REPORT_POST_START: 'REPORT_POST_START',
  REPORT_POST_SUCCESS: 'REPORT_POST_SUCCESS',
  REPORT_POST_ERROR: 'REPORT_POST_ERROR',
  REPORT_GROUP_START: 'REPORT_GROUP_START',
  REPORT_GROUP_SUCCESS: 'REPORT_GROUP_SUCCESS',
  REPORT_GROUP_ERROR: 'REPORT_GROUP_ERROR',
  REPORT_EVENT_START: 'REPORT_EVENT_START',
  REPORT_EVENT_SUCCESS: 'REPORT_EVENT_SUCCESS',
  REPORT_EVENT_ERROR: 'REPORT_EVENT_ERROR',
  EDIT_GROUP_START: 'EDIT_GROUP_START',
  EDIT_GROUP_SUCCESS: 'EDIT_GROUP_SUCCESS',
  EDIT_GROUP_ERROR: 'EDIT_GROUP_ERROR',
  CLEAR_EDIT_GROUP_STATUS: 'CLEAR_EDIT_GROUP_STATUS',
  GET_GROUP_DETAILS_START: 'GET_GROUP_DETAILS_START',
  GET_GROUP_DETAILS_SUCCESS: 'GET_GROUP_DETAILS_SUCCESS',
  GET_GROUP_DETAILS_ERROR: 'GET_GROUP_DETAILS_ERROR',
  EDIT_EVENT_START: 'EDIT_EVENT_START',
  EDIT_EVENT_SUCCESS: 'EDIT_EVENT_SUCCESS',
  EDIT_EVENT_ERROR: 'EDIT_EVENT_ERROR',
  CLEAR_EDIT_EVENT_REQ_STAT: 'CLEAR_EDIT_EVENT_REQ_STAT',
  CANCEL_EVENT_START: 'CANCEL_EVENT_START',
  CANCEL_EVENT_SUCCESS: 'CANCEL_EVENT_SUCCESS',
  CANCEL_EVENT_ERROR: 'CANCEL_EVENT_ERROR',
  DELETE_EVENT_START: 'DELETE_EVENT_START',
  DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
  DELETE_EVENT_ERROR: 'DELETE_EVENT_ERROR',
  EDIT_POST_START: 'EDIT_POST_START',
  EDIT_POST_SUCCESS: 'EDIT_POST_SUCCESS',
  EDIT_POST_ERROR: 'EDIT_POST_ERROR',
  CLEAR_EDIT_POST_STATUS: 'CLEAR_EDIT_POST_STATUS',
  DELETE_POST_START: 'DELETE_POST_START',
  DELETE_POST_SUCCESS: 'DELETE_POST_SUCCESS',
  DELETE_POST_ERROR: 'DELETE_POST_ERROR',
  GET_POPULAR_POSTS_START: 'GET_POPULAR_POSTS_START',
  GET_POPULAR_POSTS_SUCCESS: 'GET_POPULAR_POSTS_SUCCESS',
  GET_POPULAR_POSTS_ERROR: 'GET_POPULAR_POSTS_ERROR',
  REFRESH_EVENT_POSTS: 'REFRESH_EVENT_POSTS',
  REFRESH_GROUP_POSTS: 'REFRESH_GROUP_POSTS',
  REFRESH_FEED_POSTS: 'REFRESH_FEED_POSTS',
  CLEAR_REFRESHING_STATUS: 'CLEAR_REFRESHING_STATUS',
  SET_IS_SCHEDULED_POST_CREATED: 'SET_SCHEDULED_POST_STAT',
  ATTEND_EVENT_WITH_VEHICLE_START: 'ATTEND_EVENT_WITH_VEHICLE_START',
  ATTEND_EVENT_WITH_VEHICLE_SUCCESS: 'ATTEND_EVENT_WITH_VEHICLE_SUCCESS',
  ATTEND_EVENT_WITH_VEHICLE_ERROR: 'ATTEND_EVENT_WITH_VEHICLE_ERROR',
  ATTEND_EVENT_WITHOUT_VEHICLE_START: 'ATTEND_EVENT_WITHOUT_VEHICLE_START',
  ATTEND_EVENT_WITHOUT_VEHICLE_SUCCESS: 'ATTEND_EVENT_WITHOUT_VEHICLE_SUCCESS',
  ATTEND_EVENT_WITHOUT_VEHICLE_ERROR: 'ATTEND_EVENT_WITHOUT_VEHICLE_ERROR',
  CANCEL_EVENT_SUBSCRIPTION_START: 'CANCEL_EVENT_SUBSCRIPTION_START',
  CANCEL_EVENT_SUBSCRIPTION_SUCCESS: 'CANCEL_EVENT_SUBSCRIPTION_SUCCESS',
  CANCEL_EVENT_SUBSCRIPTION_ERROR: 'CANCEL_EVENT_SUBSCRIPTION_ERROR',
  GET_GROUP_FOLLOWERS_START: 'GET_GROUP_FOLLOWERS_START',
  GET_GROUP_FOLLOWERS_SUCCESS: 'GET_GROUP_FOLLOWERS_SUCCESS',
  GET_GROUP_FOLLOWERS_ERROR: 'GET_GROUP_FOLLOWERS_ERROR',
  GET_GROUP_MEMBERS_START: 'GET_GROUP_MEMBERS_START',
  GET_GROUP_MEMBERS_SUCCESS: 'GET_GROUP_MEMBERS_SUCCESS',
  GET_GROUP_MEMBERS_ERROR: 'GET_GROUP_MEMBERS_ERROR',
  GET_GROUP_ADMINS_V12_START: 'GET_GROUP_ADMINS_V12_START',
  GET_GROUP_ADMINS_V12_SUCCESS: 'GET_GROUP_ADMINS_V12_SUCCESS',
  GET_GROUP_ADMINS_V12_ERROR: 'GET_GROUP_ADMINS_V12_ERROR',
  DELETE_GROUP_FOLLOWER_START: 'DELETE_GROUP_FOLLOWER_START',
  DELETE_GROUP_FOLLOWER_SUCCESS: 'DELETE_GROUP_FOLLOWER_SUCCESS',
  DELETE_GROUP_FOLLOWER_ERROR: 'DELETE_GROUP_FOLLOWER_ERROR',
  DELETE_GROUP_MEMBER_START: 'DELETE_GROUP_MEMBER_START',
  DELETE_GROUP_MEMBER_SUCCESS: 'DELETE_GROUP_MEMBER_SUCCESS',
  DELETE_GROUP_MEMBER_ERROR: 'DELETE_GROUP_MEMBER_ERROR',
  GET_EVENT_INVITED_START: 'GET_EVENT_INVITED_START',
  GET_EVENT_INVITED_SUCCESS: 'GET_EVENT_INVITED_SUCCESS',
  GET_EVENT_INVITED_ERROR: 'GET_EVENT_INVITED_ERROR',
  GET_EVENT_ATTENDING_START: 'GET_EVENT_ATTENDING_START',
  GET_EVENT_ATTENDING_SUCCESS: 'GET_EVENT_ATTENDING_SUCCESS',
  GET_EVENT_ATTENDING_ERROR: 'GET_EVENT_ATTENDING_ERROR',
  GET_LIKES_POST_START: 'GET_LIKES_POST_START',
  GET_LIKES_POST_SUCCESS: 'GET_LIKES_POST_SUCCESS',
  GET_LIKES_POST_ERROR: 'GET_LIKES_POST_ERROR',
  GET_LIKES_VEHICLE_START: 'GET_LIKES_VEHICLE_START',
  GET_LIKES_VEHICLE_SUCCESS: 'GET_LIKES_VEHICLE_SUCCESS',
  GET_LIKES_VEHICLE_ERROR: 'GET_LIKES_VEHICLE_ERROR',
  GET_LIKES_EVENT_START: 'GET_LIKES_EVENT_START',
  GET_LIKES_EVENT_SUCCESS: 'GET_LIKES_EVENT_SUCCESS',
  GET_LIKES_EVENT_ERROR: 'GET_LIKES_EVENT_ERROR',
  CLEAR_LIKES_STORE: 'CLEAR_LIKES_STORE',
  CLEAR_INVOLVED_USERS_POST_STORE: 'CLEAR_INVOLVED_USERS_POST_STORE',
  GET_FEED_FOLLOWING_POSTS_START: 'GET_FEED_FOLLOWING_POSTS_START',
  GET_FEED_FOLLOWING_POSTS_SUCCESS: 'GET_FEED_FOLLOWING_POSTS_SUCCESS',
  GET_FEED_FOLLOWING_POSTS_ERROR: 'GET_FEED_FOLLOWING_POSTS_ERROR',
  SET_NEW_FEED_FOLLOWING_POSTS_DATA: 'SET_NEW_FEED_FOLLOWING_POSTS_DATA',
  GET_FEED_DISCOVER_POSTS_START: 'GET_FEED_DISCOVER_POSTS_START',
  GET_FEED_DISCOVER_POSTS_SUCCESS: 'GET_FEED_DISCOVER_POSTS_SUCCESS',
  GET_FEED_DISCOVER_POSTS_ERROR: 'GET_FEED_DISCOVER_POSTS_ERROR',
  GET_FEED_EVENT_POSTS_START: 'GET_FEED_EVENT_POSTS_START',
  GET_FEED_EVENT_POSTS_SUCCESS: 'GET_FEED_EVENT_POSTS_SUCCESS',
  GET_FEED_EVENT_POSTS_ERROR: 'GET_FEED_EVENT_POSTS_ERROR',
  SET_FEED_PARAMS: 'SET_FEED_PARAMS',
  SET_LIKE: 'SET_LIKE',
  GET_GROUP_POSTS_START: 'GET_GROUP_POSTS_START',
  GET_GROUP_POSTS_SUCCESS: 'GET_GROUP_POSTS_SUCCESS',
  GET_GROUP_POSTS_ERROR: 'GET_GROUP_POSTS_ERROR',
  SET_REDIRECT_FROM_GROUP_DETAILS: 'SET_REDIRECT_FROM_GROUP_DETAILS',
  GET_GROUP_VEHICLES_START: 'GET_GROUP_VEHICLES_START',
  GET_GROUP_VEHICLES_SUCCESS: 'GET_GROUP_VEHICLES_SUCCESS',
  GET_GROUP_VEHICLES_ERROR: 'GET_GROUP_VEHICLES_ERROR',
  ADD_LIKE_TO_DETAILS_SCREEN_POST: 'ADD_LIKE_TO_DETAILS_SCREEN_POST',
  REMOVE_LIKE_FROM_DETAILS_SCREEN_POST: 'REMOVE_LIKE_FROM_DETAILS_SCREEN_POST',
  SET_POSTS_ON_DETAILS_PAGE: 'SET_POSTS_ON_DETAILS_PAGE',
  GET_EVENT_POSTS_START: 'GET_EVENT_POSTS_START',
  GET_EVENT_POSTS_SUCCESS: 'GET_EVENT_POSTS_SUCCESS',
  GET_EVENT_POSTS_ERROR: 'GET_EVENT_POSTS_ERROR',
  GET_EVENT_VEHICLES_START: 'GET_EVENT_VEHICLES_START',
  GET_EVENT_VEHICLES_ERROR: 'GET_EVENT_VEHICLES_ERROR',
  GET_EVENT_VEHICLES_SUCCESS: 'GET_EVENT_VEHICLES_SUCCESS',
  SET_REDIRECT_FROM_EVENT_DETAILS: 'SET_REDIRECT_FROM_EVENT_DETAILS',
  PUT_FAVOURITE_MEDIA_REQUEST: 'PUT_FAVOURITE_MEDIA_REQUEST',
  PUT_FAVOURITE_MEDIA_SUCCESS: 'PUT_FAVOURITE_MEDIA_SUCCESS',
  PUT_FAVOURITE_MEDIA_ERROR: 'PUT_FAVOURITE_MEDIA_ERROR',
  DELETE_FAVOURITE_MEDIA_REQUEST: 'DELETE_FAVOURITE_MEDIA_REQUEST',
  DELETE_FAVOURITE_MEDIA_SUCCESS: 'DELETE_FAVOURITE_MEDIA_SUCCESS',
  DELETE_FAVOURITE_MEDIA_ERROR: 'DELETE_FAVOURITE_MEDIA_ERROR',
};

// PUT FAVOURITE MEDIA POST

export const putFavouriteMediaRequest = (postId) => ({
  type: actionTypes.PUT_FAVOURITE_MEDIA_REQUEST,
  postId,
});
export const putFavouriteMediaSuccess = () => ({
  type: actionTypes.PUT_FAVOURITE_MEDIA_SUCCESS,
});
export const putFavouriteMediaError = (error) => ({
  type: actionTypes.PUT_FAVOURITE_MEDIA_ERROR,
  error,
});

export const deleteFavouriteMediaRequest = (postId) => ({
  type: actionTypes.DELETE_FAVOURITE_MEDIA_REQUEST,
  postId,
});
export const deleteFavouriteMediaSuccess = () => ({
  type: actionTypes.DELETE_FAVOURITE_MEDIA_SUCCESS,
});
export const deleteFavouriteMediaError = (error) => ({
  type: actionTypes.DELETE_FAVOURITE_MEDIA_ERROR,
  error,
});
// ADD POST ACTIONS
export const createPostStart = (data) => ({
  type: actionTypes.CREATE_POST_START,
  data,
});

export const createPostSuccess = () => ({
  type: actionTypes.CREATE_POST_SUCCESS,
});

export const createPostError = (error) => ({
  type: actionTypes.CREATE_POST_ERROR,
  error,
});

export const clearCreatePostReqStat = () => ({
  type: actionTypes.CLEAR_CREATE_POST_REQ_STAT,
});

// PARSE LINK ACTIONS
export const parseLinkStart = (data) => ({
  type: actionTypes.PARSE_LINK_START,
  data,
});

export const parseLinkSuccess = (data) => ({
  type: actionTypes.PARSE_LINK_SUCCESS,
  data,
});

export const parseLinkError = (error) => ({
  type: actionTypes.PARSE_LINK_ERROR,
  error,
});

// CLEAR POSTS ACTIONS
export const clearPostsStore = () => ({
  type: actionTypes.CLEAR_POSTS_STORE,
});

// CREATE EVENT ACTIONS
export const createEventStart = (data) => ({
  type: actionTypes.CREATE_EVENT_START,
  data,
});

export const createEventSuccess = (data) => ({
  type: actionTypes.CREATE_EVENT_SUCCESS,
  data,
});

export const createEventError = (error) => ({
  type: actionTypes.CREATE_EVENT_ERROR,
  error,
});

export const clearCreateEventReqStat = () => ({
  type: actionTypes.CLEAR_CREATE_EVENT_REQ_STAT,
});

// GET EVENT INFO ACTIONS
export const getEventDetailsStart = (data) => ({
  type: actionTypes.GET_EVENT_DETAILS_START,
  data,
});

export const getEventDetailsSuccess = (data) => ({
  type: actionTypes.GET_EVENT_DETAILS_SUCCESS,
  data,
});

export const getEventDetailsError = (error) => ({
  type: actionTypes.GET_EVENT_DETAILS_ERROR,
  error,
});

// GET POST DETAILS ACTIONS
export const getPostDetailsStart = (data) => ({
  type: actionTypes.GET_POST_DETAILS_START,
  data,
});

export const getPostDetailsSuccess = (data) => ({
  type: actionTypes.GET_POST_DETAILS_SUCCESS,
  data,
});

export const getPostDetailsError = (error) => ({
  type: actionTypes.GET_POST_DETAILS_ERROR,
  error,
});

export const clearPostDetailsData = () => ({
  type: actionTypes.CLEAR_POST_DETAILS_DATA,
});

// REPORT POST ACTIONS
export const reportPostStart = (data) => ({
  type: actionTypes.REPORT_POST_START,
  data,
});

export const reportPostSuccess = () => ({
  type: actionTypes.REPORT_POST_SUCCESS,
});

export const reportPostError = (error) => ({
  type: actionTypes.REPORT_POST_ERROR,
  error,
});

// REPORT GROUP ACTIONS
export const reportGroupStart = (data) => ({
  type: actionTypes.REPORT_GROUP_START,
  data,
});

export const reportGroupSuccess = () => ({
  type: actionTypes.REPORT_GROUP_SUCCESS,
});

export const reportGroupError = (error) => ({
  type: actionTypes.REPORT_GROUP_ERROR,
  error,
});

// REPORT EVENT ACTIONS
export const reportEventStart = (data) => ({
  type: actionTypes.REPORT_EVENT_START,
  data,
});

export const reportEventSuccess = () => ({
  type: actionTypes.REPORT_EVENT_SUCCESS,
});

export const reportEventError = (error) => ({
  type: actionTypes.REPORT_EVENT_ERROR,
  error,
});

// EDIT GROUP ACTIONS
export const editGroupStart = (data) => ({
  type: actionTypes.EDIT_GROUP_START,
  data,
});

export const editGroupSuccess = (data) => ({
  type: actionTypes.EDIT_GROUP_SUCCESS,
  data,
});

export const editGroupError = (error) => ({
  type: actionTypes.EDIT_GROUP_ERROR,
  error,
});

export const clearEditGroupStatus = () => ({
  type: actionTypes.CLEAR_EDIT_GROUP_STATUS,
});

// GET GROUP DETAILS ACTIONS
export const getGroupDetailsStart = (data) => ({
  type: actionTypes.GET_GROUP_DETAILS_START,
  data,
});

export const getGroupDetailsSuccess = (data) => ({
  type: actionTypes.GET_GROUP_DETAILS_SUCCESS,
  data,
});

export const getGroupDetailsError = (error) => ({
  type: actionTypes.GET_GROUP_DETAILS_ERROR,
  error,
});

// EDIT EVENT ACTIONS
export const editEventStart = (data) => ({
  type: actionTypes.EDIT_EVENT_START,
  data,
});

export const editEventSuccess = (data) => ({
  type: actionTypes.EDIT_EVENT_SUCCESS,
  data,
});

export const editEventError = (error) => ({
  type: actionTypes.EDIT_EVENT_ERROR,
  error,
});

export const clearEditEventReqStat = () => ({
  type: actionTypes.CLEAR_EDIT_EVENT_REQ_STAT,
});

// CANCEL EVENT ACTIONS
export const cancelEventStart = (data) => ({
  type: actionTypes.CANCEL_EVENT_START,
  data,
});

export const cancelEventSuccess = (data) => ({
  type: actionTypes.CANCEL_EVENT_SUCCESS,
  data,
});

export const cancelEventError = (error) => ({
  type: actionTypes.CANCEL_EVENT_ERROR,
  error,
});

// DELETE EVENT ACTIONS
export const deleteEventStart = (data) => ({
  type: actionTypes.DELETE_EVENT_START,
  data,
});

export const deleteEventSuccess = (data) => ({
  type: actionTypes.DELETE_EVENT_SUCCESS,
  data,
});

export const deleteEventError = (error) => ({
  type: actionTypes.DELETE_EVENT_ERROR,
  error,
});

// EDIT POST ACTIONS
export const editPostStart = (data) => ({
  type: actionTypes.EDIT_POST_START,
  data,
});

export const editPostSuccess = (data) => ({
  type: actionTypes.EDIT_POST_SUCCESS,
  data,
});

export const editPostError = (error) => ({
  type: actionTypes.EDIT_POST_ERROR,
  error,
});

export const clearEditPostStatus = () => ({
  type: actionTypes.CLEAR_EDIT_POST_STATUS,
});

// DELETE POST ACTIONS
export const deletePostStart = (data) => ({
  type: actionTypes.DELETE_POST_START,
  data,
});

export const deletePostSuccess = (data) => ({
  type: actionTypes.DELETE_POST_SUCCESS,
  data,
});

export const deletePostError = (error) => ({
  type: actionTypes.DELETE_POST_ERROR,
  error,
});

// REFRESH EVENT POSTS ACTIONS
export const refreshEventPosts = () => ({
  type: actionTypes.REFRESH_EVENT_POSTS,
});

// REFRESH GROUP POSTS ACTIONS
export const refreshGroupPosts = () => ({
  type: actionTypes.REFRESH_GROUP_POSTS,
});

// REFRESH FEED POSTS ACTIONS
export const refreshFeedPosts = (data) => ({
  type: actionTypes.REFRESH_FEED_POSTS,
  data,
});

// CLEAR REFRESHING STATUS ACTIONS
export const clearRefreshingStatus = () => ({
  type: actionTypes.CLEAR_REFRESHING_STATUS,
});

// GET POPULAR POSTS ACTIONS
export const getPopularPostsStart = () => ({
  type: actionTypes.GET_POPULAR_POSTS_START,
});

export const getPopularPostsSuccess = (data) => ({
  type: actionTypes.GET_POPULAR_POSTS_SUCCESS,
  data,
});

export const getPopularPostsError = (error) => ({
  type: actionTypes.GET_POPULAR_POSTS_ERROR,
  error,
});

// SET IS SCHEDULED POST CREATED
export const setIsScheduledPostCreated = (data) => ({
  type: actionTypes.SET_IS_SCHEDULED_POST_CREATED,
  data,
});

// ATTEND EVENT WITH VEHICLE ACTIONS
export const attendEventWithVehicleStart = (data) => ({
  type: actionTypes.ATTEND_EVENT_WITH_VEHICLE_START,
  data,
});

export const attendEventWithVehicleSuccess = (data) => ({
  type: actionTypes.ATTEND_EVENT_WITH_VEHICLE_SUCCESS,
  data,
});

export const attendEventWithVehicleError = (error) => ({
  type: actionTypes.ATTEND_EVENT_WITH_VEHICLE_ERROR,
  error,
});

// ATTEND EVENT WITHOUT VEHICLE ACTIONS
export const attendEventWithoutVehicleStart = (data) => ({
  type: actionTypes.ATTEND_EVENT_WITHOUT_VEHICLE_START,
  data,
});

export const attendEventWithoutVehicleSuccess = (data) => ({
  type: actionTypes.ATTEND_EVENT_WITHOUT_VEHICLE_SUCCESS,
  data,
});

export const attendEventWithoutVehicleError = (error) => ({
  type: actionTypes.ATTEND_EVENT_WITHOUT_VEHICLE_ERROR,
  error,
});

// CANCEL EVENT SUBSCRIPTION ACTIONS
export const cancelEventSubscriptionStart = (data) => ({
  type: actionTypes.CANCEL_EVENT_SUBSCRIPTION_START,
  data,
});

export const cancelEventSubscriptionSuccess = (data) => ({
  type: actionTypes.CANCEL_EVENT_SUBSCRIPTION_SUCCESS,
  data,
});

export const cancelEventSubscriptionError = (error) => ({
  type: actionTypes.CANCEL_EVENT_SUBSCRIPTION_ERROR,
  error,
});

// GET GROUP FOLLOWERS ACTIONS
export const getGroupFollowersStart = (data) => ({
  type: actionTypes.GET_GROUP_FOLLOWERS_START,
  data,
});

export const getGroupFollowersSuccess = (data) => ({
  type: actionTypes.GET_GROUP_FOLLOWERS_SUCCESS,
  data,
});

export const getGroupFollowersError = (error) => ({
  type: actionTypes.GET_GROUP_FOLLOWERS_ERROR,
  error,
});

// GET GROUP MEMBERS ACTIONS
export const getGroupAdminsV12Start = (data) => ({
  type: actionTypes.GET_GROUP_ADMINS_V12_START,
  data,
});

export const getGroupAdminsV12Success = (data) => ({
  type: actionTypes.GET_GROUP_ADMINS_V12_SUCCESS,
  data,
});

export const getGroupAdminsV12Error = (error) => ({
  type: actionTypes.GET_GROUP_ADMINS_V12_ERROR,
  error,
});

// GET GROUP ADMINS V12 ACTIONS
export const getGroupMembersStart = (data) => ({
  type: actionTypes.GET_GROUP_MEMBERS_START,
  data,
});

export const getGroupMembersSuccess = (data) => ({
  type: actionTypes.GET_GROUP_MEMBERS_SUCCESS,
  data,
});

export const getGroupMembersError = (error) => ({
  type: actionTypes.GET_GROUP_MEMBERS_ERROR,
  error,
});

// DELETE GROUP FOLLOWER ACTIONS
export const deleteGroupFollowerStart = (data) => ({
  type: actionTypes.DELETE_GROUP_FOLLOWER_START,
  data,
});

export const deleteGroupFollowerSuccess = () => ({
  type: actionTypes.DELETE_GROUP_FOLLOWER_SUCCESS,
});

export const deleteGroupFollowerError = (error) => ({
  type: actionTypes.DELETE_GROUP_FOLLOWER_ERROR,
  error,
});

// DELETE GROUP MEMBER ACTIONS
export const deleteGroupMemberStart = (data) => ({
  type: actionTypes.DELETE_GROUP_MEMBER_START,
  data,
});

export const deleteGroupMemberSuccess = () => ({
  type: actionTypes.DELETE_GROUP_MEMBER_SUCCESS,
});

export const deleteGroupMemberError = (error) => ({
  type: actionTypes.DELETE_GROUP_MEMBER_ERROR,
  error,
});

// GET EVENT INVITED ACTIONS
export const getEventInvitedStart = (data) => ({
  type: actionTypes.GET_EVENT_INVITED_START,
  data,
});

export const getEventInvitedSuccess = (data) => ({
  type: actionTypes.GET_EVENT_INVITED_SUCCESS,
  data,
});

export const getEventInvitedError = (error) => ({
  type: actionTypes.GET_EVENT_INVITED_ERROR,
  error,
});

// GET EVENT ATTENDING ACTIONS
export const getEventAttendingStart = (data) => ({
  type: actionTypes.GET_EVENT_ATTENDING_START,
  data,
});

export const getEventAttendingSuccess = (data) => ({
  type: actionTypes.GET_EVENT_ATTENDING_SUCCESS,
  data,
});

export const getEventAttendingError = (error) => ({
  type: actionTypes.GET_EVENT_ATTENDING_ERROR,
  error,
});

// GET LIKES POST ACTIONS
export const getLikesPostStart = (data) => ({
  type: actionTypes.GET_LIKES_POST_START,
  data,
});

export const getLikesPostSuccess = (data) => ({
  type: actionTypes.GET_LIKES_POST_SUCCESS,
  data,
});

export const getLikesPostError = (error) => ({
  type: actionTypes.GET_LIKES_POST_ERROR,
  error,
});

// GET LIKES VEHICLE START
export const getLikesVehicleStart = (data) => ({
  type: actionTypes.GET_LIKES_VEHICLE_START,
  data,
});

export const getLikesVehicleSuccess = (data) => ({
  type: actionTypes.GET_LIKES_VEHICLE_SUCCESS,
  data,
});

export const getLikesVehicleError = (error) => ({
  type: actionTypes.GET_LIKES_VEHICLE_ERROR,
  error,
});

// GET LIKES EVENT ACTIONS
export const getLikesEventStart = (data) => ({
  type: actionTypes.GET_LIKES_EVENT_START,
  data,
});

export const getLikesEventSuccess = (data) => ({
  type: actionTypes.GET_LIKES_EVENT_SUCCESS,
  data,
});

export const getLikesEventError = (error) => ({
  type: actionTypes.GET_LIKES_EVENT_ERROR,
  error,
});

// CLEAR LIKES STORE
export const clearLikesStore = () => ({
  type: actionTypes.CLEAR_LIKES_STORE,
});

// CLEAR INVOLVED USERS POST STORE
export const clearInvolvedUsersPostStore = (data) => ({
  type: actionTypes.CLEAR_INVOLVED_USERS_POST_STORE,
  data,
});

// GET FEED FOLLOWING POSTS ACTIONS
export const getFeedFollowingPostsStart = (data) => ({
  type: actionTypes.GET_FEED_FOLLOWING_POSTS_START,
  data,
});

export const getFeedFollowingPostsSuccess = (data) => ({
  type: actionTypes.GET_FEED_FOLLOWING_POSTS_SUCCESS,
  data,
});

export const getFeedFollowingPostsError = (error) => ({
  type: actionTypes.GET_FEED_FOLLOWING_POSTS_ERROR,
  error,
});

export const setNewFollowingFeedPostsData = (data) => ({
  type: actionTypes.SET_NEW_FEED_FOLLOWING_POSTS_DATA,
  data,
});

// GET FEED DISCOVER POSTS ACTIONS
export const getFeedDiscoverPostsStart = (data) => ({
  type: actionTypes.GET_FEED_DISCOVER_POSTS_START,
  data,
});

export const getFeedDiscoverPostsSuccess = (data) => ({
  type: actionTypes.GET_FEED_DISCOVER_POSTS_SUCCESS,
  data,
});

export const getFeedDiscoverPostsError = (error) => ({
  type: actionTypes.GET_FEED_DISCOVER_POSTS_ERROR,
  error,
});

// GET FEED EVENT POSTS ACTIONS
export const getFeedEventPostsStart = (data) => ({
  type: actionTypes.GET_FEED_EVENT_POSTS_START,
  data,
});

export const getFeedEventPostsSuccess = (data) => ({
  type: actionTypes.GET_FEED_EVENT_POSTS_SUCCESS,
  data,
});

export const getFeedEventPostsError = (error) => ({
  type: actionTypes.GET_FEED_EVENT_POSTS_ERROR,
  error,
});

// SET FEED PARAMS
export const setFeedParams = (data) => ({
  type: actionTypes.SET_FEED_PARAMS,
  data,
});

// SET LIKE
export const setLike = (data) => ({
  type: actionTypes.SET_LIKE,
  data,
});

// GET GROUP POSTS ACTIONS
export const getGroupPostsStart = (data) => ({
  type: actionTypes.GET_GROUP_POSTS_START,
  data,
});

export const getGroupPostsSuccess = (data) => ({
  type: actionTypes.GET_GROUP_POSTS_SUCCESS,
  data,
});

export const getGroupPostsError = (error) => ({
  type: actionTypes.GET_GROUP_POSTS_ERROR,
  error,
});

// SET REDIRECT FROM GROUP DETAILS ACTIONS
export const setRedirectFromGroupDetails = (data) => ({
  type: actionTypes.SET_REDIRECT_FROM_GROUP_DETAILS,
  data,
});

// GET GROUP VEHICLES
export const getGroupVehiclesStart = (data) => ({
  type: actionTypes.GET_GROUP_VEHICLES_START,
  data,
});

export const getGroupVehiclesSuccess = (data) => ({
  type: actionTypes.GET_GROUP_VEHICLES_SUCCESS,
  data,
});

export const getGroupVehiclesError = (error) => ({
  type: actionTypes.GET_GROUP_VEHICLES_ERROR,
  error,
});

// ADD/REMOVE LIKE TO DETAIL SCREEN POST ACTIONS
export const addLikeToDetailsScreenPost = (data) => ({
  type: actionTypes.ADD_LIKE_TO_DETAILS_SCREEN_POST,
  data,
});

export const removeLikeFromDetailsScreenPost = (data) => ({
  type: actionTypes.REMOVE_LIKE_FROM_DETAILS_SCREEN_POST,
  data,
});

// SET POST ON DETAILS PAGE
export const setPostsOnDetailsPage = (data) => ({
  type: actionTypes.SET_POSTS_ON_DETAILS_PAGE,
  data,
});

// GET EVENT POSTS ACTIONS
export const getEventPostsStart = (data) => ({
  type: actionTypes.GET_EVENT_POSTS_START,
  data,
});

export const getEventPostsSuccess = (data) => ({
  type: actionTypes.GET_EVENT_POSTS_SUCCESS,
  data,
});

export const getEventPostsError = (error) => ({
  type: actionTypes.GET_EVENT_POSTS_ERROR,
  error,
});

// GET EVENT VEHICLES ACTIONS
export const getEventVehiclesStart = (data) => ({
  type: actionTypes.GET_EVENT_VEHICLES_START,
  data,
});

export const getEventVehiclesSuccess = (data) => ({
  type: actionTypes.GET_EVENT_VEHICLES_SUCCESS,
  data,
});

export const getEventVehiclesError = (error) => ({
  type: actionTypes.GET_EVENT_VEHICLES_ERROR,
  error,
});

// SET REDIRECT FROM EVENT DETAILS ACTIONS
export const setRedirectFromEventDetails = (data) => ({
  type: actionTypes.SET_REDIRECT_FROM_EVENT_DETAILS,
  data,
});
