import { call, put, takeLatest } from 'redux-saga/effects';

import {
  deleteMember,
  getAllClubs,
  getMemberList,
  getSingleClub,
  postActiveClubInfo,
  postJoinClub,
} from 'services';

import {
  actionTypes,
  getAllClubsSuccess,
  getAllClubsFailure,
  getSingleClubSuccess,
  getSingleClubFailure,
  getMemberListSuccess,
  getMemberListFailure,
  postActiveClubInfoSuccess,
  postActiveClubInfoFailure,
  postJoinClubSuccess,
  postJoinClubFailure,
  deleteMemberSuccess,
  deleteMemberFailure,
} from './actions';

function* workerGetAllClubs() {
  try {
    const resp = yield call(getAllClubs);
    yield put(getAllClubsSuccess(resp?.data));
  } catch (err) {
    yield put(getAllClubsFailure(err));
  }
}
function* workerGetSingleClub(action) {
  try {
    const { clubId } = action.payload;
    const params = { clubId };
    const resp = yield call(getSingleClub, params);
    yield put(getSingleClubSuccess(resp?.data));
  } catch (err) {
    yield put(getSingleClubFailure(err));
  }
}
function* workerGetMemberList(action) {
  try {
    const { clubId } = action.payload;
    const params = { clubId };
    const resp = yield call(getMemberList, params);
    console.log('GET MEMBER LIST IS', resp);
    yield put(getMemberListSuccess(resp?.data));
  } catch (err) {
    yield put(getMemberListFailure(err));
  }
}
function* workerPostActiveClubInfo(action) {
  try {
    const { clubId } = action.payload;
    const activeClubId = clubId === 'RoadStr' ? null : clubId;
    const params = { clubId: activeClubId };
    const resp = yield call(postActiveClubInfo, params);
    yield put(postActiveClubInfoSuccess(resp?.data));
  } catch (err) {
    yield put(postActiveClubInfoFailure(err));
  }
}
function* workerPostJoinClub(action) {
  try {
    const { clubId, inviteToken, onSuccess } = action.payload;
    const params = { clubId, inviteToken };
    const resp = yield call(postJoinClub, params);
    yield put(postJoinClubSuccess(resp?.data));
    // eslint-disable-next-line no-unused-expressions
    onSuccess && onSuccess(true);
  } catch (err) {
    yield put(postJoinClubFailure(err));
  }
}
function* workerDeleteMember(action) {
  try {
    const { clubId, memberId } = action.payload;
    const params = { clubId, memberId };
    const resp = yield call(deleteMember, params);
    console.log('DELETE MEMBER IS', resp);
    yield put(deleteMemberSuccess(resp?.data));
  } catch (err) {
    yield put(deleteMemberFailure(err));
  }
}

function* watchClub() {
  yield takeLatest(actionTypes.GET_ALL_CLUBS_REQUEST, workerGetAllClubs);
  yield takeLatest(actionTypes.GET_SINGLE_CLUB_REQUEST, workerGetSingleClub);
  yield takeLatest(actionTypes.GET_MEMBER_LIST_REQUEST, workerGetMemberList);
  yield takeLatest(
    actionTypes.POST_ACTIVE_CLUB_INFO_REQUEST,
    workerPostActiveClubInfo
  );
  yield takeLatest(actionTypes.POST_JOIN_CLUB_REQUEST, workerPostJoinClub);
  yield takeLatest(actionTypes.DELETE_MEMBER_REQUEST, workerDeleteMember);
}

export default watchClub;
