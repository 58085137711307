import styled from 'styled-components';

interface IProps {
  active?: boolean;
  width?: number;
  withBorder?: boolean;
  variant?: string;
}

export const NavTabsContainer = styled.div<IProps>`
  display: flex;
  width: 100%;
  max-width: 100%;
  background: ${(props) =>
    props.variant !== 'pill' ? props.theme.colors.white : 'none'};
  box-shadow: ${(props) =>
    props.variant !== 'pill' && props.theme.colors.shadows.sm};
  border-radius: 8px;
  margin: 20px 0;
  & > span {
    position: relative;
    border-radius: ${(props) => props.variant !== 'pill' && '0'} !important;
    &:first-child {
      border-radius: ${(props) =>
        props.variant !== 'pill' ? '8px 0 0 8px' : '8px'} !important;
    }
    &:last-child {
      border-radius: ${(props) =>
        props.variant !== 'pill' ? '0 8px 8px 0' : '8px'} !important;
      &::after {
        display: ${(props) => props.variant !== 'pill' && 'none'};
      }
    }
  }
`;

export const TabContainer = styled.span<IProps>`
  display: ${(props) => props.variant !== 'pill' && 'flex'};
  justify-content: ${(props) => props.variant !== 'pill' && 'center'};
  align-items: ${(props) => props.variant !== 'pill' && 'center'};
  width: ${(props) => (props.variant !== 'pill' ? '100%' : 'auto')};
  padding: ${(props) => (props.variant !== 'pill' ? '14px 0' : '9px 14px')};
  border-radius: 8px;
  font-family: ${(props) => props.theme.typography.fontFamily.primary};
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  font-weight: ${(props) => props.theme.typography.fontWeight.normal};
  text-transform: capitalize;
  color: ${(props) =>
    props.active ? props.theme.colors.white : props.theme.colors.text};
  line-height: 100%;
  background: ${(props) =>
    (props.active &&
      props.variant !== 'pill' &&
      props.theme.colors.neutral.n11) ||
    (props.active && props.variant === 'pill' && props.theme.colors.blue) ||
    (!props.active && props.variant !== 'pill' && props.theme.colors.white) ||
    'transparent'};
  letter-spacing: 0.02em;
  transition: all 0.3s;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: ${(props) => props.variant === 'pill' && '12px'};
  }
  &::after {
    content: ${(props) => (props.variant !== 'pill' ? `''` : 'none')};
    position: absolute;
    height: 14px;
    width: 1px;
    right: 0;
    background: ${(props) => props.withBorder && props.theme.colors.neutral.n6};
  }
  &:hover {
    background: ${(props) => !props.active && props.theme.colors.neutral.n4};
    transition: all 0.3s;
  }
`;
export const IconWrapper = styled.span<{ active?: boolean }>`
  display: inline-flex;
  align-items: center;
  margin-left: 5px; 
  color: ${(props) =>
    props.active ? props.theme.colors.white : props.theme.colors.text};
`;