import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { minimalTimezoneSet } from 'node_modules/compact-timezone-list';

export const mapTimezoneOffsetToMinutes = (tz = '+00:00') => {
  let timeZoneOffsetInMinutes =
    60 * Number.parseInt(tz.slice(1, 3), 10) +
    Number.parseInt(tz.slice(4, 6), 10);
  if (tz[0] === '+') {
    return timeZoneOffsetInMinutes;
  } else {
    return -timeZoneOffsetInMinutes;
  }
};

export const convertTimezoneOffsetToHhmmFormat = (offset) => {
  const sign = offset < 0 ? '-' : '+';

  const offsetAbs = Math.abs(offset);

  const hours = +(offsetAbs / 60).toFixed(0);
  const hoursStyled = hours < 10 ? '0' + hours : hours;

  const minutes = offsetAbs - 60 * +hours;
  const minutesStyled = minutes < 10 ? '0' + minutes : offsetAbs - 60 * +hours;

  return `${sign}${hoursStyled}:${minutesStyled}`;
};

export const getTimezoneLabel = (offsetMinutes = 0) => {
  const offsetHhmm = convertTimezoneOffsetToHhmmFormat(offsetMinutes);
  const tzData = minimalTimezoneSet.find((tz) => tz.offset === offsetHhmm);
  const tzShortLabel = momentTimezone.tz(tzData.tzCode).format('z');

  const isLabelValid = !tzShortLabel.match(/[+-]/);
  if (isLabelValid) {
    return `(GMT${offsetHhmm}) ${tzShortLabel}`;
  }

  return minimalTimezoneSet.find((tz) => tz.tzCode === tzData.tzCode).label;
};

export const getUserTimezoneOffset = (inMinutes = true) => {
  const offsetInMinutes = moment().isDST()
    ? moment().utcOffset() - 60
    : moment().utcOffset();

  if (inMinutes) {
    return offsetInMinutes;
  }

  return convertTimezoneOffsetToHhmmFormat(offsetInMinutes);
};

// Returns ms based on if the date isDTS
// Miliseconds comes ms*1000
export const checkForDST = (miliseconds) => {
  return moment(miliseconds).isDST() ? miliseconds - 3600000 : miliseconds;
};

export const getUpdatedTime = (time: {
  startDate: any;
  endDate: any;
  timezone: any;
}) => {
  let isStartDST = moment(time.startDate).isDST();
  let isEndDST = moment(time.endDate).isDST();
  let startTime: any;
  let endTime: any;
  let tzAvailable: { offset: string; label: string; tzCode: string };
  if (time?.timezone) {
    tzAvailable = minimalTimezoneSet.find((tz) => tz.tzCode === time.timezone);
    // If timezone didn't find in minimal timezone offset then find it in moment and validate the offset
    if (!tzAvailable) {
      tzAvailable = minimalTimezoneSet.find(
        (tz) => tz.offset === moment.tz(time?.timezone).format('Z')
      );
    }
  }
  startTime = isStartDST ? moment(time.startDate).add(1, 'hour') : time.startDate;
  endTime = isEndDST ? moment(time.endDate).add(1, 'hour') : time.endDate;
  startTime = startTime.utcOffset(
    mapTimezoneOffsetToMinutes(tzAvailable.offset)
  );
  endTime = endTime.utcOffset(mapTimezoneOffsetToMinutes(tzAvailable?.offset));
  return {
    startDate: startTime,
    endDate: endTime,
    tzCode: tzAvailable?.tzCode,
    tzOffset: tzAvailable?.offset,
  };
};
