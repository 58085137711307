export function objectToQueryParams(obj: any) {
  return Object.keys(obj)
    ?.filter((el) => !!obj[el])
    .map((key) => {
      const value = obj[key];
      const encodedValue = Array.isArray(value)
        ? value.join(',')
        : encodeURIComponent(value).replace(/%2C/g, ',');
      return `${encodeURIComponent(key)}=${encodedValue}`;
    })
    .join('&');
}
