import { useRef } from 'react';

const useDataRef = (name: string, state: any) => {
  const currentData = useRef({ data: [] });
  currentData.current = state[name]
    ? { ...currentData.current, ...state[name] }
    : currentData.current;
  return currentData;
};

export default useDataRef;
