import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useField, useForm } from 'react-final-form';

import { composeValidators, required } from 'utils/validators';
import FieldElect from 'components/common/FieldElect';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';
import { SearchIcon, WheelIcon, PlusIcon } from 'public/assets/icons';
import { addCarModelStart } from 'store/vehicle/actions';
import { IModel } from './IVehicleForm';

import {
  FormRenderPills,
  ItemForSelectText,
  ItemForSelectValue,
  ItemForSelectWrapper,
  RenderPills,
  SelectedPills,
  SelectedItemWrapper,
  SelectedItemText,
  SelectedValues,
  NoResultWrap,
  NoResultText,
  FormSearchWrapper,
  FormSearch,
  NewAttrContainer,
  NewAttrTouchable,
  NewAttrText,
  NewAttrTextStatic,
  NewAttrTextDynamic,
  PlusIconWrap,
} from './VehicleForm.styles';

const search = (data: any[], searchValue: string) => {
  const searchValueLowerCase = searchValue?.toLocaleLowerCase();

  return data?.filter((elem) =>
    elem?.model?.toLowerCase()?.includes(searchValueLowerCase)
  );
};

const Model = ({ id }: IModel) => {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const dispatch = useDispatch();
  const searchValue = useField('search').input.value;
  const form = useForm();
  const getCarModels = useSelector((store) => store.fetchData);

  const { action, loading } = fetchApi();

  useEffect(() => {
    if (id) {
      action(API_NAME.GET_CAR_MODELS, {
        params: {
          id,
        },
      });
    }
    form.change('search', '');
  }, [id]);

  useEffect(() => {
    if(!id) {
      form.change('model', '')
    }
  }, [id])

  const handleSelect = (value: string, onChange: (e: string) => void) => {
    const selected = getCarModels[API_NAME.GET_CAR_MODELS]?.data?.find(
      (brand: { model: string }) => brand.model === value
    );

    if (!selected && searchValue) {
      dispatch(addCarModelStart({ model: value, brandId: id }));
      onChange(searchValue);
    } else {
      onChange(value);
    }
    form.change('search', '');
  };

  const hasModels = getCarModels[API_NAME.GET_CAR_MODELS]?.data?.length > 0;
  const searchedModels = useMemo(
    () =>
      hasModels
        ? search(getCarModels[API_NAME.GET_CAR_MODELS]?.data, searchValue)
        : [],
    [hasModels, searchValue, getCarModels[API_NAME.GET_CAR_MODELS]?.data]
  );
  console.log('SEARCHED MODELS ARE', searchedModels)

  return (
    <FieldElect
      name="model"
      label={t('addVehicle:model')}
      headerText="My models"
      multipleSelect={false}
      isDisabled={!id}
      render={(b, onRemove) =>
        b?.length > 0 && (
          <RenderPills
            key={b}
            onCrossClick={() => onRemove(b)}
            bgColor={theme.colors.neutral.n0}
            type="addTags"
            isCross
          >
            {b}
          </RenderPills>
        )
      }
      formRender={(value, onChange) => {
        return (
          <>
            <FormSearchWrapper>
              <FormSearch
                name="search"
                prefix={<SearchIcon colorStroke={theme.colors.neutral.n6} />}
              />
            </FormSearchWrapper>
            {!!searchValue && !searchedModels?.length && (
              <NewAttrContainer>
                <NewAttrTouchable
                  onClick={() => handleSelect(searchValue, onChange)}
                >
                  <PlusIconWrap>
                    <PlusIcon />
                  </PlusIconWrap>
                  <NewAttrText>
                    <NewAttrTextStatic>{t('add')}</NewAttrTextStatic>
                    <NewAttrTextDynamic>{`" ${searchValue} "`}</NewAttrTextDynamic>
                    <NewAttrTextStatic>{`${t(
                      'addVehicle:asNew'
                    )} `}</NewAttrTextStatic>
                  </NewAttrText>
                </NewAttrTouchable>
              </NewAttrContainer>
            )}
            {value?.length > 0 && (
              <SelectedItemWrapper>
                <SelectedItemText>{t('common:selected')}</SelectedItemText>
                <SelectedValues>
                  {value && (
                    <SelectedPills
                      key={value}
                      onCrossClick={() => onChange()}
                      bgColor={theme.colors.blue}
                      color={theme.colors.white}
                      type="addTags"
                      isCross
                    >
                      {value}
                    </SelectedPills>
                  )}
                </SelectedValues>
              </SelectedItemWrapper>
            )}

            <ItemForSelectWrapper>
              <ItemForSelectText>
                {t('addVehicle:selectCarModel')}
              </ItemForSelectText>
              <ItemForSelectValue>
                {hasModels ? (
                  searchedModels?.map((s, i) => {
                    return (
                      <FormRenderPills
                        key={s?._id || i}
                        onSelectItemClick={() => onChange(s?.model)}
                        bgColor={
                          s?.model === value
                            ? theme.colors.blue
                            : theme.colors.neutral.n0
                        }
                        color={
                          s?.model === value
                            ? theme.colors.white
                            : theme.colors.neutral.text
                        }
                        type="addTags"
                      >
                        {s?.model}
                      </FormRenderPills>
                    );
                  })
                ) : (
                  <NoResultWrap>
                    <NoResultText>{t('chat:nothingFound')}</NoResultText>
                    <WheelIcon />
                  </NoResultWrap>
                )}
              </ItemForSelectValue>
            </ItemForSelectWrapper>
          </>
        );
      }}
      validate={composeValidators(required(t('error:thisFieldIsRequired')))}
    />
  );
};

export default Model;
