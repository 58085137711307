import React, { forwardRef } from 'react';
import Image from 'components/common/Image';
import { CheckMarkIcon, EmptyGroupIcon } from 'public/assets/icons';
import imageResize from 'utils/imageResize';
import { EmptyUserProfilePhoto } from 'components';
import { IPostCreatorList } from './ICreateEventPostForm';

import {
  EmptyUserPhoto,
  ImageWrapper,
  ItemDescription,
  ItemDescriptions,
  ListItemName,
  ItemWrap,
  CheckIcon,
  TextContainer,
} from './CreateEventPostForm.styles';

const PostCreatorsList = forwardRef(
  (
    {
      coverPhoto,
      name,
      username,
      _id,
      onSelect,
      selected,
      isUser,
    }: IPostCreatorList & {
      onSelect?: (club: IPostCreatorList) => void;
      selected?: boolean;
      isUser?: boolean;
    },
    ref
  ) => {
    const handleClick = () => {
      if (onSelect) {
        onSelect({ coverPhoto, name, username, _id });
      }
    };

    return (
      <ItemWrap onClick={handleClick} ref={ref}>
        <ImageWrapper>
          {isUser && !String(coverPhoto)?.trim()?.length ? (
            <EmptyUserProfilePhoto
              wrapSize={28}
              userIconSize={14}
              withBorder={false}
              cursor="pointer"
            />
          ) : coverPhoto && coverPhoto.trim() !== '' ? (
            <Image
              src={imageResize(coverPhoto, '_thumb') || coverPhoto?.trim()}
              alt="list item image"
              height={40}
              width={40}
              unoptimized
              priority
            />
          ) : (
            <EmptyUserPhoto>
              <EmptyGroupIcon />
            </EmptyUserPhoto>
          )}
        </ImageWrapper>
        <TextContainer>
          <ItemDescriptions>
            <ListItemName>{username}</ListItemName>
            <ItemDescription>{name}</ItemDescription>
          </ItemDescriptions>
          <CheckIcon checked={selected}>
            {selected && <CheckMarkIcon />}
          </CheckIcon>
        </TextContainer>
      </ItemWrap>
    );
  }
);

export default PostCreatorsList;
