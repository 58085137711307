import styled from 'styled-components';

import Text from 'components/common/Text';
import Heading from 'components/common/Typography/Heading';

interface IProps {
  pt?: number;
  pb?: number;
  mt?: number;
  mb?: number;
  fw?: number;
  fs?: number;
  mr?: number;
  isSelectedStep?: boolean;
  selected?: boolean;
  checked?: boolean;
}

export const JoiningManagementContentWrapper = styled.div<IProps>`
  & > div {
    &:first-child,
    & > div {
      & > label {
        & > div {
          & > span {
            height: 20px;
            width: 20px;
            border: 2px solid ${(props) => props.theme.colors.blue};
            &::after {
              left: 7px;
              top: 3px;
              width: 4px;
              height: 10px;
            }
          }
        }
      }
    }
    &:last-child {
      & > div {
        & > label {
          & > div {
            ${(props) => {
              if (props.checked) {
                return `
                  margin-bottom: 12px;
                  &:nth-child(3) {
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    & > span {
                      height: 20px;
                      width: 20px;
                      border: 2px solid ${props.theme.colors.blue};
                      &::after {
                        left: 7px;
                        top: 3px;
                        width: 4px;
                        height: 10px;
                      }
                    }
                  }
                `;
              }
              return `
                &:nth-child(2) {
                  position: absolute;
                  top: 20px;
                  right: 0px;
                  & > span {
                    height: 20px;
                    width: 20px;
                    border: 2px solid ${props.theme.colors.blue};
                    &::after {
                      left: 7px;
                      top: 3px;
                      width: 4px;
                      height: 10px;
                    }
                  }
                }
              `;
            }}
          }
        }
      }
    }
  }
`;

export const JoiningManagementContentBlock = styled.div<IProps>`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  border: 1px solid
    ${(props) =>
      !props.checked
        ? props.theme.colors.neutral.n4
        : props.theme.colors.blueHover};
  border-radius: 6px;
  margin-bottom: 16px;
`;

export const JoiningManagementContentBlockLeft = styled.div`
  margin-right: 30px;
`;
export const JoiningManagementContentBlockRight = styled.div`
  & > label {
    padding-left: 0;
    margin-bottom: 0;
  }
`;

export const BlockHeading = styled(Heading)<IProps>`
  font-size: ${({ fs, theme }) =>
    fs ? `${fs}px` : theme.typography.fontSize.f6};
  line-height: 150%;
  font-weight: ${({ fw }) => fw || '600'};
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.text};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;
export const BlockText = styled(Text)`
  font-size: ${(props) => props.theme.typography.fontSize.f2};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.colors.neutral.n9};
  letter-spacing: 0.02em;
`;

export const SecretCodeInputBlock = styled.div`
  & input {
    text-transform: uppercase;
    &::placeholder {
      text-transform: capitalize;
      color: ${(props) => props.theme.colors.neutral.n9};
    }
  }
`;
