import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useTranslation } from 'react-i18next';

import { style } from 'utils/constants/style';
import { IImageCropper } from './IImageCropper';
import { Wrapper, ButtonWrap } from './ImageCropper.style';
import { RectangleButton } from '..';

async function getCroppedImg(
  imageSrc,
  pixelCrop,
  isBlobImage,
  imgRef
): Promise<string> {
  const image = imgRef.current;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  const croppedImageWidth = pixelCrop.width ? pixelCrop.width : image.width;
  const cropperImageHeight = pixelCrop.height ? pixelCrop.height : image.height;

  canvas.width = croppedImageWidth * pixelRatio * scaleX;
  canvas.height = cropperImageHeight * pixelRatio * scaleY;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  // Draw cropped image
  ctx.drawImage(
    image,
    pixelCrop.x * scaleX,
    pixelCrop.y * scaleY,
    croppedImageWidth * scaleX,
    cropperImageHeight * scaleY,
    0,
    0,
    croppedImageWidth * scaleX,
    cropperImageHeight * scaleY
  );

  if (isBlobImage) {
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, 'image/png');
    });
  }

  return canvas.toDataURL('image/jpeg');
}

const ImageCropper = ({
  image,
  aspect,
  isCircleShape,
  isCoverImage,
  onSubmitImage,
  isProfileImages,
  isBlobImage = false,
  isAspect = false,
}: IImageCropper) => {
  const imgRef = useRef(null);
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    width: 100,
    height: 100,
    x: 0,
    y: 0,
  });

  const { t } = useTranslation();
  const [imageInfo, setImageInfo] = useState({ width: 0, height: 0 });

  // Handle crop change, making sure it stays within bounds
  const onCropChange = (newCrop) => {
    const maxWidth = imgRef.current.offsetWidth;
    const maxHeight = imgRef.current.offsetHeight;

    const adjustedCrop = { ...newCrop };

    // Ensure the crop width doesn't exceed the image bounds
    if (newCrop.width > maxWidth) {
      adjustedCrop.width = maxWidth;
    }

    // Ensure the crop height doesn't exceed the image bounds
    if (newCrop.height > maxHeight) {
      adjustedCrop.height = maxHeight;
    }

    setCrop(adjustedCrop);
  };

  const getCropData = useCallback(
    (
      width = 270,
      height = 100,
      matchParent = false,
      isCircleShape = false
    ): any => {
      if (isCircleShape && width && height) {
        return {
          unit: 'px',
          width: Math.min(width, height),
          height: Math.min(width, height),
          x: 0,
          y: 0,
          aspect: 1,
        };
      }
      if (matchParent) {
        if (!width || !height) {
          return {
            unit: '%',
            width: 100,
            height: 100,
            x: 0,
            y: 0,
            aspect: isAspect ? 3 / 2 : undefined,
          };
        } else {
          return {
            unit: 'px',
            width: width,
            height: height,
            x: 0,
            y: 0,
            aspect: isAspect ? 3 / 2 : undefined,
          };
        }
      }

      if (isAspect) {
        const aspect = 3 / 2;
        const _width = Math.min(width, aspect * height);
        const _height = _width / aspect;
        return {
          unit: 'px',
          width: _width,
          height: _height,
          x: 0,
          y: 0,
        };
      }
      return {
        unit: 'px',
        width,
        height,
        x: 0,
        y: 0,
      };
    },
    [isAspect]
  );
  useEffect(() => {
    if (isCircleShape) {
      setCrop({ ...crop, width: 210, height: 210 });
    }

    // if (isCoverImage) {
    //   setCrop({ ...crop, width: 270, height: 180 });
    // }
  }, [isCircleShape, isCoverImage]);

  const onLoad = useCallback(
    (img) => {
      imgRef.current = img;

      // Get the actual rendered (occupied) size of the image in the container
      const width = img.offsetWidth;
      const height = img.offsetHeight;
      setImageInfo({ width, height });
      setCrop(getCropData(width, height, !isAspect, isCircleShape)); // Pass the rendered dimensions to getCropData
    },
    [isAspect, getCropData, isCircleShape]
  );

  const showResults = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        crop,
        isBlobImage,
        imgRef
      );
      if (isProfileImages) {
        onSubmitImage(croppedImage, isCircleShape ? 'profile' : 'cover');
      } else {
        onSubmitImage(croppedImage);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const rectCropInfo = useMemo(() => {
    if (!imageInfo?.width || !imageInfo?.height) {
      return { minWidth: 100, minHeight: 100 };
    }
    return {
      minWidth: imageInfo?.width / 2,
      minHeight: imageInfo?.height / 2,
      maxWidth: imageInfo?.width,
      maxHeight: imageInfo?.height,
    };
  }, [imageInfo]);

  return (
    <Wrapper>
      <ReactCrop
        crop={crop}
        aspect={isCircleShape ? 1 : isAspect ? 3 / 2 : undefined}
        onChange={onCropChange}
        circularCrop={isCircleShape}
        {...rectCropInfo}
      >
        <img
          src={image}
          onLoad={(e) => onLoad(e.target)}
          ref={imgRef}
          alt="Crop me"
          style={{ width: '100%', height: '100%' }}
        />
      </ReactCrop>

      <ButtonWrap>
        <RectangleButton
          text={t('editUserProfile:save')}
          color={style.mainWhite.white}
          backgroundColor={style.mainYellow.yellow}
          onClick={showResults}
        />
      </ButtonWrap>
    </Wrapper>
  );
};

export default ImageCropper;
