import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NavTabs from 'components/common/NavTabs';
import { ICreateEventSwitcherSettings } from './ICreateEventSwitcherSettings';

import {
  CreateEventSwitcherSettingsWrapper,
  SeparateSettingsHeader,
  SwitcherSettingWrap,
  TextWrap,
} from './CreateEventSwitcherSettings.styles';

const CreateEventSwitcherSettings = ({
  eventSettings,
  setEventSettings,
  initialValues,
  form,
}: ICreateEventSwitcherSettings) => {
  const activeAccountId =
    useSelector((state) => state?.club?.activeClub)?._id || null;
  // SETTING THE DEFAULT VALUE
  let activeChatDefaultValue = 1;
  let activeBroadcastDefaultValue = 2;
  let activeShareOnRoadStrValue = 2;
  let activePrivacyValue = 1;
  let activePostPermissionValue = 1;
  let activePrivatePermissionValue = 2;
  const formValue = form.getState()?.values;

  // --------------- ON CREATE OF EVENT --------------------
  if (!initialValues) {
    if (formValue) {
      if (formValue?.chat) activeChatDefaultValue = formValue?.chat ? 1 : 2;
      if (formValue?.broadcast)
        activeBroadcastDefaultValue = formValue?.broadcast ? 1 : 2;
      if (formValue?.shareOnRoadStr)
        activeShareOnRoadStrValue = formValue?.shareOnRoadStr ? 1 : 2;
      if (formValue?.privacy) activePrivacyValue = formValue?.privacy ? 2 : 1;
      if (formValue?.postPermission)
        activePostPermissionValue = formValue?.postPermission ? 2 : 1;
      if (formValue?.private)
        activePrivatePermissionValue = formValue?.private ? 1 : 2;
    }
  } else {
    activeChatDefaultValue = formValue?.chat ? 1 : 2;
    activeBroadcastDefaultValue = formValue?.broadcast ? 1 : 2;
    activeShareOnRoadStrValue = formValue?.shareOnRoadStr ? 1 : 2;
    activePrivacyValue = formValue?.privacy ? 2 : 1;
    activePostPermissionValue = formValue?.postPermission ? 2 : 1;
    activePrivatePermissionValue = formValue?.private ? 1 : 2;
  }

  const [activeTab, setActiveTab] = useState<number>(activeChatDefaultValue);
  const [activeBroadcastTab, setBroadcastActiveTab] = useState<number>(
    activeBroadcastDefaultValue
  );
  const [activeShareOnRoadStrTab, setShareOnRoadStrTab] = useState<number>(
    activeShareOnRoadStrValue
  );
  const [activePrivacy, setActivePrivacy] =
    useState<number>(activePrivacyValue);
  const [activePermission, setActivePermission] = useState<number>(
    activePostPermissionValue
  );
  const [activePrivate, setActivePrivate] = useState<number>(
    activePrivatePermissionValue
  );

  const { t } = useTranslation();

  return (
    <CreateEventSwitcherSettingsWrapper>
      <SwitcherSettingWrap>
        <SeparateSettingsHeader type="h3" fw={500} mb={6}>
          {t('event:eventChat')}
        </SeparateSettingsHeader>
        <NavTabs
          tabsList={[
            { id: 1, name: 'On' },
            { id: 2, name: 'Off' },
          ]}
          defaultActive={activeTab}
          onHandleSelect={(value) => {
            setActiveTab(value);
            form.change('chat', value == 1);
          }}
        />
        <TextWrap mt={6}>
          {activeTab == 1 ? t('event:eventChatOn') : t('event:eventChatOff')}
        </TextWrap>
      </SwitcherSettingWrap>
      {activeAccountId && (
        <SwitcherSettingWrap>
          <SeparateSettingsHeader type="h3" fw={500} mb={6}>
            {t('event:shareOnRoadStr')}
          </SeparateSettingsHeader>
          <NavTabs
            tabsList={[
              { id: 1, name: 'On' },
              { id: 2, name: 'Off' },
            ]}
            defaultActive={activeShareOnRoadStrTab}
            onHandleSelect={(value) => {
              setShareOnRoadStrTab(value);
              form.change('shareOnRoadStr', value === 1);
            }}
          />
          <TextWrap mt={6}>
            {activeShareOnRoadStrTab === 1
              ? t('event:shareOnRoadStrContentOn')
              : t('event:shareOnRoadStrContentOff')}
          </TextWrap>
        </SwitcherSettingWrap>
      )}

      <SwitcherSettingWrap>
        <SeparateSettingsHeader type="h3" fw={500} mb={6}>
          {t('event:broadCastMode')}
        </SeparateSettingsHeader>
        <NavTabs
          tabsList={[
            { id: 1, name: 'On' },
            { id: 2, name: 'Off' },
          ]}
          defaultActive={activeBroadcastTab}
          onHandleSelect={(value) => {
            setBroadcastActiveTab(value);
            form.change('broadcast', value == 1);
          }}
        />
        <TextWrap mt={6}>
          {activeBroadcastTab === 1
            ? t('event:eventChatBroadcastOn')
            : t('event:broadCastModeOff')}
        </TextWrap>
      </SwitcherSettingWrap>

      <SwitcherSettingWrap>
        <SeparateSettingsHeader type="h3" fw={500} mb={6}>
          {t('event:privateContent')}
        </SeparateSettingsHeader>
        <NavTabs
          tabsList={[
            { id: 1, name: 'On' },
            { id: 2, name: 'Off' },
          ]}
          defaultActive={activePrivate}
          onHandleSelect={(value) => {
            setActivePrivate(value);
            form.change('private', value == 1);
          }}
        />
        <TextWrap mt={6}>
          {activePrivate === 1
            ? t('event:privateContentOn')
            : t('event:privateContentOff')}
        </TextWrap>
      </SwitcherSettingWrap>

      <SwitcherSettingWrap mt={16} mb={16}>
        <SeparateSettingsHeader type="h3" fw={500} mb={6}>
          {t('event:eventPrivacy')}
        </SeparateSettingsHeader>
        <NavTabs
          tabsList={[
            { id: 1, name: t('map:public') },
            { id: 2, name: t('map:private') },
          ]}
          defaultActive={activePrivacy}
          onHandleSelect={(value) => {
            setActivePrivacy(value);
            form.change('privacy', value == 2);
          }}
        />
        <TextWrap mt={6}>
          {activePrivacy == 2
            ? t('event:privateEventOn')
            : t('event:privateEventOff')}
        </TextWrap>
      </SwitcherSettingWrap>

      <SwitcherSettingWrap>
        <SeparateSettingsHeader type="h3" fw={500} mb={6}>
          {t('addNewGroup:postPermissions')}
        </SeparateSettingsHeader>
        <NavTabs
          tabsList={[
            { id: 1, name: t('event:postPermissionOpen') },
            { id: 2, name: t('event:postPermissionOpenApproval') },
          ]}
          defaultActive={activePermission}
          onHandleSelect={(value) => {
            setActivePermission(value);
            form.change('postPermission', value == 2);
          }}
        />
        <TextWrap mt={6}>
          {activePermission == 2
            ? t('event:postPermissionOpenApprovalDescription')
            : t('event:postPermissionOpenDescription')}
        </TextWrap>
      </SwitcherSettingWrap>
    </CreateEventSwitcherSettingsWrapper>
  );
};

export default CreateEventSwitcherSettings;
