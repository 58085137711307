import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MapGL, { Marker } from 'react-map-gl';

import Drawer from 'components/Drawer';
import { getPlaceName, searchPlace } from 'services';
import {
  addTags,
  closeSidebarChooseLocation,
  closeSidebarCreateEvent,
} from 'store/sideBarControler/actions';
import { getLocalStorage } from 'hooks';
import { LocationArrowIcon } from 'public/assets/icons';
import { MapControls } from 'pages/map/components/mapControls/mapControls';
import Image from 'components/common/Image';
import { InputField, LocationPlacesList } from 'components';
import useMapFilters from 'hooks/useMapFilters';
import { IChooseLocationDrawer } from './IChooseLocationDrawer';

import { MapBoxContainer } from './ChooseLocationDrawer.styles';

const ChooseLocationDrawer = ({
  open,
  handleSubmit,
  setOpenChooseLocationDrawer,
  location,
}: IChooseLocationDrawer) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [locationInputValue, setLocationInputValue] = useState('');
  const [isOfferedPlacesModalOpen, setIsOfferedPlacesModalOpen] =
    useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [submitLocationData, setSubmitLocationData] = useState(null);

  const userLocalData = JSON.parse(getLocalStorage('user_data'));
  // const { location } = useSelector((state) => state.sideBarControler.tagsData);

  const { data } = userLocalData || { data: null };
  const { geoLocation } = data || { geoLocation: null };

  const [viewport, setViewport] = useState({
    width: '100%',
    height: '100%',
    latitude: geoLocation?.coordinates[1],
    longitude: geoLocation?.coordinates[0],
    zoom: 6,
  });
  const [userCoordinates, setUserCoordinates] = useState([]);
  const [placesOffered, setPlacesOffered] = useState([]);

  const mapRef = useRef(null);
  const locationInputRef = useRef(null);
  useMapFilters(mapRef, isMapLoaded);

  useEffect(() => {
    if (location) {
      setSubmitLocationData(location);
      setLocationInputValue(location.name || location.address);
      setViewport({
        ...viewport,
        latitude: Number(location.lat),
        longitude: Number(location.lng),
        zoom: 15,
      });
    }
  }, [location]);

  useEffect(() => {
    if (isMapLoaded) {
      locationInputRef.current.focus();
    }
  }, [isMapLoaded]);

  const onPointClick = async (point) => {
    const { features } = await getPlaceName({ coordinates: point.lngLat });

    if (Array.isArray(features) && features?.length) {
      if (features[0].place_type[0]) {
        setLocationInputValue(features[0].place_name);
      } else {
        setLocationInputValue(
          `${point.lngLat[0].toFixed(6)},${point.lngLat[1].toFixed(6)}`
        );
      }
    }

    const [longitude, latitude] = point.lngLat;

    setSubmitLocationData({
      lat: latitude,
      lng: longitude,
      name: features[0]?.place_name,
      address: features[0]?.place_name,
    });
    setViewport({ ...viewport, latitude, longitude, zoom: 15 });
  };

  const onChangeUserLocation = ({ coords: { latitude, longitude } }) => {
    setUserCoordinates([longitude, latitude]);
  };

  const onChangePlace = (newText) => {
    setLocationInputValue(newText);

    const proximity = userCoordinates
      ? `${userCoordinates[0]},${userCoordinates[1]}`
      : '';

    const searchPlaceByText = async () => {
      const resp = await searchPlace({
        searchText: newText.trim(),
        proximity,
      });

      if (!resp) {
        return null;
      }

      const { features } = resp;
      if (features?.length) {
        setPlacesOffered(features);
        // eslint-disable-next-line no-unused-expressions
        !isOfferedPlacesModalOpen && setIsOfferedPlacesModalOpen(true);
      } else {
        setPlacesOffered([]);
        setIsOfferedPlacesModalOpen(false);
      }
    };

    if (newText.length) {
      searchPlaceByText();
    } else {
      setIsOfferedPlacesModalOpen(false);
      setSubmitLocationData(null);
    }
  };

  const onPlaceOfferedClick = (place) => {
    setIsOfferedPlacesModalOpen(false);
    setLocationInputValue(place.place_name);

    const [longitude, latitude] = place.geometry.coordinates;

    setSubmitLocationData({
      lat: latitude,
      lng: longitude,
      name: place.place_name,
      address: place.place_name,
    });
    setViewport({ ...viewport, latitude, longitude, zoom: 13 });
  };

  const onSubmit = () => {
    // dispatch(addTags({ type: 'location', tags: submitLocationData }));
    // dispatch(closeSidebarChooseLocation());
    setOpenChooseLocationDrawer(false);
    handleSubmit(submitLocationData);
  };

  const onMapLoad = () => {
    setIsMapLoaded(true);
  };

  return (
    <Drawer
      open={open}
      onDrawerClose={() => setOpenChooseLocationDrawer(false)}
      onIconClick={() => setOpenChooseLocationDrawer(false)}
      isHeaderBorder
      headerText={t('map:tagLocation')}
      submitText={t('editUserProfile:save')}
      loading={false}
      onSubmit={onSubmit}
    >
      <InputField
        value={locationInputValue}
        onChange={(e) => onChangePlace(e.target.value)}
        label={t('common:location')}
        crossIcon
        onCrossClick={() => {
          setLocationInputValue('');
          setSubmitLocationData(null);
          setPlacesOffered([]);
          setIsOfferedPlacesModalOpen(false);
        }}
        margin="0 0 20px 0"
        iconLeft={<LocationArrowIcon />}
        disabled={!isMapLoaded}
        ref={locationInputRef}
      />

      <MapBoxContainer>
        <MapGL
          ref={mapRef}
          {...viewport}
          mapStyle={process.env._MAPBOX_STYLE_URL}
          onViewportChange={(nextViewport) => setViewport(nextViewport)}
          mapboxApiAccessToken={process.env._MAPBOX_ACCESS_TOKEN}
          onClick={(evt) => onPointClick(evt)}
          onLoad={onMapLoad}
          attributionControl={false}
          interactiveLayerIds={[
            process.env._EVENT_PIN_LAYER_NAME,
            process.env._POI_PIN_LAYER_NAME,
            process.env._BUSINESS_MKPL_PIN_LAYER_NAME,
          ]}
        >
          <MapControls onGeolocate={onChangeUserLocation} />

          {submitLocationData && (
            <Marker
              key={submitLocationData.name}
              longitude={Number(submitLocationData.lng)}
              latitude={Number(submitLocationData.lat)}
              offsetLeft={-18}
              offsetTop={-43}
            >
              <Image
                src="/assets/img/pin.svg"
                alt="location_pin"
                width={36}
                height={43}
                unoptimized
                priority
              />
            </Marker>
          )}
        </MapGL>

        {isOfferedPlacesModalOpen && (
          <LocationPlacesList
            locationsList={placesOffered}
            onOutsideClick={() => setIsOfferedPlacesModalOpen(false)}
            onItemClick={(item) => onPlaceOfferedClick(item)}
          />
        )}
      </MapBoxContainer>
    </Drawer>
  );
};

export default ChooseLocationDrawer;
