/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, batch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SidebarWrapper, InputField } from 'components';
import {
  closeSidebarImportRoute,
  openSidebarPlanRoute,
} from 'store/map/actions';
import { ArrowBack } from 'public/assets/icons';

import { MapSidebarWrap } from '../index';
import { AddImportRouteWrap, GpxFileWrap  } from './SidebarImportRoute.style';

const SidebarImportRoute = ({
  routeLink,
  gpxFile,
  loading,
  handleLinkChange,
  selectedMethod,
  handleGoogleLink,
  handleGPXFile,
  handleFileSelect,
}: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  useEffect(() => {
    setIsSubmitAvailable(!!routeLink || !!gpxFile);
  }, [routeLink, gpxFile]);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const onCloseSidebarClick = async () => {
    batch(() => {
      dispatch(closeSidebarImportRoute());
      dispatch(openSidebarPlanRoute());
    });
  };

  const onSubmit = () => {
    if (selectedMethod === 'maps') {
      handleGoogleLink();
    } else {
      handleGPXFile();
    }
    batch(() => {
      dispatch(closeSidebarImportRoute());
      dispatch(openSidebarPlanRoute());
    });
  };

  const handleAddImportRouteClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <MapSidebarWrap>
      <SidebarWrapper
        headerText={
          selectedMethod === 'maps'
            ? t('map:importFromGoogleMaps')
            : t('map:importFromGpxFile')
        }
        headerIcon={<ArrowBack />}
        submitText={t('common:import')}
        isSubmitAvailable={isSubmitAvailable && !loading}
        onSubmit={onSubmit}
        onIconClick={onCloseSidebarClick}
        contentPadding="20px"
      >
        {selectedMethod === 'maps' ? (
          <InputField
            value={routeLink}
            onChange={(e) => handleLinkChange(e.target.value)}
            placeholder={t('map:pasteRouteLink')}
            crossIcon
            onCrossClick={() => handleLinkChange('')}
            ref={inputRef}
          />
        ) : gpxFile ? (
          <GpxFileWrap>
          <p>{gpxFile.name}</p>
          </GpxFileWrap>
        ) : (
          <>
            <input
              type="file"
              accept=".gpx"
              onChange={handleFileSelect}
              ref={fileInputRef}
              style={{ display: 'none' }}
              aria-label="GPX file input"
            />
            <AddImportRouteWrap onClick={handleAddImportRouteClick}>
              <p>{t('map:selectGpxFile')}</p>
            </AddImportRouteWrap>
          </>
        )}
      </SidebarWrapper>
    </MapSidebarWrap>
  );
};

export default SidebarImportRoute;
